import {
  CCol,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
} from '@coreui/react-pro'
import { TextareaAutosize } from '@mui/base'
import { CopyAll } from '@mui/icons-material'
import { Box, Chip, IconButton, Switch, Tooltip, useForkRef } from '@mui/material'
import { styled } from '@mui/system'
import { DateTimePicker, useClearableField } from '@mui/x-date-pickers'
import { useDateTimeField } from '@mui/x-date-pickers/DateTimeField/useDateTimeField'
import { useTimeField } from '@mui/x-date-pickers/TimeField/useTimeField'
import PropTypes, { array, func, number } from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { copyToClipBoard } from 'src/utilities/generalUtils'
import {
  CustomCreatableSelect,
  CustomSelect,
} from 'src/views/plugins/calendar/components/CustomSelect'
import { StyledStaffPhoneNumberInput } from 'src/views/settings/components/styledComponents'
import { CustomReactSelectComponent } from './styledComponents'

const generalPropTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  setData: PropTypes.func,
  disabled: PropTypes.bool,
  colNumber: PropTypes.number,
  showLabel: PropTypes.bool,
  setType: PropTypes.oneOf(['string', 'object']),
  customLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  loading: PropTypes.bool,
  selectFirstByDefaultIfEmpty: PropTypes.bool,
}

export const selectTypes = { object: 'object', string: 'string' }

export const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  padding: 7px 12px;
  border-radius: var(--cui-border-radius);
  background: ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#fff'};
  color: ${theme.palette.mode !== 'dark' ? '#000' : '#fff'};
  border:${
    theme.palette.mode === 'dark'
      ? '1px solid #b1b7c114'
      : 'var(--cui-border-width) solid var(--cui-input-border-color, var(--cui-border-color))'
  };
  box-shadow: 0 0 0 0 rgba(50, 31, 219, 0.25) ;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border: 1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#a19ae1'};
    border-color:#998fed;
    box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25) ;
  }
  &:disabled{
    border-color:#b1b7c1;
    background: #c9c9c94d;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
)

function selectValueMaker(setType, value, { valueKey = 'id', labelKey = 'title' }, options = []) {
  console.log(value)
  if (setType !== 'string') {
    if (value === undefined || value?.[valueKey] === undefined || value?.[valueKey] === '') {
      return undefined
    }
    return { value: value[valueKey], label: value[labelKey] }
  }

  const foundData = options?.find((option) => {
    return option[valueKey] === value
  })
  const label = foundData ? foundData?.[labelKey] || value : value

  return value === undefined || value === ''
    ? undefined
    : {
        value: value || '',
        label: label,
      }
}

function multiSelectValueMaker(
  setType,
  selectedValue,
  { valueKey = 'id', labelKey = 'title' },
  options,
) {
  if (setType !== 'string') {
    if (selectedValue.length === 0) {
      return undefined
    }
    return selectedValue.map((value) => {
      const foundData = options.find((option) => {
        return option?.[valueKey] === value?.[valueKey]
      })
      return {
        ...foundData,
        value: foundData ? foundData?.[valueKey] : foundData,
        label: foundData ? foundData?.[labelKey] : foundData,
      }
    })
  }
  return selectedValue?.map((val) => {
    const foundOption = options?.find((option) => {
      return option[valueKey] === val
    })
    const selectLabel = !!foundOption ? foundOption?.[labelKey] : selectedValue || ''
    return {
      ...foundOption,
      value: val || '',
      label: selectLabel,
    }
  })
}

export function SelectField(
  id,
  value,
  { valueKey = 'id', labelKey = 'title' },
  options,
  setData,
  disabled = false,
  colNumber = 4,
  setType = 'object',
  customLabel = false,
  required,
  loading = false,
) {
  const label = id.replace(/([A-Z])/g, ' $1')
  if (options.length === 1 && !selectValueMaker(setType, value, { valueKey, labelKey }, options)) {
    setData((prev) => {
      return { ...prev, [id]: setType !== 'object' ? options?.[0]?.[valueKey] : options?.[0] }
    })
  }
  return (
    <CCol md={colNumber}>
      <CFormLabel htmlFor={`select${id}`}>
        {customLabel ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)}
      </CFormLabel>
      <CustomSelect
        menuPortalTarget={document.body}
        required={required}
        isDisabled={disabled}
        isLoading={loading}
        id={`select${id}`}
        placeholder={
          'Select ' +
          (customLabel ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)) +
          '....'
        }
        value={selectValueMaker(setType, value, { valueKey, labelKey }, options)}
        options={options?.map((option) => {
          return { ...option, value: option?.[valueKey], label: option?.[labelKey] }
        })}
        onChange={(selectValue) => {
          console.log(selectValue)
          console.log(selectValue?.value)
          const { value, label, ...cleanedSelectValue } = selectValue
          setData((prev) => {
            return { ...prev, [id]: setType !== 'object' ? value : cleanedSelectValue }
          })
        }}
      />
    </CCol>
  )
}

export function MultiSelectField(
  id,
  value,
  { valueKey = 'id', labelKey = 'title' },
  options,
  setData,
  disabled = false,
  colNumber = 4,
  showLabel = true,
  setType = 'string',
  customLabel = false,
  required,
  loading = false,
) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      {showLabel && (
        <CFormLabel htmlFor={`select${id}`}>
          {customLabel ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)}
        </CFormLabel>
      )}
      <CustomSelect
        isMulti
        isLoading={loading}
        required={required}
        isDisabled={disabled}
        id={`select${id}`}
        placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
        value={multiSelectValueMaker(
          setType,
          value,
          { valueKey: valueKey, labelKey: labelKey },
          options,
        )}
        options={options?.map((option) => {
          return { ...option, value: option?.[valueKey], label: option?.[labelKey] }
        })}
        onChange={(selectValue) => {
          if (setType === 'object') {
            setData((prev) => {
              return {
                ...prev,
                [id]: selectValue?.map((selValue) => {
                  const { value, label, ...cleanedSelectValue } = selValue
                  return cleanedSelectValue
                }),
              }
            })
            return
          }
          setData((prev) => {
            return {
              ...prev,
              [id]: selectValue?.map((value) => {
                return value[valueKey]
              }),
            }
          })
        }}
      />
    </CCol>
  )
}

export function CreatableMultiSelectField(
  id,
  value,
  { valueKey = 'id', labelKey = 'title' },
  options,
  setData,
  disabled = false,
  colNumber = 4,
  showLabel = true,
  restProps = {},
) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      {showLabel && (
        <CFormLabel htmlFor={`select${id}`}>
          {label.charAt(0).toUpperCase() + label.slice(1)}
        </CFormLabel>
      )}
      <CustomCreatableSelect
        {...restProps}
        isMulti
        isDisabled={disabled}
        id={`select${id}`}
        placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
        value={value?.map((val) => {
          const foundOption = options?.find((option) => {
            return option[valueKey] === val
          })
          const selectLabel = !!foundOption ? foundOption?.[labelKey] : value || ''
          return {
            ...foundOption,
            value: val || '',
            label: selectLabel,
          }
        })}
        options={options?.map((option) => {
          return { ...option, value: option?.[valueKey], label: option?.[labelKey] }
        })}
        onChange={(selectValue) => {
          console.log(selectValue)
          setData((prev) => {
            return {
              ...prev,
              [id]: selectValue?.map((value) => {
                return value.value
              }),
            }
          })
        }}
      />
    </CCol>
  )
}

export function RequiredInputField(
  id,
  value,
  setData,
  colNumber = 4,
  mode,
  customLabel = false,
  customDisable,
) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      <CFormInput
        label={customLabel === false ? label.charAt(0).toUpperCase() + label.slice(1) : customLabel}
        value={value}
        onChange={(event) => {
          setData((prev) => {
            return {
              ...prev,
              [id]: event.target.value,
            }
          })
        }}
        required
        disabled={customDisable ? customDisable : mode === 'View'}
        valid={value?.length !== 0}
        invalid={value?.length === 0}
      ></CFormInput>
    </CCol>
  )
}

export function RequiredTextAreaField(id, value, setData, colNumber = 4, mode, rows = 3) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      <CFormTextarea
        label={label.charAt(0).toUpperCase() + label.slice(1)}
        value={value}
        rows={rows}
        onChange={(event) => {
          setData((prev) => {
            return {
              ...prev,
              [id]: event.target.value,
            }
          })
        }}
        required
        disabled={mode === 'View'}
        valid={value?.length !== 0}
        invalid={value?.length === 0}
      />
    </CCol>
  )
}

export function TextAreaField(
  id,
  value,
  setData,
  colNumber = 4,
  mode,
  rows = 3,
  customLabel = false,
  required = false,
) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      <CFormLabel htmlFor={`textArea-${id}`}>
        {customLabel ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)}
      </CFormLabel>
      <StyledTextarea
        id={`textArea-${id}`}
        value={value}
        onChange={(event) => {
          setData((prev) => {
            return {
              ...prev,
              [id]: event.target.value,
            }
          })
        }}
        required={required}
        disabled={mode === 'View'}
      />
    </CCol>
  )
}

export function InputField(id, value, setData, colNumber = 4, mode, customLabel = false) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      <CFormInput
        label={customLabel !== false ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)}
        value={value}
        onChange={(event) => {
          setData((prev) => {
            return {
              ...prev,
              [id]: event.target.value,
            }
          })
        }}
        disabled={mode === 'View'}
      ></CFormInput>
    </CCol>
  )
}

export function FormSwitch(
  id,
  value,
  setData,
  required,
  colSize = 2,
  mdColSize = 6,
  mode,
  customLabel = false,
  customDisable = false,
) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={mdColSize} xxl={colSize} className="d-flex align-items-center">
      <Switch
        checked={value}
        disabled={customDisable ? customDisable : mode === 'View'}
        id={id}
        onChange={(event) => {
          setData((prev) => {
            return { ...prev, [id]: prev?.[id] ? false : true }
          })
        }}
        required={required}
      />
      <label htmlFor={id}>
        {customLabel === false ? label.charAt(0).toUpperCase() + label.slice(1) : customLabel}
      </label>
    </CCol>
  )
}

export function BetterCreatableMultiSelect({
  id,
  value,
  valueKey = 'id',
  labelKey = 'title',
  options,
  setData,
  disabled = false,
  colNumber = 4,
  showLabel = true,
  ...rest
}) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      {showLabel && (
        <CFormLabel htmlFor={`select${id}`}>
          {label.charAt(0).toUpperCase() + label.slice(1)}
        </CFormLabel>
      )}
      <CustomCreatableSelect
        isMulti
        isDisabled={disabled}
        id={`select${id}`}
        placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
        value={value?.map((val) => {
          const foundOption = options?.find((option) => {
            return option[valueKey] === val
          })
          const selectLabel = !!foundOption ? foundOption?.[labelKey] : value || ''
          return {
            ...foundOption,
            value: val || '',
            label: selectLabel,
          }
        })}
        options={options?.map((option) => {
          return { ...option, value: option?.[valueKey], label: option?.[labelKey] }
        })}
        onChange={(selectValue) => {
          console.log(selectValue)
          setData((prev) => {
            return {
              ...prev,
              [id]: selectValue?.map((value) => {
                return value.value
              }),
            }
          })
        }}
        {...rest}
      />
    </CCol>
  )
}

export function BetterMultiSelectField({
  id,
  value,
  valueKey = 'id',
  labelKey = 'title',
  options,
  setData,
  disabled = false,
  colNumber = 4,
  showLabel = true,
  setType = 'string',
  customLabel = false,
  required,
  loading = false,
}) {
  const label = id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      {showLabel && (
        <CFormLabel htmlFor={`select${id}`}>
          {customLabel ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)}
        </CFormLabel>
      )}
      <CustomSelect
        isMulti
        isLoading={loading}
        required={required}
        isDisabled={disabled}
        id={`select${id}`}
        placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
        value={multiSelectValueMaker(
          setType,
          value,
          { valueKey: valueKey, labelKey: labelKey },
          options,
        )}
        options={options?.map((option) => {
          return { ...option, value: option?.[valueKey], label: option?.[labelKey] }
        })}
        onChange={(selectValue) => {
          if (setType === 'object') {
            setData((prev) => {
              return {
                ...prev,
                [id]: selectValue?.map((selValue) => {
                  const { value, label, ...cleanedSelectValue } = selValue
                  return cleanedSelectValue
                }),
              }
            })
            return
          }
          setData((prev) => {
            return {
              ...prev,
              [id]: selectValue?.map((value) => {
                return value[valueKey]
              }),
            }
          })
        }}
      />
    </CCol>
  )
}

export function BetterSelectField({
  id,
  value,
  valueKey = 'id',
  labelKey = 'title',
  setData,
  options,
  disabled = false,
  colNumber = 4,
  setType = 'object',
  customLabel = false,
  required = false,
  loading = false,
  showLabel = true,
  selectFirstByDefaultIfEmpty = true,
  ...rest
}) {
  const label = id.replace(/([A-Z])/g, ' $1')
  const innerOptions = loading ? [] : options
  if (
    innerOptions.length === 1 &&
    !selectValueMaker(setType, value, { valueKey, labelKey }, innerOptions) &&
    setData
  ) {
    selectFirstByDefaultIfEmpty &&
      setData((prev) => {
        return {
          ...prev,
          [id]: setType !== 'object' ? innerOptions?.[0]?.[valueKey] : innerOptions?.[0],
        }
      })
  }
  return (
    <CCol md={colNumber}>
      {showLabel && (
        <CFormLabel htmlFor={`select${id}`}>
          {customLabel ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)}
        </CFormLabel>
      )}
      <CustomSelect
        {...rest}
        menuPortalTarget={document.body}
        required={required}
        isDisabled={disabled}
        isLoading={loading}
        id={`select${id}`}
        placeholder={
          'Select ' +
          (customLabel ? customLabel : label.charAt(0).toUpperCase() + label.slice(1)) +
          '....'
        }
        value={selectValueMaker(setType, value, { valueKey, labelKey }, innerOptions)}
        options={innerOptions?.map((option) => {
          return { ...option, value: option?.[valueKey], label: option?.[labelKey] }
        })}
        onChange={(selectValue) => {
          console.log(selectValue)
          console.log(selectValue?.value)
          const { value, label, ...cleanedSelectValue } = selectValue
          setData((prev) => {
            return { ...prev, [id]: setType !== 'object' ? value : cleanedSelectValue }
          })
        }}
      />
    </CCol>
  )
}

export const CustomReactSelect = function CustomReactSelect({
  id = 'temp',
  label,
  subLabel,
  hideStarInRequired = false,
  options,
  formLabelClass,
  selectType = selectTypes.object,
  noOptionTextEllipisis = false,
  onChangeEvent,
  SelectComponent,
  setFirstByDefault = false,
  disabled = false,
  ltr = false,
  ...rest
}) {
  const ReactSelectComponent = useMemo(() => {
    return !!SelectComponent ? SelectComponent : CustomReactSelectComponent
  }, [SelectComponent])

  function handleChange(selectValue) {
    if (onChangeEvent) {
      var valueToPut
      if (selectType === selectTypes?.string) {
        if (rest?.isMulti) {
          valueToPut = selectValue.map((val) => rest?.getOptionValue(val))
        } else {
          if (!!selectValue) {
            valueToPut = rest?.getOptionValue(selectValue)
          } else {
            valueToPut = selectValue
          }
        }
      } else {
        valueToPut = selectValue
      }
      const event = {
        target: { value: valueToPut, id: id, type: 'react-select' },
      }
      onChangeEvent(event)
    } else {
      rest?.onChange(selectValue)
    }
  }

  function getValue(selectValue) {
    if (selectType === selectTypes?.string) {
      if (rest?.isMulti) {
        if (Boolean(rest?.value)) {
          return rest?.value?.map((val) => {
            return (
              options?.find((option) => rest?.getOptionValue(option) === val) || {
                value: val,
                id: val,
                title: val,
                name: val,
                label: val,
              }
            )
          })
        }
        return null
      } else {
        if (Boolean(rest?.value))
          return (
            options?.find((option) => rest?.getOptionValue(option) === rest?.value) || {
              value: rest?.value,
              id: rest?.value,
              title: rest?.value,
              name: rest?.value,
              label: rest?.value,
            }
          )
        else {
          return undefined
        }
      }
    }
    return selectValue
  }

  useEffect(() => {
    if (setFirstByDefault && rest.options && rest?.options?.length > 0) {
      const value = getValue(rest?.value)
      if (rest?.isMulti) {
        if (!value || value.length === 0) {
          handleChange([options[0]])
        }
      } else {
        if (!value) {
          handleChange(options[0])
        }
      }
    }
  }, [setFirstByDefault, rest?.value, options])

  //TODO: use the above two functions to auto select the initial values

  return (
    <div
      className={`d-flex ${ltr ? '' : 'flex-column'} justify-content-start ${
        ltr ? 'align-items-center' : 'align-items-stretch'
      } w-100`}
    >
      {(label || subLabel) && (
        <div className="d-flex flex-column justify-content-start align-items-stretch">
          <CFormLabel className={formLabelClass}>
            {label && (
              <span className="m-0">
                {label} {!hideStarInRequired && rest?.required && '*'}
              </span>
            )}
            {subLabel && <div className="fs-8">{subLabel}</div>}
          </CFormLabel>
        </div>
      )}
      <ReactSelectComponent
        {...rest}
        value={getValue(rest?.value) || null}
        noOptionTextEllipisis
        classNamePrefix="react-select"
        options={options}
        onChange={handleChange}
        isDisabled={disabled}
      />
    </div>
  )
}

export function CFormInputViewComponent({ value, label }) {
  return (
    <span>
      <strong>label:</strong>
      {value}
    </span>
  )
}

export const CCFormInput = React.memo(function BetterInputField({
  colNumber = 4,
  showLabel = true,
  preLabel,
  postLabel,
  suffix, // New prop for suffix
  coreUiGridItem,
  containerStyles = {},
  label,
  subLabel,
  ...rest
}) {
  const handleChange = (event) => {
    const value = event.target.value.replace(suffix, '') // Prevent duplicate suffix
    rest.onChange({ target: { id: event.target.id, type: event.target.type, value: value } })
  }

  const GridComponent = coreUiGridItem ? CCol : Box
  const InputGroupContainer = preLabel ? CInputGroup : Box

  var internalLabel
  if (showLabel !== false) {
    internalLabel =
      label ||
      (rest?.id
        ? rest.id
            .replace(/([A-Z])/g, ' $1')
            .charAt(0)
            .toUpperCase() + rest.id.slice(1)
        : null)
  }

  if (internalLabel && rest?.required) {
    internalLabel += ' *'
  }

  return (
    <GridComponent md={colNumber} sx={containerStyles}>
      <InputGroupContainer sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {(internalLabel || subLabel) && (
          <div className="w-100 d-flex flex-column">
            {internalLabel && <CFormLabel htmlFor={rest.id}>{internalLabel}</CFormLabel>}
            {subLabel && <CFormLabel className="fs-8">{subLabel}</CFormLabel>}
          </div>
        )}
        <div style={{ position: 'relative', width: '100%' }}>
          <CFormInput
            {...rest}
            // value={rest?.value + suffix || ''}
            onChange={handleChange}
            style={{
              paddingRight: suffix ? '25px' : '10px', // Space for suffix
            }}
          />
          {suffix && (
            <span
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
                color: '#888',
                fontSize: '14px',
              }}
            >
              {suffix}
            </span>
          )}
        </div>
        {postLabel && <CInputGroupText>{postLabel}</CInputGroupText>}
      </InputGroupContainer>
    </GridComponent>
  )
})

export const CCFormInputPhoneNumber = React.memo(function BetterInputField({
  colNumber = 4,
  showLabel = true,
  label,
  subLabel,
  ...rest
}) {
  // var internalLabel = label ? label : rest.id.replace(/([A-Z])/g, ' $1')
  var internalLabel
  if (showLabel === false) {
    internalLabel = null
  } else {
    if (!!label) {
      internalLabel = label
    } else {
      const tempLabel = rest.id.replace(/([A-Z])/g, ' $1')
      internalLabel = tempLabel.charAt(0).toUpperCase() + tempLabel.slice(1)
    }
  }
  return (
    <CCol md={colNumber}>
      <div className="w-100 d-flex flex-column">
        {internalLabel && (
          <CFormLabel className={`${subLabel ? 'mb-0' : ''}`} htmlFor={rest.id}>
            {internalLabel}
          </CFormLabel>
        )}
        {subLabel && (
          <CFormLabel htmlFor={rest.id} className="fs-8">
            {subLabel}
          </CFormLabel>
        )}
      </div>
      <StyledStaffPhoneNumberInput {...rest} />
    </CCol>
  )
})

export const BetterColorPicker = ({ label, ...rest }) => {
  return (
    <div className="d-flex justify-content-between align-items-center pt-4">
      {!!label && (
        <div className="d-flex align-items-center">
          <div>{label}</div>
          <Tooltip title={rest?.value}>
            <IconButton onClick={() => copyToClipBoard(label, rest?.value)}>
              <CopyAll />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <CFormInput {...rest} type="color" title="Choose your color" />
    </div>
  )
}

export function OverflowingArrayChips({ allItems, getTitle, maxChips, chipProps = {} }) {
  const allItemTitles = allItems?.map((item) => {
    return getTitle(item)
  })
  const titleToShowOutside = allItemTitles?.length > 3 ? allItemTitles?.slice(0, 3) : allItemTitles

  return (
    <>
      {allItemTitles?.length === 0 || !allItems ? (
        <div>No Data</div>
      ) : (
        <Tooltip title={allItemTitles?.join(', ')}>
          <div className="d-flex flex-wrap gap-2">
            {titleToShowOutside?.map((iTitle, idx) => {
              return <Chip {...chipProps} label={iTitle} key={iTitle + idx} />
            })}
            {allItemTitles?.length > maxChips ? `+${allItemTitles?.length - maxChips}` : ''}
          </div>
        </Tooltip>
      )}
    </>
  )
}

export const CCTextArea = function CCTextArea({
  id,
  colNumber = 12,
  rows = 3,
  label,
  subLabel,
  textAreaComponent,
  hideLabel,
  ...rest
}) {
  const TextAreaComponentToUse = useMemo(() => {
    return textAreaComponent ? textAreaComponent : StyledTextarea
  }, [textAreaComponent])
  const internalLabel = label ? label : id.replace(/([A-Z])/g, ' $1')
  return (
    <CCol md={colNumber}>
      {!hideLabel && (
        <div className="w-100 d-flex flex-column">
          <CFormLabel htmlFor={id} style={{ margin: '0 0 8px 0' }}>
            {!!label ? label : internalLabel.charAt(0).toUpperCase() + internalLabel.slice(1)}
          </CFormLabel>
          {subLabel && (
            <CFormLabel htmlFor={rest.id} className="fs-8">
              {subLabel}
            </CFormLabel>
          )}
        </div>
      )}
      <TextAreaComponentToUse id={id} {...rest} />
    </CCol>
  )
}

const CoreUIInputField = React.forwardRef((props, ref) => {
  const {
    inputRef,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
    error,
    focused,
    ownerState,
    id,
    inputId,
    sx,
    label,
    ...other
  } = props

  const handleRef = useForkRef(containerRef, ref)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {label && <CFormLabel htmlFor={inputId}>{label}</CFormLabel>}
      <Box
        sx={{ ...(sx || {}), display: 'flex', alignItems: 'center', width: '100%' }}
        id={id}
        ref={handleRef}
      >
        {startAdornment}
        <CFormInput ref={inputRef} id={inputId} {...other} />
        {endAdornment}
      </Box>
    </Box>
  )
})

const BrowserDateTimeField = React.forwardRef((props, ref) => {
  const { inputRef: externalInputRef, slots, slotProps, ...textFieldProps } = props

  const {
    onClear,
    clearable,
    ref: inputRef,
    ...fieldProps
  } = useDateTimeField({
    props: textFieldProps,
    inputRef: externalInputRef,
  })

  /* If you don't need a clear button, you can skip the use of this hook */
  const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } = useClearableField({
    onClear,
    clearable,
    fieldProps,
    InputProps: fieldProps.InputProps,
    slots,
    slotProps,
  })
  return (
    <CoreUIInputField
      ref={ref}
      inputRef={inputRef}
      {...processedFieldProps}
      InputProps={ProcessedInputProps}
    />
  )
})

const BrowserTimeField = React.forwardRef((props, ref) => {
  const { inputRef: externalInputRef, slots, slotProps, ...textFieldProps } = props

  const {
    onClear,
    clearable,
    ref: inputRef,
    ...fieldProps
  } = useTimeField({
    props: textFieldProps,
    inputRef: externalInputRef,
  })

  /* If you don't need a clear button, you can skip the use of this hook */
  const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } = useClearableField({
    onClear,
    clearable,
    fieldProps,
    InputProps: fieldProps.InputProps,
    slots,
    slotProps,
  })
  return (
    <CoreUIInputField
      ref={ref}
      inputRef={inputRef}
      {...processedFieldProps}
      InputProps={ProcessedInputProps}
    />
  )
})

export const CoreMuiDateTimePicker = React.forwardRef((props, ref) => {
  return (
    <DateTimePicker ref={ref} {...props} slots={{ field: BrowserDateTimeField, ...props.slots }} />
  )
})
export const CoreMuiTimePicker = React.forwardRef((props, ref) => {
  return <DateTimePicker ref={ref} {...props} slots={{ field: BrowserTimeField, ...props.slots }} />
})

OverflowingArrayChips.propTypes = { allItems: array, getTitle: func, maxChips: number }

CCTextArea.propTypes = {
  ...generalPropTypes,
  mode: PropTypes.string,
  rows: PropTypes.number,
}
BetterMultiSelectField.propTypes = generalPropTypes
BetterSelectField.propTypes = generalPropTypes
CCFormInput.propTypes = { ...generalPropTypes, ...CFormInput.propTypes }
BetterColorPicker.propTypes = { ...generalPropTypes, ...CFormInput.propTypes }

CustomReactSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  selectType: PropTypes.oneOf(['object', 'string']),
  onChangeEvent: PropTypes.func,
  SelectComponent: PropTypes.elementType,
  setFirstByDefault: PropTypes.bool,
  disabled: PropTypes.bool,
  // Add other PropTypes for rest here as needed
}

BetterCreatableMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  setData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  colNumber: PropTypes.number,
  showLabel: PropTypes.bool,
}
