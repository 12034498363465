import React, { useMemo } from 'react'
import DefaultCsvUploadComponent from './DefaultCsvUploadComponent'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import PatientsCsvUploadComponent from './components/patients/PatientsCsvUploadComponent'
import BookedEventsCsvUploadComponent from './components/bookedEvent/BookedEventsCsvUploadComponent'

export default function CsvUploadFormSwitcher({ apiParam, ...rest }) {
  let UploadComponentToUse = useMemo(() => {
    switch (apiParam) {
      case ALL_ENTITY_TYPES_FROM_CONSTANTS.bookedEvent:
        return BookedEventsCsvUploadComponent
      case ALL_ENTITY_TYPES_FROM_CONSTANTS.patientInfo:
        return PatientsCsvUploadComponent
      default:
        return DefaultCsvUploadComponent
    }
  }, [apiParam])

  return <UploadComponentToUse apiParam={apiParam} {...rest} />
}
