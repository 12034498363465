import { Grid } from '@mui/material'
import React, { useMemo } from 'react'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'

export default function HPPatientsUpload({
  params,
  setParams,
  customOptions,
  storeData,
  handleChangeParams,
}) {
  const selectedClinic = useMemo(() => {
    return customOptions?.clinics?.find((cln) => cln?.id === params?.['clinic-id'])
  }, [params, customOptions])

  const currentMentors = useMemo(() => {
    const mentrContacts =
      [...(selectedClinic?.doctors || []), ...(selectedClinic?.therapists || [])] || []

    const teacherIdsToConsider = mentrContacts?.map((cont) => cont?.uid)

    const mentorObjects = customOptions?.mentors?.filter((teach) => {
      return teacherIdsToConsider?.includes(teach?.id)
    })
    return mentorObjects
  }, [customOptions, selectedClinic])

  const currentClinics = useMemo(() => {
    return customOptions?.clinics?.filter(
      (cln) => cln?.clinicBrandId === params?.['clinic-brand-id'],
    )
  }, [customOptions, params])

  return (
    <>
      <Grid item xs={12} md={4}>
        <CustomReactSelect
          id="clinic-brand-id"
          value={params?.['clinic-brand-id']}
          options={customOptions?.clinicBrands || []}
          getOptionValue={(object) => object?.id}
          getOptionLabel={(object) => object?.brandName || object?.title}
          label="Clinic Brand"
          onChangeEvent={(e) => {
            handleChangeParams(e)
            setParams((prev) => ({ ...prev, 'clinic-id': null, 'clinic-doctor-id': null }))
          }}
          selectType={selectTypes.string}
          placeholder="Select a clinic brand..."
          required
        />
      </Grid>
      {!!params?.['clinic-brand-id'] && (
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="clinic-id"
            value={params?.['clinic-id']}
            options={currentClinics || []}
            getOptionValue={(object) => object?.id}
            getOptionLabel={(object) => object?.title || object?.id}
            label="Clinic Location"
            onChangeEvent={(e) => {
              handleChangeParams(e)
              setParams((prev) => ({ ...prev, 'clinic-doctor-id': null }))
            }}
            selectType={selectTypes.string}
            placeholder="Select a clinic..."
            required
          />
        </Grid>
      )}
      {!!params?.['clinic-id'] && (
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="clinic-doctor-id"
            label="Mentor"
            value={params?.['clinic-doctor-id']}
            options={currentMentors?.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionValue={(object) => object?.id}
            // getOptionLabel={(object) =>
            //   `${object?.name || object?.title || object?.id} (${getRoleTitleForMentor(object)})`
            // }
            getOptionLabel={(object) => `${object?.name || object?.title || object?.id}`}
            onChangeEvent={handleChangeParams}
            selectType={selectTypes.string}
            placeholder="Select a mentor..."
            required
          />
        </Grid>
      )}
      <Grid item xs={12}></Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="import-id-header"
          value={params?.['import-id-header']}
          label="ImportId Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="patient-name-header"
          value={params?.['patient-name-header']}
          label="Patient Name Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="age-header"
          value={params?.['age-header']}
          label="Age Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="mobile-header"
          value={params?.['mobile-header']}
          label="Mobile Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="gender-header"
          value={params?.['gender-header']}
          label="Gender Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="email-header"
          value={params?.['email-header']}
          label="Email Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="city-header"
          value={params?.['city-header']}
          label="City Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="address-header"
          value={params?.['address-header']}
          label="Address Header"
          onChange={handleChangeParams}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CCFormInput
          id="registration-date-header"
          value={params?.['registration-date-header']}
          label="Registration Date Header"
          onChange={handleChangeParams}
        />
      </Grid>
    </>
  )
}
