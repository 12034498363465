import { Button, DialogActions, DialogTitle } from '@mui/material'
import React from 'react'

export default function PatientInfoBulkActionsForm({
  selectedItems,
  refreshListingData,
  closeDialog,
}) {
  async function disableItems() {
    console.log('disable')
    console.log({ selectedItems: selectedItems?.map((i) => i?.id) })
  }
  async function enableItems() {
    console.log('enabel')
    console.log({ selectedItems: selectedItems?.map((i) => i?.id) })
  }

  return (
    <>
      <DialogTitle>Bulk actions will be applied to {selectedItems?.length} patients</DialogTitle>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button onClick={disableItems}>Disable Patients</Button>
        <Button onClick={enableItems}>Enable Patients</Button>
      </DialogActions>
    </>
  )
}
