import moment from 'moment'
import { transformPostClinicBrandData } from 'src/config/clinicBrand/transformPostData'
import { transformPostClinicChamberData } from 'src/config/clinicChamber/transformPostData'
import { transformSendInAppMsgData } from 'src/config/inAppMessages/transformPostData'
import { transformInvestigationPostData } from 'src/config/investigation/transformPostData'
import { transformSendMetaTabSettingData } from 'src/config/metaTabSetting/transformPostData'
import { transformPostNotificationData } from 'src/config/notifications/transformPostData'
import { transformPaymentGatewayPostData } from 'src/config/paymentGatewaySku/transformPostData'
import { transformPaywallPostData } from 'src/config/paywalls/transformPostData'
import { transformPostPrescriptionTemplateData } from 'src/config/prescriptionTemplates/transformPostData'
import { transformSatelliteCallData } from 'src/config/satelliteCall/transformPostData'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getSavableMentalStateTypeObject } from 'src/views/setup/userStats/stats-utils'
import { transformSendBundlePacksData } from '../bundlePacks/transformPostData'
import { transformFeeModelData } from '../feeModel/transformFeeModelData'
import { transformClinicSubscriptionPlan } from '../clincSubscriptionPlan/transformPostData'

export async function transformUploadObject(entity, data, storeData) {
  const todayFormattedDate = moment().format('YYYY-MM-DD HH:mm:ss')

  switch (entity) {
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.article:
      return { ...data, status: 'published' }
    case 'tag':
      return {
        ...data,
        name: data.tag,
        translations: {
          en: {
            longDesc: data.longDescription,
            name: data.name,
            shortDesc: data.shortDesc,
            title: data.title,
            welcomeMsg: data.welcomeMsg,
          },
        },
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.agreement:
      return {
        ...data,
        version: data.version + 1,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.metaCategoryType:
      return {
        ...data,
        itemType:
          data?.scope === 'app'
            ? storeData?.itemTypes?.map((itemType) => itemType?.id)
            : data?.itemType,
      }
    case 'hint':
      return {
        ...data,
        name: data.hint,
        internalTitle: data.hint,
        title: data.hint,
        hintTranslation: { en: data.hint },
      }
    case 'drug-info':
      return {
        ...data,
        dosage: data.dosage
          .split('-')
          .map((dosg) => (dosg === '' ? '0' : dosg))
          .join('-'),
        availableToAll: true,
        approvedForAllBy: storeData.userId,
        approvedForAllAt: todayFormattedDate,
      }
    case 'diagnosis':
      console.log(storeData)
      return {
        ...data,
        availableToAll: true,
        approvedForAllBy: storeData.userId,
        approvedForAllAt: todayFormattedDate,
      }
    case 'health-complaint':
      return {
        ...data,
        availableToAll: true,
        approvedForAllBy: storeData.userId,
        approvedForAllAt: todayFormattedDate,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.mentalStateType:
      const mentalStateTypeDataToReturn = await getSavableMentalStateTypeObject(data)
      return { ...mentalStateTypeDataToReturn }
    case 'rest':
      return { ...data, internalTitle: data.title }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.activity:
      return { ...data, activityId: data?.activityId?.value }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.planPhase:
      return { ...data, _newPhase: null }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.oohMsg:
      console.log({ typeClinicId: typeof data?.clinicId })
      const oohMsgClinicIdToUse =
        typeof data?.clinicId === 'object' ? data?.clinicId?.id : data?.clinicId
      return { ...data, clinicId: oohMsgClinicIdToUse || null }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.inAppMessages: {
      return await transformSendInAppMsgData(data, storeData)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.notifications: {
      return await transformPostNotificationData(data)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.metaTabSetting: {
      return await transformSendMetaTabSettingData(data)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.displayMessageTemplates: {
      return { ...data, apiParam: ALL_ENTITY_TYPES_FROM_CONSTANTS?.displayMessageTemplates }
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.planBundlePack: {
      return transformSendBundlePacksData({
        ...data,
        apiParam: ALL_ENTITY_TYPES_FROM_CONSTANTS?.planBundlePack,
      })
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.clinicSubscriptionPlan: {
      return transformClinicSubscriptionPlan({
        ...data,
        apiParam: ALL_ENTITY_TYPES_FROM_CONSTANTS?.clinicSubscriptionPlan,
      })
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.clinicBrand: {
      return await transformPostClinicBrandData(data, storeData)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.prescriptionTemplate: {
      return await transformPostPrescriptionTemplateData(data)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.paymentGatewaySKU: {
      return await transformPaymentGatewayPostData(data, storeData)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.clinicChamber: {
      return await transformPostClinicChamberData(data, storeData)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.satelliteCall: {
      return await transformSatelliteCallData(data, storeData)
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.clinicAssistant:
      return {
        ...data,
        userId: data?.userId?.userId,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.paywalls:
      return transformPaywallPostData(data)
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.investigation:
      return transformInvestigationPostData(data)
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.instruction:
      return {
        ...data,
        availableToAll: true,
        approvedForAllBy: storeData.userId,
        approvedForAllAt: todayFormattedDate,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.feeModel:
      return transformFeeModelData(data)
    default:
      return data
  }
}
