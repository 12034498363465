import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'

export async function transformFeeModelListingData({ listingData }) {
  const feeModelIds = listingData?.map((listingData) => listingData?.id)
  const entityFeeModelCountMap = await getFeeModelToEntityFeeModelCountMap(feeModelIds)

  return listingData?.map((data) => {
    return { ...data, entityFeeModelsUsingThisFeeModel: entityFeeModelCountMap?.[data?.id] || 0 }
  })
}

async function getFeeModelToEntityFeeModelCountMap(feeModelIds = []) {
  if (feeModelIds?.length === 0) {
    return {}
  }
  const uniqueFeeModelIds = new Set(feeModelIds)

  const resp = await GetSettingsData('fee-model/count-attached-entity-fee-models', {
    ids: uniqueFeeModelIds?.join(','),
  })
  if (resp.status === 200) {
    return resp?.data
  }
  return {}
}
