import { Button, Grid, IconButton } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import React, { useState } from 'react'
import { FEE_RANGE } from 'src/common/types/feeModel/FeeRange'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import {
  getPriceStringWithCurrency,
  giveGenericHandleChangedData,
  isValidNumber,
  openInNewTab,
} from 'src/utilities/generalUtils'
import { getFeeModelFeeRangeString, getNewRange } from '../utils'

export default function EntityFeeRanges({
  feeRanges,
  setFeeRanges,
  disabled,
  relevantFeeModels,
  loadingFeeModels,
  refreshFeeModels,
  currencyObject,
}) {
  console.log({ relevantFeeModels })

  function handleChangeFeeRange(e, idx) {
    setFeeRanges((prev) => {
      return (prev || [])?.map((range, rIdx) => {
        return rIdx === idx ? giveGenericHandleChangedData(e, range) : range
      })
    })
  }

  function addFeeRange() {
    setFeeRanges((prev) => {
      const oldRangeList = [...(prev || [])]
      const newRange = getNewRange(FEE_RANGE, oldRangeList)
      return [...oldRangeList, newRange]
    })
  }

  function deleteFeeRange(index) {
    setFeeRanges((prev) => {
      return prev?.filter((range, idx) => {
        return index !== idx
      })
    })
  }

  return (
    <>
      {feeRanges?.map((range, idx) => {
        return (
          <EntityFeeRange
            feeRange={range}
            handleChangeFeeRange={(e) => {
              handleChangeFeeRange(e, idx)
            }}
            disabled={disabled}
            loadingFeeModels={loadingFeeModels}
            isLastItem={idx === (feeRanges?.length || 0) - 1}
            relevantFeeModels={relevantFeeModels}
            refreshFeeModels={refreshFeeModels}
            deleteFeeRange={() => {
              deleteFeeRange(idx)
            }}
            currencyObject={currencyObject}
            key={idx}
          />
        )
      })}
      {!disabled && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={addFeeRange}
            endIcon={<Add />}
            variant="contained"
            sx={{ padding: '8px 80px' }}
          >
            Add
          </Button>
        </Grid>
      )}
    </>
  )
}

function EntityFeeRange({
  feeRange,
  handleChangeFeeRange,
  disabled,
  deleteFeeRange,
  loadingFeeModels,
  relevantFeeModels,
  isLastItem,
  currencyObject,
}) {
  return (
    <>
      <Grid item xs={12}>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CCFormInput
                id="min"
                value={feeRange?.min}
                type="number"
                label={`Fee Range Min ${!!currencyObject ? ` in (${currencyObject?.symbol})` : ''}`}
                onChange={handleChangeFeeRange}
                required
                invalid={!isValidNumber(feeRange?.min)}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CCFormInput
                id="max"
                value={feeRange?.max}
                type="number"
                label={`Fee Range Max ${!!currencyObject ? ` in (${currencyObject?.symbol})` : ''}`}
                onChange={handleChangeFeeRange}
                required={!isLastItem}
                invalid={!isLastItem && !isValidNumber(feeRange?.max)}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex align-items-center gap-1 mb-1">
                <span>Model</span>
                <span>*</span>
                <Button
                  disabled={!feeRange?.modelId}
                  sx={{textDecoration: 'underline', textTransform: 'unset'}}
                  size="small"
                  onClick={() => {
                    openInNewTab(
                      `/health-care/business-models/business-model-templates/${feeRange?.modelId}?mode=View`,
                    )
                  }}
                >
                  View
                </Button>
              </div>
              <CustomReactSelect
                id="modelId"
                value={feeRange?.modelId}
                formLabelClass="d-flex align-items-center gap-1"
                type="number"
                noOptionTextEllipisis
                isLoading={loadingFeeModels}
                options={relevantFeeModels}
                getOptionValue={(optn) => optn?.id}
                getOptionLabel={(optn) =>
                  `${optn?.title || 'No Title'} (Reference Fee Range: ${getFeeModelFeeRangeString(
                    optn?.refFeeRange,
                    optn?.currencyCode,
                  )})`
                }
                onChangeEvent={handleChangeFeeRange}
                selectType={selectTypes.string}
                required
                invalid={!feeRange?.modelId}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          {!disabled && (
            <IconButton sx={{ flexShrink: 0 }} onClick={deleteFeeRange}>
              <Delete />
            </IconButton>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <hr></hr>
      </Grid>
    </>
  )
}
