import { CalendarMonth } from '@mui/icons-material'

export const currency_list = [
  { name: 'Albania Lek', code: 'all', symbol: 'Lek' },
  { name: 'Afghanistan Afghani', code: 'afn', symbol: '؋' },
  { name: 'Argentina Peso', code: 'ars', symbol: '$' },
  { name: 'Aruba Guilder', code: 'awg', symbol: 'ƒ' },
  { name: 'Australia Dollar', code: 'aud', symbol: '$' },
  { name: 'Azerbaijan Manat', code: 'azn', symbol: '₼' },
  { name: 'Bahamas Dollar', code: 'bsd', symbol: '$' },
  { name: 'Barbados Dollar', code: 'bbd', symbol: '$' },
  { name: 'Belarus Ruble', code: 'byn', symbol: 'Br' },
  { name: 'Belize Dollar', code: 'bzd', symbol: 'BZ$' },
  { name: 'Bermuda Dollar', code: 'bmd', symbol: '$' },
  { name: 'Bolivia Bolíviano', code: 'bob', symbol: '$b' },
  { name: 'Bosnia and Herzegovina Convertible Mark', code: 'bam', symbol: 'KM' },
  { name: 'Botswana Pula', code: 'bwp', symbol: 'P' },
  { name: 'Bulgaria Lev', code: 'bgn', symbol: 'лв' },
  { name: 'Brazil Real', code: 'brl', symbol: 'R$' },
  { name: 'Brunei Darussalam Dollar', code: 'bnd', symbol: '$' },
  { name: 'Cambodia Riel', code: 'khr', symbol: '៛' },
  { name: 'Canada Dollar', code: 'cad', symbol: '$' },
  { name: 'Cayman Islands Dollar', code: 'kyd', symbol: '$' },
  { name: 'Chile Peso', code: 'clp', symbol: '$' },
  { name: 'China Yuan Renminbi', code: 'cny', symbol: '¥' },
  { name: 'Colombia Peso', code: 'cop', symbol: '$' },
  { name: 'Costa Rica Colon', code: 'crc', symbol: '₡' },
  { name: 'Croatia Kuna', code: 'hrk', symbol: 'kn' },
  { name: 'Cuba Peso', code: 'cup', symbol: '₱' },
  { name: 'Czech Republic Koruna', code: 'czk', symbol: 'Kč' },
  { name: 'Denmark Krone', code: 'dkk', symbol: 'kr' },
  { name: 'Dominican Republic Peso', code: 'dop', symbol: 'RD$' },
  { name: 'East Caribbean Dollar', code: 'xcd', symbol: '$' },
  { name: 'Egypt Pound', code: 'egp', symbol: '£' },
  { name: 'El Salvador Colon', code: 'svc', symbol: '$' },
  { name: 'Euro Member Countries', code: 'eur', symbol: '€' },
  { name: 'Falkland Islands (Malvinas) Pound', code: 'fkp', symbol: '£' },
  { name: 'Fiji Dollar', code: 'fjd', symbol: '$' },
  { name: 'Ghana Cedi', code: 'ghs', symbol: '¢' },
  { name: 'Gibraltar Pound', code: 'gip', symbol: '£' },
  { name: 'Guatemala Quetzal', code: 'gtq', symbol: 'Q' },
  { name: 'Guernsey Pound', code: 'ggp', symbol: '£' },
  { name: 'Guyana Dollar', code: 'gyd', symbol: '$' },
  { name: 'Honduras Lempira', code: 'hnl', symbol: 'L' },
  { name: 'Hong Kong Dollar', code: 'hkd', symbol: '$' },
  { name: 'Hungary Forint', code: 'huf', symbol: 'Ft' },
  { name: 'Iceland Krona', code: 'isk', symbol: 'kr' },
  { name: 'India Rupee', code: 'inr', symbol: '₹' },
  { name: 'Indonesia Rupiah', code: 'idr', symbol: 'Rp' },
  { name: 'Iran Rial', code: 'irr', symbol: '﷼' },
  { name: 'Isle of Man Pound', code: 'imp', symbol: '£' },
  { name: 'Israel Shekel', code: 'ils', symbol: '₪' },
  { name: 'Jamaica Dollar', code: 'jmd', symbol: 'J$' },
  { name: 'Japan Yen', code: 'jpy', symbol: '¥' },
  { name: 'Jersey Pound', code: 'jep', symbol: '£' },
  { name: 'Kazakhstan Tenge', code: 'kzt', symbol: 'лв' },
  { name: 'Korea (North) Won', code: 'kpw', symbol: '₩' },
  { name: 'Korea (South) Won', code: 'krw', symbol: '₩' },
  { name: 'Kyrgyzstan Som', code: 'kgs', symbol: 'лв' },
  { name: 'Laos Kip', code: 'lak', symbol: '₭' },
  { name: 'Lebanon Pound', code: 'lbp', symbol: '£' },
  { name: 'Liberia Dollar', code: 'lrd', symbol: '$' },
  { name: 'Macedonia Denar', code: 'mkd', symbol: 'ден' },
  { name: 'Malaysia Ringgit', code: 'myr', symbol: 'RM' },
  { name: 'Mauritius Rupee', code: 'mur', symbol: '₨' },
  { name: 'Mexico Peso', code: 'mxn', symbol: '$' },
  { name: 'Mongolia Tughrik', code: 'mnt', symbol: '₮' },
  { name: 'Moroccan-dirham', code: 'mnt', symbol: 'د.إ' },
  { name: 'Mozambique Metical', code: 'mzn', symbol: 'MT' },
  { name: 'Namibia Dollar', code: 'nad', symbol: '$' },
  { name: 'Nepal Rupee', code: 'npr', symbol: '₨' },
  { name: 'Netherlands Antilles Guilder', code: 'ang', symbol: 'ƒ' },
  { name: 'New Zealand Dollar', code: 'nzd', symbol: '$' },
  { name: 'Nicaragua Cordoba', code: 'nio', symbol: 'C$' },
  { name: 'Nigeria Naira', code: 'ngn', symbol: '₦' },
  { name: 'Norway Krone', code: 'nok', symbol: 'kr' },
  { name: 'Oman Rial', code: 'omr', symbol: '﷼' },
  { name: 'Pakistan Rupee', code: 'pkr', symbol: '₨' },
  { name: 'Panama Balboa', code: 'pab', symbol: 'B/.' },
  { name: 'Paraguay Guarani', code: 'pyg', symbol: 'Gs' },
  { name: 'Peru Sol', code: 'pen', symbol: 'S/.' },
  { name: 'Philippines Peso', code: 'php', symbol: '₱' },
  { name: 'Poland Zloty', code: 'pln', symbol: 'zł' },
  { name: 'Qatar Riyal', code: 'qar', symbol: '﷼' },
  { name: 'Romania Leu', code: 'ron', symbol: 'lei' },
  { name: 'Russia Ruble', code: 'rub', symbol: '₽' },
  { name: 'Saint Helena Pound', code: 'shp', symbol: '£' },
  { name: 'Saudi Arabia Riyal', code: 'sar', symbol: '﷼' },
  { name: 'Serbia Dinar', code: 'rsd', symbol: 'Дин.' },
  { name: 'Seychelles Rupee', code: 'scr', symbol: '₨' },
  { name: 'Singapore Dollar', code: 'sgd', symbol: '$' },
  { name: 'Solomon Islands Dollar', code: 'sbd', symbol: '$' },
  { name: 'Somalia Shilling', code: 'sos', symbol: 'S' },
  { name: 'South Korean Won', code: 'krw', symbol: '₩' },
  { name: 'South Africa Rand', code: 'zar', symbol: 'R' },
  { name: 'Sri Lanka Rupee', code: 'lkr', symbol: '₨' },
  { name: 'Sweden Krona', code: 'sek', symbol: 'kr' },
  { name: 'Switzerland Franc', code: 'chf', symbol: 'CHF' },
  { name: 'Suriname Dollar', code: 'srd', symbol: '$' },
  { name: 'Syria Pound', code: 'syp', symbol: '£' },
  { name: 'Taiwan New Dollar', code: 'twd', symbol: 'NT$' },
  { name: 'Thailand Baht', code: 'thb', symbol: '฿' },
  { name: 'Trinidad and Tobago Dollar', code: 'ttd', symbol: 'TT$' },
  { name: 'Turkey Lira', code: 'try', symbol: '₺' },
  { name: 'Tuvalu Dollar', code: 'tvd', symbol: '$' },
  { name: 'Ukraine Hryvnia', code: 'uah', symbol: '₴' },
  { name: 'UAE-Dirham', code: 'aed', symbol: 'د.إ' },
  { name: 'United Kingdom Pound', code: 'gbp', symbol: '£' },
  { name: 'United States Dollar', code: 'usd', symbol: '$' },
  { name: 'Uruguay Peso', code: 'uyu', symbol: '$U' },
  { name: 'Uzbekistan Som', code: 'uzs', symbol: 'лв' },
  { name: 'Venezuela Bolívar', code: 'vef', symbol: 'Bs' },
  { name: 'Viet Nam Dong', code: 'vnd', symbol: '₫' },
  { name: 'Yemen Rial', code: 'yer', symbol: '﷼' },
  { name: 'Zimbabwe Dollar', code: 'zwd', symbol: 'Z$' },
]
export const timezones_list = [
  {
    name: '(GMT-11:00) Midway Island',
    timezone: 'Pacific/Midway',
    gmtvalue: '-11:00',
    id: 'pacific-midway',
  },
  {
    name: '(GMT-11:00) Samoa',
    timezone: 'US/Samoa',
    gmtvalue: '-11:00',
    id: 'us-samoa',
  },
  {
    name: '(GMT-10:00) Hawaii',
    timezone: 'US/Hawaii',
    gmtvalue: '-10:00',
    id: 'us-hawaii',
  },
  {
    name: '(GMT-09:00) Alaska',
    timezone: 'US/Alaska',
    gmtvalue: '-09:00',
    id: 'us-alaska',
  },
  {
    name: '(GMT-08:00) Pacific Time (US & Canada)',
    timezone: 'US/Pacific',
    gmtvalue: '-08:00',
    id: 'us-pacific',
  },
  {
    name: '(GMT-08:00) Tijuana',
    timezone: 'America/Tijuana',
    gmtvalue: '-08:00',
    id: 'america-tijuana',
  },
  {
    name: '(GMT-07:00) Arizona',
    timezone: 'US/Arizona',
    gmtvalue: '-07:00',
    id: 'us-arizona',
  },
  {
    name: '(GMT-07:00) Mountain Time (US & Canada)',
    timezone: 'US/Mountain',
    gmtvalue: '-07:00',
    id: 'us-mountain',
  },
  {
    name: '(GMT-07:00) Chihuahua',
    timezone: 'America/Chihuahua',
    gmtvalue: '-07:00',
    id: 'america-chihuahua',
  },
  {
    name: '(GMT-07:00) Mazatlan',
    timezone: 'America/Mazatlan',
    gmtvalue: '-07:00',
    id: 'america-mazatlan',
  },
  {
    name: '(GMT-06:00) Mexico City',
    timezone: 'America/Mexico_City',
    gmtvalue: '-06:00',
    id: 'america-mexico_city',
  },
  {
    name: '(GMT-06:00) Monterrey',
    timezone: 'America/Monterrey',
    gmtvalue: '-06:00',
    id: 'america-monterrey',
  },
  {
    name: '(GMT-06:00) Saskatchewan',
    timezone: 'Canada/Saskatchewan',
    gmtvalue: '-06:00',
    id: 'canada-saskatchewan',
  },
  {
    name: '(GMT-06:00) Central Time (US & Canada)',
    timezone: 'US/Central',
    gmtvalue: '-06:00',
    id: 'us-central',
  },
  {
    name: '(GMT-05:00) Eastern Time (US & Canada)',
    timezone: 'US/Eastern',
    gmtvalue: '-05:00',
    id: 'us-eastern',
  },
  {
    name: '(GMT-05:00) Indiana (East)',
    timezone: 'US/East-Indiana',
    gmtvalue: '-05:00',
    id: 'us-east-indiana',
  },
  {
    name: '(GMT-05:00) Bogota',
    timezone: 'America/Bogota',
    gmtvalue: '-05:00',
    id: 'america-bogota',
  },
  {
    name: '(GMT-05:00) Lima',
    timezone: 'America/Lima',
    gmtvalue: '-05:00',
    id: 'america-lima',
  },
  {
    name: '(GMT-04:30) Caracas',
    timezone: 'America/Caracas',
    gmtvalue: '-04:30',
    id: 'america-caracas',
  },
  {
    name: '(GMT-04:00) Atlantic Time (Canada)',
    timezone: 'Canada/Atlantic',
    gmtvalue: '-04:00',
    id: 'canada-atlantic',
  },
  {
    name: '(GMT-04:00) La Paz',
    timezone: 'America/La_Paz',
    gmtvalue: '-04:00',
    id: 'america-la_paz',
  },
  {
    name: '(GMT-04:00) Santiago',
    timezone: 'America/Santiago',
    gmtvalue: '-04:00',
    id: 'america-santiago',
  },
  {
    name: '(GMT-03:30) Newfoundland',
    timezone: 'Canada/Newfoundland',
    gmtvalue: '-03:30',
    id: 'canada-newfoundland',
  },
  {
    name: '(GMT-03:00) Buenos Aires',
    timezone: 'America/Buenos_Aires',
    gmtvalue: '-03:00',
    id: 'america-buenos_aires',
  },
  {
    name: '(GMT-03:00) Greenland',
    timezone: 'Greenland',
    gmtvalue: '-03:00',
    id: 'greenland',
  },
  {
    name: '(GMT-02:00) Stanley',
    timezone: 'Atlantic/Stanley',
    gmtvalue: '-02:00',
    id: 'atlantic-stanley',
  },
  {
    name: '(GMT-01:00) Azores',
    timezone: 'Atlantic/Azores',
    gmtvalue: '-01:00',
    id: 'atlantic-azores',
  },
  {
    name: '(GMT-01:00) Cape Verde Is.',
    timezone: 'Atlantic/Cape_Verde',
    gmtvalue: '-01:00',
    id: 'atlantic-cape_verde',
  },
  {
    name: '(GMT+00:00) Casablanca',
    timezone: 'Africa/Casablanca',
    gmtvalue: '+00:00',
    id: 'africa-casablanca',
  },
  {
    name: '(GMT+00:00) Dublin',
    timezone: 'Europe/Dublin',
    gmtvalue: '+00:00',
    id: 'europe-dublin',
  },
  {
    name: '(GMT+00:00) Lisbon',
    timezone: 'Europe/Lisbon',
    gmtvalue: '+00:00',
    id: 'europe-lisbon',
  },
  {
    name: '(GMT+00:00) London',
    timezone: 'Europe/London',
    gmtvalue: '+00:00',
    id: 'europe-london',
  },
  {
    name: '(GMT+00:00) Monrovia',
    timezone: 'Africa/Monrovia',
    gmtvalue: '+00:00',
    id: 'africa-monrovia',
  },
  {
    name: '(GMT+01:00) Amsterdam',
    timezone: 'Europe/Amsterdam',
    gmtvalue: '+01:00',
    id: 'europe-amsterdam',
  },
  {
    name: '(GMT+01:00) Belgrade',
    timezone: 'Europe/Belgrade',
    gmtvalue: '+01:00',
    id: 'europe-belgrade',
  },
  {
    name: '(GMT+01:00) Berlin',
    timezone: 'Europe/Berlin',
    gmtvalue: '+01:00',
    id: 'europe-berlin',
  },
  {
    name: '(GMT+01:00) Bratislava',
    timezone: 'Europe/Bratislava',
    gmtvalue: '+01:00',
    id: 'europe-bratislava',
  },
  {
    name: '(GMT+01:00) Brussels',
    timezone: 'Europe/Brussels',
    gmtvalue: '+01:00',
    id: 'europe-brussels',
  },
  {
    name: '(GMT+01:00) Budapest',
    timezone: 'Europe/Budapest',
    gmtvalue: '+01:00',
    id: 'europe-budapest',
  },
  {
    name: '(GMT+01:00) Copenhagen',
    timezone: 'Europe/Copenhagen',
    gmtvalue: '+01:00',
    id: 'europe-copenhagen',
  },
  {
    name: '(GMT+01:00) Ljubljana',
    timezone: 'Europe/Ljubljana',
    gmtvalue: '+01:00',
    id: 'europe-ljubljana',
  },
  {
    name: '(GMT+01:00) Madrid',
    timezone: 'Europe/Madrid',
    gmtvalue: '+01:00',
    id: 'europe-madrid',
  },
  {
    name: '(GMT+01:00) Paris',
    timezone: 'Europe/Paris',
    gmtvalue: '+01:00',
    id: 'europe-paris',
  },
  {
    name: '(GMT+01:00) Prague',
    timezone: 'Europe/Prague',
    gmtvalue: '+01:00',
    id: 'europe-prague',
  },
  {
    name: '(GMT+01:00) Rome',
    timezone: 'Europe/Rome',
    gmtvalue: '+01:00',
    id: 'europe-rome',
  },
  {
    name: '(GMT+01:00) Sarajevo',
    timezone: 'Europe/Sarajevo',
    gmtvalue: '+01:00',
    id: 'europe-sarajevo',
  },
  {
    name: '(GMT+01:00) Skopje',
    timezone: 'Europe/Skopje',
    gmtvalue: '+01:00',
    id: 'europe-skopje',
  },
  {
    name: '(GMT+01:00) Stockholm',
    timezone: 'Europe/Stockholm',
    gmtvalue: '+01:00',
    id: 'europe-stockholm',
  },
  {
    name: '(GMT+01:00) Vienna',
    timezone: 'Europe/Vienna',
    gmtvalue: '+01:00',
    id: 'europe-vienna',
  },
  {
    name: '(GMT+01:00) Warsaw',
    timezone: 'Europe/Warsaw',
    gmtvalue: '+01:00',
    id: 'europe-warsaw',
  },
  {
    name: '(GMT+01:00) Zagreb',
    timezone: 'Europe/Zagreb',
    gmtvalue: '+01:00',
    id: 'europe-zagreb',
  },
  {
    name: '(GMT+02:00) Athens',
    timezone: 'Europe/Athens',
    gmtvalue: '+02:00',
    id: 'europe-athens',
  },
  {
    name: '(GMT+02:00) Bucharest',
    timezone: 'Europe/Bucharest',
    gmtvalue: '+02:00',
    id: 'europe-bucharest',
  },
  {
    name: '(GMT+02:00) Cairo',
    timezone: 'Africa/Cairo',
    gmtvalue: '+02:00',
    id: 'africa-cairo',
  },
  {
    name: '(GMT+02:00) Harare',
    timezone: 'Africa/Harare',
    gmtvalue: '+02:00',
    id: 'africa-harare',
  },
  {
    name: '(GMT+02:00) Helsinki',
    timezone: 'Europe/Helsinki',
    gmtvalue: '+02:00',
    id: 'europe-helsinki',
  },
  {
    name: '(GMT+02:00) Istanbul',
    timezone: 'Europe/Istanbul',
    gmtvalue: '+02:00',
    id: 'europe-istanbul',
  },
  {
    name: '(GMT+02:00) Jerusalem',
    timezone: 'Asia/Jerusalem',
    gmtvalue: '+02:00',
    id: 'asia-jerusalem',
  },
  {
    name: '(GMT+02:00) Kyiv',
    timezone: 'Europe/Kiev',
    gmtvalue: '+02:00',
    id: 'europe-kiev',
  },
  {
    name: '(GMT+02:00) Minsk',
    timezone: 'Europe/Minsk',
    gmtvalue: '+02:00',
    id: 'europe-minsk',
  },
  {
    name: '(GMT+02:00) Riga',
    timezone: 'Europe/Riga',
    gmtvalue: '+02:00',
    id: 'europe-riga',
  },
  {
    name: '(GMT+02:00) Sofia',
    timezone: 'Europe/Sofia',
    gmtvalue: '+02:00',
    id: 'europe-sofia',
  },
  {
    name: '(GMT+02:00) Tallinn',
    timezone: 'Europe/Tallinn',
    gmtvalue: '+02:00',
    id: 'europe-tallinn',
  },
  {
    name: '(GMT+02:00) Vilnius',
    timezone: 'Europe/Vilnius',
    gmtvalue: '+02:00',
    id: 'europe-vilnius',
  },
  {
    name: '(GMT+03:00) Baghdad',
    timezone: 'Asia/Baghdad',
    gmtvalue: '+03:00',
    id: 'asia-baghdad',
  },
  {
    name: '(GMT+03:00) Kuwait',
    timezone: 'Asia/Kuwait',
    gmtvalue: '+03:00',
    id: 'asia-kuwait',
  },
  {
    name: '(GMT+03:00) Nairobi',
    timezone: 'Africa/Nairobi',
    gmtvalue: '+03:00',
    id: 'africa-nairobi',
  },
  {
    name: '(GMT+03:00) Riyadh',
    timezone: 'Asia/Riyadh',
    gmtvalue: '+03:00',
    id: 'asia-riyadh',
  },
  {
    name: '(GMT+03:00) Moscow',
    timezone: 'Europe/Moscow',
    gmtvalue: '+03:00',
    id: 'europe-moscow',
  },
  {
    name: '(GMT+03:30) Tehran',
    timezone: 'Asia/Tehran',
    gmtvalue: '+03:30',
    id: 'asia-tehran',
  },
  {
    name: '(GMT+04:00) Baku',
    timezone: 'Asia/Baku',
    gmtvalue: '+04:00',
    id: 'asia-baku',
  },
  {
    name: '(GMT+04:00) Volgograd',
    timezone: 'Europe/Volgograd',
    gmtvalue: '+04:00',
    id: 'europe-volgograd',
  },
  {
    name: '(GMT+04:00) Muscat',
    timezone: 'Asia/Muscat',
    gmtvalue: '+04:00',
    id: 'asia-muscat',
  },
  {
    name: '(GMT+04:00) Tbilisi',
    timezone: 'Asia/Tbilisi',
    gmtvalue: '+04:00',
    id: 'asia-tbilisi',
  },
  {
    name: '(GMT+04:00) Yerevan',
    timezone: 'Asia/Yerevan',
    gmtvalue: '+04:00',
    id: 'asia-yerevan',
  },
  {
    name: '(GMT+04:30) Kabul',
    timezone: 'Asia/Kabul',
    gmtvalue: '+04:30',
    id: 'asia-kabul',
  },
  {
    name: '(GMT+05:00) Karachi',
    timezone: 'Asia/Karachi',
    gmtvalue: '+05:00',
    id: 'asia-karachi',
  },
  {
    name: '(GMT+05:00) Tashkent',
    timezone: 'Asia/Tashkent',
    gmtvalue: '+05:00',
    id: 'asia-tashkent',
  },
  {
    name: '(GMT+05:30) Kolkata',
    timezone: 'Asia/Kolkata',
    gmtvalue: '+05:30',
    id: 'asia-kolkata',
  },
  {
    name: '(GMT+05:45) Kathmandu',
    timezone: 'Asia/Kathmandu',
    gmtvalue: '+05:45',
    id: 'asia-kathmandu',
  },
  {
    name: '(GMT+06:00) Ekaterinburg',
    timezone: 'Asia/Yekaterinburg',
    gmtvalue: '+06:00',
    id: 'asia-yekaterinburg',
  },
  {
    name: '(GMT+06:00) Almaty',
    timezone: 'Asia/Almaty',
    gmtvalue: '+06:00',
    id: 'asia-almaty',
  },
  {
    name: '(GMT+06:00) Dhaka',
    timezone: 'Asia/Dhaka',
    gmtvalue: '+06:00',
    id: 'asia-dhaka',
  },
  {
    name: '(GMT+07:00) Novosibirsk',
    timezone: 'Asia/Novosibirsk',
    gmtvalue: '+07:00',
    id: 'asia-novosibirsk',
  },
  {
    name: '(GMT+07:00) Bangkok',
    timezone: 'Asia/Bangkok',
    gmtvalue: '+07:00',
    id: 'asia-bangkok',
  },
  {
    name: '(GMT+07:00) Jakarta',
    timezone: 'Asia/Jakarta',
    gmtvalue: '+07:00',
    id: 'asia-jakarta',
  },
  {
    name: '(GMT+08:00) Krasnoyarsk',
    timezone: 'Asia/Krasnoyarsk',
    gmtvalue: '+08:00',
    id: 'asia-krasnoyarsk',
  },
  {
    name: '(GMT+08:00) Chongqing',
    timezone: 'Asia/Chongqing',
    gmtvalue: '+08:00',
    id: 'asia-chongqing',
  },
  {
    name: '(GMT+08:00) Hong Kong',
    timezone: 'Asia/Hong_Kong',
    gmtvalue: '+08:00',
    id: 'asia-hong_kong',
  },
  {
    name: '(GMT+08:00) Kuala Lumpur',
    timezone: 'Asia/Kuala_Lumpur',
    gmtvalue: '+08:00',
    id: 'asia-kuala_lumpur',
  },
  {
    name: '(GMT+08:00) Perth',
    timezone: 'Australia/Perth',
    gmtvalue: '+08:00',
    id: 'australia-perth',
  },
  {
    name: '(GMT+08:00) Singapore',
    timezone: 'Asia/Singapore',
    gmtvalue: '+08:00',
    id: 'asia-singapore',
  },
  {
    name: '(GMT+08:00) Taipei',
    timezone: 'Asia/Taipei',
    gmtvalue: '+08:00',
    id: 'asia-taipei',
  },
  {
    name: '(GMT+08:00) Ulaan Bataar',
    timezone: 'Asia/Ulaanbaatar',
    gmtvalue: '+08:00',
    id: 'asia-ulaanbaatar',
  },
  {
    name: '(GMT+08:00) Urumqi',
    timezone: 'Asia/Urumqi',
    gmtvalue: '+08:00',
    id: 'asia-urumqi',
  },
  {
    name: '(GMT+09:00) Irkutsk',
    timezone: 'Asia/Irkutsk',
    gmtvalue: '+09:00',
    id: 'asia-irkutsk',
  },
  {
    name: '(GMT+09:00) Seoul',
    timezone: 'Asia/Seoul',
    gmtvalue: '+09:00',
    id: 'asia-seoul',
  },
  {
    name: '(GMT+09:00) Tokyo',
    timezone: 'Asia/Tokyo',
    gmtvalue: '+09:00',
    id: 'asia-tokyo',
  },
  {
    name: '(GMT+09:30) Adelaide',
    timezone: 'Australia/Adelaide',
    gmtvalue: '+09:30',
    id: 'australia-adelaide',
  },
  {
    name: '(GMT+09:30) Darwin',
    timezone: 'Australia/Darwin',
    gmtvalue: '+09:30',
    id: 'australia-darwin',
  },
  {
    name: '(GMT+10:00) Yakutsk',
    timezone: 'Asia/Yakutsk',
    gmtvalue: '+10:00',
    id: 'asia-yakutsk',
  },
  {
    name: '(GMT+10:00) Brisbane',
    timezone: 'Australia/Brisbane',
    gmtvalue: '+10:00',
    id: 'australia-brisbane',
  },
  {
    name: '(GMT+10:00) Canberra',
    timezone: 'Australia/Canberra',
    gmtvalue: '+10:00',
    id: 'australia-canberra',
  },
  {
    name: '(GMT+10:00) Guam',
    timezone: 'Pacific/Guam',
    gmtvalue: '+10:00',
    id: 'pacific-guam',
  },
  {
    name: '(GMT+10:00) Hobart',
    timezone: 'Australia/Hobart',
    gmtvalue: '+10:00',
    id: 'australia-hobart',
  },
  {
    name: '(GMT+10:00) Melbourne',
    timezone: 'Australia/Melbourne',
    gmtvalue: '+10:00',
    id: 'australia-melbourne',
  },
  {
    name: '(GMT+10:00) Port Moresby',
    timezone: 'Pacific/Port_Moresby',
    gmtvalue: '+10:00',
    id: 'pacific-port_moresby',
  },
  {
    name: '(GMT+10:00) Sydney',
    timezone: 'Australia/Sydney',
    gmtvalue: '+10:00',
    id: 'australia-sydney',
  },
  {
    name: '(GMT+11:00) Vladivostok',
    timezone: 'Asia/Vladivostok',
    gmtvalue: '+11:00',
    id: 'asia-vladivostok',
  },
  {
    name: '(GMT+12:00) Magadan',
    timezone: 'Asia/Magadan',
    gmtvalue: '+12:00',
    id: 'asia-magadan',
  },
  {
    name: '(GMT+12:00) Auckland',
    timezone: 'Pacific/Auckland',
    gmtvalue: '+12:00',
    id: 'pacific-auckland',
  },
  {
    name: '(GMT+12:00) Fiji',
    timezone: 'Pacific/Fiji',
    gmtvalue: '+12:00',
    id: 'pacific-fiji',
  },
]

export const UNIQUE_COLORS_100 = [
  '#ac16d9',
  '#ec6703',
  '#26cae0',
  '#024adb',
  '#162595',
  '#1eaf11',
  '#75ad4b',
  '#57d74f',
  '#d11c50',
  '#afd9db',
  '#e81a70',
  '#3ed642',
  '#d402d6',
  '#1a6b13',
  '#80604e',
  '#eec445',
  '#da1d3d',
  '#b5e8d1',
  '#679f9a',
  '#1bb240',
  '#cf0291',
  '#91c66f',
  '#bfb51f',
  '#e1a9da',
  '#26d062',
  '#dee4cb',
  '#ac4967',
  '#14d7b5',
  '#baa1d1',
  '#0683e6',
  '#d0820e',
  '#4a1293',
  '#50580a',
  '#cfaad8',
  '#5427d1',
  '#fb94eb',
  '#3e3c20',
  '#cb2058',
  '#86bd6f',
  '#c78d13',
  '#fc1b98',
  '#5c7d47',
  '#92b1a9',
  '#e42469',
  '#9b378b',
  '#d37d92',
  '#f80805',
  '#ed3b33',
  '#331609',
  '#2f58be',
  '#870572',
  '#15bb05',
  '#1ca48e',
  '#31ffa6',
  '#9d8618',
  '#e63b2f',
  '#242f14',
  '#c1c8e0',
  '#a49a55',
  '#6847ae',
  '#a970de',
  '#40a74f',
  '#a92b8e',
  '#86818b',
  '#e27023',
  '#98c3ec',
  '#a361b0',
  '#6d3a17',
  '#cfc951',
  '#2e4e39',
  '#ee8012',
  '#42bd15',
  '#4e0246',
  '#31dbe3',
  '#60ba8a',
  '#e92996',
  '#d57ce3',
  '#fc72f6',
  '#d4cd3d',
  '#73e830',
  '#317f2b',
  '#7d1247',
  '#89849e',
  '#201b1c',
  '#f0a189',
  '#f27a34',
  '#1ee273',
  '#b9e7d6',
  '#33d83c',
  '#65aae7',
  '#9eb70b',
  '#1ac062',
  '#9fe615',
  '#06a91a',
  '#2a37a3',
  '#0f1f6f',
  '#2f3606',
  '#22ddbc',
  '#1c205b',
  '#27d540',
]

export const AUMHUM_API_SERVER_MAP = {
  dev: 'https://dev.aumhum.xyz',
  stg: 'https://stg.aumhum.xyz',
  live: 'https://live.aumhum.xyz',
  ganga: 'https://ganga.aumhum.xyz',
  office: 'http://172.16.0.206:8080/aumhum',
  localHost: 'http://localhost:8080/aumhum',
  localHostFive: 'http://localhost:5000/aumhum',
}
export const AUMHUM_URL_MAP = {
  [AUMHUM_API_SERVER_MAP.dev]: 'https://aumhum.com',
  [AUMHUM_API_SERVER_MAP.stg]: 'https://aumhum.com',
  [AUMHUM_API_SERVER_MAP.live]: 'https://aumhum.com',
  [AUMHUM_API_SERVER_MAP.office]: 'https://aumhum.com',
  [AUMHUM_API_SERVER_MAP.localHost]: 'https://aumhum.com',
  [AUMHUM_API_SERVER_MAP.localHostFive]: 'https://aumhum.com',
}

export function getAumhumUrlMap(serverDomain) {
  return AUMHUM_URL_MAP?.[serverDomain] || 'https://aumhum.com'
}

export const REVERSED_AUMHUM_API_SERVER_MAP = Object.fromEntries(
  Object.entries(AUMHUM_API_SERVER_MAP).map(([key, value]) => [value, key]),
)
export const NEXT_API_SERVER_MAP = {
  [AUMHUM_API_SERVER_MAP.dev]: 'STG',
  [AUMHUM_API_SERVER_MAP.stg]: 'LIVE',
  [AUMHUM_API_SERVER_MAP.live]: 'Office Server',
  [AUMHUM_API_SERVER_MAP.office]: 'Localhost',
  [AUMHUM_API_SERVER_MAP.localHost]: 'DEV',
}

export const ARTICLE_TYPES = [
  { id: 'blog', title: 'Public Article' },
  { id: 'assessment-report', title: 'Assessment Report Article' },
]

export const TAG_TYPE = {
  domain: 'domain',
  tag: 'tag',
  feeling: 'feeling',
  genre: 'genre',
}

export const HLS_TRACK_TYPES_MAP = {
  hls_audio: 'hls_audio',
  hls_video: 'hls_video',
}

export const HLS_TRACK_TYPES = [
  { id: HLS_TRACK_TYPES_MAP.hls_audio, title: 'Audio' },
  { id: HLS_TRACK_TYPES_MAP.hls_video, title: 'Video' },
]
export const VIDEO_ORIENTATION_TYPES = [
  { id: 'landscape', title: 'Landscape' },
  { id: 'portrait', title: 'Portrait' },
]
export const ACTIVITY_TYPES = [
  { id: 'challenge', title: 'Challenge' },
  { id: 'plan', title: 'Plan' },
]

export const IdTypes = {
  slug: 'slug',
  id: 'id',
  list: 'list',
  type: 'type',
}

export const LINK_TYPES = {
  deep: 'deep_links',
  hyperlink: 'hyperlinks',
}

export const META_CATEGORY_TYPES_TO_API_PARAM = {
  quotes: 'quote',
  'service-provider': 'mentor',
  tiles: 'tile-policy',
  mentalStateType: 'mental-state-type',
  practice: 'technique',
}

export const META_CATEGORY_SCOPE_TYPES = [
  { id: 'app', title: 'App' },
  { id: 'server', title: 'Server/System' },
]

export const NOTIFICATIONS_MEDIUMS = [
  { id: 'PUSH', title: 'Push Notification' },
  { id: 'MAIL', title: 'Email' },
]

export const USERS_COMPONENT_VALIDITY_TYPES = {
  noData: 'noData',
}

export const ALL_AVAILABLE_PLATFORMS = {
  web: 'web',
  android: 'android',
  ios: 'ios',
}

export const ALL_AVAILABLE_PLATFORMS_ARRAY = [
  { id: ALL_AVAILABLE_PLATFORMS.web, title: 'Web' },
  { id: ALL_AVAILABLE_PLATFORMS.android, title: 'Android', mobile: true },
  { id: ALL_AVAILABLE_PLATFORMS.ios, title: 'iOS', mobile: true },
]

export const SAVE_MODES = {
  normal: 'normal',
  draft: 'draft',
  published: 'published',
}

export const TAB_ICON_VIEWS = {
  icon: 'icon',
  coverIcon: 'coverIcon',
  any: 'any',
}

export const POPUP_SCREEN_TYPES = [
  { id: 'quote_settings', title: 'Quote Settings' },
  { id: 'profile_settings', title: 'Profile Settings' },
  { id: 'call_with_founder', title: 'Call With Founder' },
  { id: 'feedback', title: 'Open Feedback' },
  { id: 'app_language_change', title: 'Open Language Popup' },
]

export const TAG_TYPES = [
  { id: 'tag', title: 'Tag' },
  { id: 'domain', title: 'Domains' },
  { id: 'feeling', title: 'Feelings' },
  { id: 'Genre', title: 'Genre' },
]

export const MENTOR_EXTRA_ITEM_OPTIONS = [{ id: 'booking-request', title: 'Booked Events' }]

export const LISTING_VIEWS = {
  list: 'list',
  grid: 'grid',
}

export const NOTIFICATION_ACTION_TYPES = [
  { id: 'join-call', title: 'Join Call' },
  { id: 'booking-reminder', title: 'Reminder' },
]

export const ALL_GENDERS_IN_APP_MSGS = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'nonBinary', title: 'Non Binary' },
  { id: 'other', title: 'Other' },
  { id: 'noAnswer', title: 'Prefer not to state' },
]

export const ALL_GENDERS = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'nonBinary', title: 'Non Binary' },
]

export const ALL_USER_STATES = [
  { id: 'S1_first_launch', title: 'First launch' },
  { id: 'S2_onboarding_started', title: 'Onboarding started' },
  { id: 'S3_primary_genre_selected', title: 'Primary genre Selected' },
  { id: 'S4_onboarded_plan_later', title: 'Onboarded Plan Later' },
  { id: 'S5_onboarded_with_plan', title: 'Onboarded With Plan' },
  { id: 'S6_first_play_started', title: 'First Play Started' },
  { id: 'S7_play_incomplete_left', title: 'Play Incomplete Left' },
  { id: 'S8_play_complete_OB_FIN', title: 'Play Completed' },
]
export const SUBSCRIPTION_TYPES_LAYMAN = [
  { id: 'regular', title: 'Regular' },
  { id: 'freeTrial', title: 'Free Trial' },
]

export const SUBSCRIPTION_STATUS = {
  free: {
    id: 'free',
    title: 'Free',
  },
  premium_trial: {
    id: 'premium_trial',
    title: 'Premium Trial',
  },
  paid: {
    id: 'paid',
    title: 'Paid',
  },
}

export const SUBSCRIPTION_STATUS_ARRAY = Object.values(SUBSCRIPTION_STATUS)

export const ALL_SUBSCRIPTION_LEVELS = [
  {
    id: 'subscription_free',
    name: 'Free',
  },
  {
    id: 'subscription_normal',
    name: 'Premium',
  },
  {
    id: 'subscription_premium',
    name: 'Super-premium',
  },
]

export const PLAN_GROUP_TYPES = {
  corporate: 'corporate',
  consumer: 'consumer-subscription-plan',
  featureBundle: 'feature-bundle',
  bundle: 'bundle',
}

export const SUBSCRIPTION_AUDIENCE_TYPES = [
  {
    id: 'corporate',
    title: 'Corporate Users',
  },
  {
    id: 'endUsers',
    title: 'End Users',
  },
]

export const FEATURE_BUNDLE_TYPES_ARRAY = [
  {
    id: 'count_based',
    title: 'Count Based',
  },
  {
    id: 'time_based',
    title: 'Time Based',
  },
]

export const GENDER_LIST_ONBOARDING = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'nonBinary', label: 'Non-Binary' },
]

export const PRESCRIPTION_TEMPLATES_TYPES = {
  onBlankSheet: 'onBlankSheet',
  onHeaderFooterPrinted: 'onHeaderFooterPrinted',
  on3QuartersPrinted: 'on3QuartersPrinted',
}

export const PRESCRIPTION_LAYOUTS = {
  default: 'default',
  threeColumnFooter: '3ColumnFooter',
  twoColumnFooter: '2ColumnFooter',
  twoColumnWithDisclaimerFooter: 'twoColumnWithDisclaimerFooter',
}

export const PRESCRIPTION_LAYOUTS_ARRAY = [
  { id: PRESCRIPTION_LAYOUTS.default, title: 'Default' },
  { id: PRESCRIPTION_LAYOUTS.threeColumnFooter, title: '3 Column Footer' },
  { id: PRESCRIPTION_LAYOUTS.twoColumnFooter, title: '2 Column Footer' },
  {
    id: PRESCRIPTION_LAYOUTS.twoColumnWithDisclaimerFooter,
    title: 'Two column footer with disclaimer',
  },
]

export const PRESCRIPTION_TEMPLATES_TYPES_ARRAY = [
  { id: PRESCRIPTION_TEMPLATES_TYPES.onBlankSheet, title: 'Blank Sheet' },
  { id: PRESCRIPTION_TEMPLATES_TYPES.onHeaderFooterPrinted, title: 'On Header Footer Printed' },
  { id: PRESCRIPTION_TEMPLATES_TYPES.on3QuartersPrinted, title: 'On 3 Quarters Printed' },
]

export const ESCALATION_LEVEL = [
  { id: 'L1', title: 'Level 1' },
  { id: 'L2', title: 'Level 2' },
  { id: 'L3', title: 'Level 3' },
]

export const SUPPORT_MODE = [
  {
    id: 'whatsapp',
    correspondingId: 'supportWhatsappId',
    title: 'Whatsapp',
    subTitle: 'Whatsapp',
  },
  {
    id: 'phone',
    correspondingId: 'supportPhoneNumber',
    title: 'Phone',
    subTitle: 'Phone Number',
  },
  { id: 'email', correspondingId: 'supportEmail', title: 'Email', subTitle: 'Email Id' },
]

export const REDUX_REDUCERS_STATES = {
  set: 'set',
  SET_DROPDOWN_DATA: 'SET_DROPDOWN_DATA',
  SET_CLINIC_FLOW_DATA: 'SET_CLINIC_FLOW_DATA',
}

export const OOH_MSG_TYPES = {
  youtube: 'youtube_video',
  image: 'image',
}

export const OOH_MSG_TYPES_ARRAY = [
  {
    id: OOH_MSG_TYPES.youtube,
    title: 'Youtube Video',
  },
  {
    id: OOH_MSG_TYPES.image,
    title: 'Image',
  },
]

export const ALL_ENTITY_TYPES_FROM_CONSTANTS = {
  acquisitionModes: 'acquisition-modes',
  activity: 'activity',
  activityPlan: 'activity-plan',
  agreement: 'agreement',
  article: 'article',
  assessment: 'assessment',
  approvalRequest: 'approval-request',
  badge: 'badge',
  breathe: 'breathe',
  backgroundMusic: 'background-music',
  bookedEvent: 'booked-event-info',
  chamberRoom: 'chamber-room',
  chamberRoomActivityLog: 'chamber-room-activity-log',
  cacheEvict: 'cache-evict',
  clinic: 'clinic',
  clinicAssistant: 'clinic-assistant',
  clinicAssistantUser: 'clinic-assistant-users',
  clinicBrand: 'clinic-brand',
  clinicChamber: 'clinic-chamber',
  clinicSubscriptionPlan: 'clinic-subscription-plan',
  clinicPayoutModels: 'clinic-payout-models',
  cmsUsers: 'cms-users',
  consumerSubscriptionPlan: 'consumer-subscription-plan',
  courseCmsFieldType: 'course-cms-field-type',
  courseApprovalFeedback: 'course-approval-feedback',
  courseDraft: 'course-draft',
  clinicEngagementModels: 'clinic-engagement-models',
  doctorUsers: 'doctor-users',
  diagnosis: 'diagnosis',
  displayMessageTemplates: 'display-msg-template',
  drugInfo: 'drug-info',
  drugAdviceDuration: 'drug-advice-duration',
  drugAdviceDosage: 'drug-advice-dosage',
  drugAdviceFrequency: 'drug-advice-frequency',
  drugAdviceNote: 'drug-advice-note',
  drugAdviceTiming: 'drug-advice-timing',
  entityFeeModel: 'entity-fee-model',
  faqCategory: 'faq-category',
  faqScope: 'faq-scope',
  faqs: 'faqs',
  feeModel: 'fee-model',
  focusArea: 'focus-area',
  guest: 'guest',
  healthComplaint: 'health-complaint',
  hint: 'hint',
  imageTypes: 'image-types',
  inAppMessages: 'in-app-messages',
  investigation: 'investigation',
  journal: 'journal',
  mentalState: 'mental-state',
  mentalStateType: 'mental-state-type',
  mentor: 'mentor',
  metaCategoryType: 'meta-category-type',
  metaTabSetting: 'meta-tab-setting',
  medicalBoardMember: 'medical-board-member',
  investigationSample: 'investigation-sample',
  instruction: 'instruction',
  notifications: 'push-notification-template',
  oohMsg: 'ooh-msg',
  onboardingPolicy: 'onboarding-policy',
  onboardingType: 'onboarding-type',
  onlineCourse: 'course',
  payoutDistributionReports: 'payout-distribution-reports',
  paywalls: 'paywall',
  paymentGatewaySKU: 'payment-gateway-sku',
  paymentGateways: 'payment-gateway',
  planBundlePack: 'plan-bundle-pack',
  planGroups: 'meta_subscription_plan_grps',
  planPhase: 'plan-phase',
  practice: 'practice',
  prescriptionTemplate: 'prescription-template',
  patientInfo: 'PatientInfo',
  quote: 'quote',
  reflection: 'reflection',
  rest: 'rest',
  role: 'role',
  satelliteCall: 'satellite-call',
  segment: 'segment',
  sentinelCard: 'sentinel-card',
  subscriptionPlan: 'subscription-plan',
  subscriptionPlanType: 'subscription-plan-type',
  supportStaff: 'support-staff',
  supportUser: 'support-user',
  sesTemplate: 'ses-template',
  tab: 'tab',
  tag: 'tag',
  technique: 'technique',
  tilePolicy: 'tile-policy',
  tiles: 'tiles',
  timeSlots: 'timeslots',
  therapistPayoutModels: 'therapist-payout-models',
  therapistEngagementModels: 'therapist-engagement-models',
  userStats: 'user-stats',
  users: 'end-users',
  unPublishRequest: 'unpublish-request',
  webinar: 'webinar',
}

export const ONLINE_COURSE_STATUSES = [
  { id: 'draft', title: 'Draft' },
  { id: 'pending', title: 'Pending' },
  { id: 'rejected', title: 'Rejected' },
  { id: 'published', title: 'Published' },
  { id: 'unpublished', title: 'UnPublished' },
]

export const ONLINE_COURSE_MEDIA_TYPES = {
  video: 'video',
  audio: 'audio',
}

export const ONLINE_COURSE_MEDIA_TYPES_ARRAY = [
  { id: ONLINE_COURSE_MEDIA_TYPES.video, title: 'Video' },
  { id: ONLINE_COURSE_MEDIA_TYPES.audio, title: 'Audio' },
]

export const ONLINE_COURSE_MEDIA_TYPES_TO_HLS_TYPES_MAP = {
  video: HLS_TRACK_TYPES_MAP.hls_video,
  audio: HLS_TRACK_TYPES_MAP.hls_audio,
}

export const ONLINE_COURSE_AUTOPLAY_TYPE = [
  { id: true, title: 'Yes' },
  { id: false, title: 'No' },
]
export const ONLINE_COURSE_TYPES = [
  { id: 'Single', title: 'Single' },
  { id: 'Multiple', title: 'Multiple' },
]

export const ASSESSMENT_PAYWALL_OPTIONS = [
  { id: 'start', title: 'Start' },
  { id: 'result', title: 'Result' },
]

export const SUBSCRIPTION_IAPS = {
  android: 'android_iap',
  ios: 'ios_iap',
  giftUp: 'gift_up',
  all: 'all',
}

export const SUBSCRIPTION_IAPS_ARRAY = [
  { id: SUBSCRIPTION_IAPS.android, title: 'Android IAP' },
  { id: SUBSCRIPTION_IAPS.ios, title: 'iOS IAP' },
  { id: SUBSCRIPTION_IAPS.giftUp, title: 'Gift Up' },
  { id: SUBSCRIPTION_IAPS.all, title: 'All' },
]

export const SUBSCRIPTION_IAPS_PLATFORMS = {
  android: 'android',
  ios: 'ios',
  giftUp: 'gift_up',
}

export function TRUE_FALSE_OPTIONS({ trueTitle = 'Yes', falseTitle = 'No', showAll = false }) {
  return [
    { id: true, title: trueTitle },
    { id: false, title: falseTitle },
    ...(showAll ? [{ id: 'all', title: 'All' }] : []),
  ]
}

export const CLINIC_ASSISTANT_SUPPORT_ROLES_TYPES = {
  therapist: 'therapist',
  doctor: 'doctor',
}

export const FIELD_TYPES_OBJECT_FIELD_TYPES = [
  {
    id: 'Instant',
    title: 'Instant (Time and date)',
  },
  {
    id: 'String',
    title: 'String',
  },
  {
    id: 'Boolean',
    title: 'Boolean',
  },
  {
    id: 'Array',
    title: 'List (array)',
  },
  {
    id: 'object',
    title: 'Object (Map)',
  },
]

export const GENERIC_DEFAULT_ACTIONS = {
  view: 'view',
  edit: 'edit',
  enable_disable: 'enable_disable',
}

export const SECTION_OBJECT_FIELD_TYPES = [
  {
    id: 'object',
    title: 'Regular',
  },
  {
    id: 'list',
    title: 'List',
  },
]

export const MOMENT_FORMATS = {
  completeTimeDetailsWithMonthShortForm: 'D MMM, YYYY h:mm a',
  completeDateDetailsWithMonthShortFormAndDay: 'ddd, DD MMM, YYYY',
  completeDateDetailsWithMonthShortForm: 'DD MMM, YYYY',
  formatThatServerSends: 'YYYY-MM-DD HH:mm:ss',
  completeTimeDetails: 'DD MMMM YYYY h:mm A',
  completeTimeDetailsWithTimeZone: 'DD/MM/YYYY h:mm A (Z)',
  completeDateDetailsWithTimeZone: 'DD/MM/YYYY (Z)',
  javaInstantFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  javaInstantFormatNew: 'YYYY-MM-DDTHH:mm:ss[Z]',
  formattedTime24Hrs: 'h:mm a',
}

export const PAYMENT_MODES = [
  { id: 'in-app', title: 'In App' },
  { id: 'cash', title: 'Cash' },
  { id: 'online', title: 'Online' },
  { id: 'reward (unpaid)', title: 'Reward (Unpaid)' },
]

export const SEGMENT_CRITERIA_TYPE_TYPES = {
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
}
export const SEGMENT_CRITERIA_TYPE_TYPES_ARRAY = [
  { id: SEGMENT_CRITERIA_TYPE_TYPES.INCLUDE, title: 'Incl' },
  { id: SEGMENT_CRITERIA_TYPE_TYPES.EXCLUDE, title: 'Excl' },
]

export const SEGEMENT_CRITERIA_DEVICE_TYPES = ALL_AVAILABLE_PLATFORMS

export const SEGEMENT_CRITERIA_DEVICE_TYPES_ARRAY = ALL_AVAILABLE_PLATFORMS_ARRAY

export const CRITERIA_OPERATORS = [
  { id: 'eq', title: '==' },
  { id: 'gt', title: '>' },
  { id: 'gte', title: '>=' },
  { id: 'lt', title: '<' },
  { id: 'lte', title: '<=' },
]

export const SUPPORTED_DEVICE_TYPE = [
  { id: 'iPhone', title: 'iPhone', osType: ALL_AVAILABLE_PLATFORMS.ios },
  { id: 'iPad', title: 'iPad', osType: ALL_AVAILABLE_PLATFORMS.ios },
]

export const BACKGROUND_MUSIC_TYPES = [
  { id: 'breathe', title: 'Breathe' },
  { id: 'course', title: 'Course' },
]

export const COURSE_CMS_REF_TYPES = {
  add: 'add',
  clonse: 'clone',
}

export const ROLE_ACCESS_LEVELS = {
  edit: 'edit',
  readOnly: 'readOnly',
  manage: 'readOnly',
}

export const ROLE_ACCESS_LEVELS_ARRAY = [
  { id: ROLE_ACCESS_LEVELS.edit, title: 'Edit' },
  { id: ROLE_ACCESS_LEVELS.readOnly, title: 'Read Only' },
  { id: ROLE_ACCESS_LEVELS.manage, title: 'Manage' },
]

export const COURSE_CMS_ROLE_ACCESS_LEVELS = {
  edit: 'edit',
  readOnly: 'readOnly',
}

export const COURSE_CMS_ROLE_ACCESS_LEVELS_ARRAY = [
  { id: COURSE_CMS_ROLE_ACCESS_LEVELS.edit, title: 'Edit' },
  { id: COURSE_CMS_ROLE_ACCESS_LEVELS.readOnly, title: 'Read Only' },
]

export const COURSE_ROLES_MAP = {
  ROLE_MENTOR: {
    routeId: 'mentor',
    roleId: 'ROLE_MENTOR',
    title: 'Mentor',
  },
  ROLE_CONTENT_EDITOR: {
    routeId: 'content-editor',
    roleId: 'ROLE_CONTENT_EDITOR',
    title: 'Content Editor',
  },
  ROLE_CONTENT_APPROVER: {
    routeId: 'content-approver',
    roleId: 'ROLE_CONTENT_APPROVER',
    title: 'Content Approver',
  },
  ROLE_CONTENT_PUBLISHER: {
    routeId: 'content-publisher',
    roleId: 'ROLE_CONTENT_PUBLISHER',
    title: 'Content Publisher',
  },
}

export const INVERSE_COURSE_ROLES_FROM_ROUTE_ID = Object.fromEntries(
  Object.entries(COURSE_ROLES_MAP).map(([key, value]) => [value.routeId, key]),
)

export const COURSE_ROLES_ARRAY = Object.values(COURSE_ROLES_MAP)

export const COURSE_DRAFT_STATUS_TYPES = {
  active: 'active',
  stashed: 'stashed',
  submittedSoInActive: 'submitted-so-inactive',
}

export const ROLE_TYPES = {
  mentor: 'mentor',
  user: 'user',
  clinic: 'clinic',
  business: 'business',
}

export const ROLE_TYPES_ARRAY = [
  { id: ROLE_TYPES.mentor, title: 'Mentor' },
  { id: ROLE_TYPES.user, title: 'User' },
  { id: ROLE_TYPES.clinic, title: 'Clinic' },
  { id: ROLE_TYPES.business, title: 'Business' },
]

export const UNITS_ARRAY = [
  { id: 'units/L', title: 'units/L' },
  { id: 'g/dL', title: 'g/dL' },
  { id: 'mg/dL', title: 'mg/dL' },
  { id: 'mEq/L', title: 'mEq/L' },
  { id: 'mm Hg', title: 'mm Hg' },
  { id: 'hemoglobin/cell', title: 'hemoglobin/cell' },
  { id: 'µmol/L', title: 'µmol/L' },
  { id: 'mEq/L', title: 'mEq/L' },
]

export const INVESTIGATION_TYPES = {
  test: 'test',
  panel: 'panel',
}

export const INVESTIGATION_TYPES_ARRAY = [
  { id: INVESTIGATION_TYPES.test, title: 'Marker' },
  { id: INVESTIGATION_TYPES.panel, title: 'Panel' },
]

export const INVESTIGATION_TEST_TYPES = {
  audiology: 'audiology',
  imaging: 'imaging',
  microscopy: 'microscopy',
  pathology: 'pathology',
}

export const INVESTIGATION_TEST_TYPES_ARRAY = [
  { id: INVESTIGATION_TEST_TYPES.audiology, title: 'Audiology' },
  { id: INVESTIGATION_TEST_TYPES.imaging, title: 'Imaging' },
  { id: INVESTIGATION_TEST_TYPES.microscopy, title: 'Microscopy' },
  { id: INVESTIGATION_TEST_TYPES.pathology, title: 'Pathology' },
]

export const SAMPLE_TYPE = {
  blood: 'blood',
  urine: 'urine',
  eye: 'eye',
  swab: 'swab',
  imaging: 'imaging',
  saliva: 'saliva',
  electrodesOnScalp: 'electrodes-on-scalp',
  semen: 'semen',
}

export const SAMPLE_TYPE_ARRAY = [
  { id: SAMPLE_TYPE.blood, title: 'Blood' },
  { id: SAMPLE_TYPE.urine, title: 'Urine' },
  { id: SAMPLE_TYPE.eye, title: 'Eye' },
  { id: SAMPLE_TYPE.swab, title: 'Swab' },
  { id: SAMPLE_TYPE.imaging, title: 'Imaging' },
  { id: SAMPLE_TYPE.saliva, title: 'Saliva' },
  { id: SAMPLE_TYPE.electrodesOnScalp, title: 'Electrodes On Scalp' },
  { id: SAMPLE_TYPE.semen, title: 'Semen' },
]

export const REPORT_DATE_TYPES = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
}

export const REPORT_DATE_TYPES_ARRAY = [
  { id: REPORT_DATE_TYPES.day, title: 'Day' },
  { id: REPORT_DATE_TYPES.week, title: 'Week' },
  { id: REPORT_DATE_TYPES.month, title: 'Month' },
  { id: REPORT_DATE_TYPES.year, title: 'Year' },
]

export const EVENT_TYPE_ONE_ON_ONE = '1on1'
export const EVENT_TYPE_INTERVIEW = 'interview'
export const EVENT_TYPE_SCREENING = 'screening'
export const EVENT_TYPE_CONSULTATION = 'consultation'
export const EVENT_TYPE_THERAPY_SESSION = 'therapy-session'
export const EVENT_TYPE_WEBINAR = 'webinar'

export const EVENT_SUBTYPE_INDIVIDUAL_THERAPY = 'individual-therapy'
export const EVENT_SUBTYPE_COUPLE_THERAPY = 'couple-therapy'
export const EVENT_SUBTYPE_THERAPIST_ASSESSMENT = 'therapist-assessment'

export const EVENT_TYPES_ARRAY = [
  {
    id: EVENT_TYPE_ONE_ON_ONE,
    title: 'One on One',
    mentorNoun: 'Therapist',
    subtypes: [
      {
        id: EVENT_SUBTYPE_INDIVIDUAL_THERAPY,
        title: 'Individual Therapy',
      },
    ],
  },
  {
    id: EVENT_TYPE_INTERVIEW,
    title: 'Interview',
    mentorNoun: 'Interviewer',
    subtypes: [],
  },
  {
    id: EVENT_TYPE_SCREENING,
    title: 'Screening',
    mentorNoun: 'Screener',
    subtypes: [],
  },
  {
    id: EVENT_TYPE_CONSULTATION,
    title: 'Consultation',
    mentorNoun: 'Doctor',
    subtypes: [],
  },
  {
    id: EVENT_TYPE_THERAPY_SESSION,
    title: 'Therapy Session',
    mentorNoun: 'Therapist',
    subtypes: [
      {
        id: EVENT_SUBTYPE_INDIVIDUAL_THERAPY,
        title: 'Individual Therapy',
      },
      {
        id: EVENT_SUBTYPE_COUPLE_THERAPY,
        title: 'Couple Therapy',
      },
    ],
  },
  {
    id: EVENT_TYPE_WEBINAR,
    title: 'Webinar',
    mentorNoun: 'Mentor',
    subtypes: [],
  },
]

export const SERVICE_TYPES = {
  consultation: {
    id: 'consultation',
    title: 'Consultations',
  },
  therapy: {
    id: 'therapy',
    title: 'Therapy',
  },
  'psychological-assessment': {
    id: 'psychological-assessment',
    title: 'Psychological Assessment',
  },
  other: {
    id: 'other',
    title: 'Other',
  },
}

export const SERVICE_TYPES_ARRAY = [
  SERVICE_TYPES.consultation,
  SERVICE_TYPES.therapy,
  SERVICE_TYPES['psychological-assessment'],
  SERVICE_TYPES.other,
]

export function findEventTypeObjectByEventTypeId(eTypeId) {
  return EVENT_TYPES_ARRAY.find((eType) => eType.id === eTypeId)
}

export function getEventTypeTitle(eventTypeId) {
  switch (eventTypeId) {
    case EVENT_TYPE_INTERVIEW:
      return 'Interview'
    case EVENT_TYPE_SCREENING:
      return 'Screening'
    case EVENT_TYPE_CONSULTATION:
      return 'Consultation'
    case EVENT_TYPE_THERAPY_SESSION:
      return 'Therapy'
    case EVENT_TYPE_WEBINAR:
      return 'Webinar'
    default:
      return 'One on One'
  }
}

export const PRESCRIPTION_PRINT_TYPES = {
  withHeaderAndFooterData: 'withHeaderAndFooterData',
  withHeaderAndFooterBlankSpace: 'withHeaderAndFooterBlankSpace',
}

export const PRESCRIPTION_PRINT_TYPES_ARRAY = [
  { id: PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterData, title: 'With header footer' },
  {
    id: PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace,
    title: 'Blank space in header and footer',
  },
]

export const COMMUNICATION_TYPES = {
  none: 'none',
  mail: 'mail',
  pushNotification: 'push-notification',
  whatsapp: 'whatsapp',
  sms: 'sms',
}

export const COMMUNICATION_TYPES_ARRAY = [
  { id: COMMUNICATION_TYPES.none, title: 'None' },
  {
    id: COMMUNICATION_TYPES.mail,
    title: 'Email',
  },
  {
    id: COMMUNICATION_TYPES.pushNotification,
    title: 'Push Notification',
  },
  {
    id: COMMUNICATION_TYPES.whatsapp,
    title: 'Whatsapp',
  },
  {
    id: COMMUNICATION_TYPES.sms,
    title: 'Sms',
  },
]

export const APPLICABLE_TO_ENTITY = {
  clinic: { id: 'clinic', title: 'Clinic' },
  mentor: { id: 'mentor', title: 'Mentor' },
}

export const APPLICABLE_TO_ENTITY_ARRAY = [
  { id: APPLICABLE_TO_ENTITY.clinic.id, title: APPLICABLE_TO_ENTITY.clinic.title },
  {
    id: APPLICABLE_TO_ENTITY.mentor.id,
    title: APPLICABLE_TO_ENTITY.mentor.title,
  },
]

// clinics: { id: 'clinics', title: 'Clinics' },
// private: { id: 'private', title: 'Private' },
// aumhumDirect: { id: 'aumhum-direct', title: 'AUMHUM Direct' },
export const ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES = {
  'clinic-doctors': {
    id: 'clinic-doctors',
    title: 'Clinic Doctors',
    titleForEntityModel: 'Doctors on Clinic Rolls',
    for: APPLICABLE_TO_ENTITY.clinic.id,
  },
  'clinic-therapists': {
    id: 'clinic-therapists',
    title: 'Clinic Therapists',
    titleForEntityModel: 'Therapists on Clinic Rolls',
    for: APPLICABLE_TO_ENTITY.clinic.id,
  },
  'aumhum-network-doctors': {
    id: 'aumhum-network-doctors',
    title: 'AUMHUM Network Doctors',
    titleForEntityModel: 'AUMHUM Provided Doctors',
    for: APPLICABLE_TO_ENTITY.clinic.id,
  },
  'aumhum-network-therapists': {
    id: 'aumhum-network-therapists',
    title: 'AUMHUM Network Therapists',
    titleForEntityModel: 'AUMHUM Provided Therapists',
    for: APPLICABLE_TO_ENTITY.clinic.id,
  },
  'aumhum-network-clinics': {
    id: 'aumhum-network-clinics',
    title: 'AUMHUM Network Clinics',
    for: APPLICABLE_TO_ENTITY.mentor.id,
    longDesc:
      'Patients coming via clinics in different part of the world. These patients belong to the clinic and you are providing the service on behalf of the clinic. Clinic usually collects money here.',
  },
  'private-patients': {
    id: 'private-patients',
    title: 'Private Patients',
    for: APPLICABLE_TO_ENTITY.mentor.id,
    longDesc: 'These are your existing personal patients, from before joining AUMHUM.',
  },
  'aumhum-direct-patients': {
    id: 'aumhum-direct-patients',
    title: 'AUMHUM Direct Patients',
    for: APPLICABLE_TO_ENTITY.mentor.id,
    longDesc:
      'Patients coming directly through different AUMHUM programs and not through clinics. Examples are Potential Bloom Program, AUMHUM de-addiction packages and directly serving customers.',
  },
}

export const ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY = [
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES['aumhum-direct-patients'],
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES['aumhum-network-clinics'],
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES['aumhum-network-doctors'],
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES['aumhum-network-therapists'],
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES['clinic-doctors'],
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES['clinic-therapists'],
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES['private-patients'],
]

export const EVENT_MODES = {
  online: { id: 'online', title: 'Online' },
  offline: { id: 'offline', title: 'Offline' },
}

export const EVENT_MODES_ARRAY = [EVENT_MODES.online, EVENT_MODES.offline]
export const BOOKED_EVENT_MODES_TYPES = {
  online: 'aumhum',
  offline: 'offline',
}
export const BOOKED_EVENT_MODES = {
  aumhum: { id: BOOKED_EVENT_MODES_TYPES.online, title: 'Online', altTitle: 'Online' },
  offline: { id: BOOKED_EVENT_MODES_TYPES.offline, title: 'Offline', altTitle: 'In Person' },
}

export const PAYMENT_METHOD_KV_MAP = {
  BOOKING_FEE_PAYMENT_METHOD_CASH: 'cash',
  BOOKING_FEE_PAYMENT_METHOD_CARD: 'debit_and_credit_cards',
  BOOKING_FEE_PAYMENT_METHOD_PHONEPE: 'phonepe',
  BOOKING_FEE_PAYMENT_METHOD_GPAY: 'google_pay',
  BOOKING_FEE_PAYMENT_METHOD_PAYTM: 'paytm',
  BOOKING_FEE_PAYMENT_METHOD_OTHER_UPI: 'other_upi',
  BOOKING_FEE_PAYMENT_METHOD_COLLECTED_BY_AUMHUM: 'aumhum',
}

export const PAYMENT_METHOD_TITLE_MAP = {
  cash: 'Cash',
  debit_and_credit_cards: 'Debit and Credit Cards',
  phonepe: 'PhonePe',
  google_pay: 'Google Pay',
  paytm: 'Paytm',
  other_upi: 'Other UPI',
  aumhum: 'Collected by Aumhum',
}

export const PAYMENT_METHODS_ARRAY = [
  {
    id: PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_CASH,
    title: PAYMENT_METHOD_TITLE_MAP[PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_CASH],
  },
  {
    id: PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_CARD,
    title: PAYMENT_METHOD_TITLE_MAP[PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_CARD],
  },
  {
    id: PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_PHONEPE,
    title: PAYMENT_METHOD_TITLE_MAP[PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_PHONEPE],
  },
  {
    id: PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_GPAY,
    title: PAYMENT_METHOD_TITLE_MAP[PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_GPAY],
  },
  {
    id: PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_PAYTM,
    title: PAYMENT_METHOD_TITLE_MAP[PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_PAYTM],
  },
  {
    id: PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_OTHER_UPI,
    title: PAYMENT_METHOD_TITLE_MAP[PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_OTHER_UPI],
  },
  {
    id: PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_COLLECTED_BY_AUMHUM,
    title:
      PAYMENT_METHOD_TITLE_MAP[
        PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_COLLECTED_BY_AUMHUM
      ],
  },
]

// public static final String ROLE_CONTENT_EDITOR = "ROLE_CONTENT_EDITOR";
// public static final String ROLE_CONTENT_APPROVER = "ROLE_CONTENT_APPROVER";
// public static final String ROLE_CONTENT_PUBLISHER = "ROLE_CONTENT_PUBLISHER";

export const VISIT_EVENT_TYPES = {
  regular: 'regular',
  followUp: 'follow-up',
  emergency: 'emergency',
}

export const SIREN_ICON_ID = 'MEDIA-661e7309c6884802a74a4a9e6bb93f06'

export const VISIT_EVENT_TYPES_ARRAY = [
  { id: VISIT_EVENT_TYPES.regular, title: 'Regular' },
  { id: VISIT_EVENT_TYPES.followUp, title: 'Follow-Up', Icon: CalendarMonth },
  {
    id: VISIT_EVENT_TYPES.emergency,
    title: 'Emergency',
    iconImageId: SIREN_ICON_ID,
  },
]

export const USER_ROLES_MAP = {
  l2TherapySupport : 'ROLE_THERAPY_SUP_L2'
}