import { CMultiSelect } from '@coreui/react-pro'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import {
  APPLICABLE_TO_ENTITY,
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY,
} from 'src/utilities/constants'

export function getCSmartTableColumnData({ key, label, filter, sorter, _style }) {
  return {
    key,
    label,
    filter,
    sorter,
    _style,
  }
}

export function getListOfColumnsForSmartTable(apiParam, params = {}, tempApiParam) {
  switch (apiParam) {
    case ALL_ENTITY_TYPES?.mentalState:
      return [
        { key: 'orderId', label: 'Order Number', filter: false, sorter: false },
        { key: 'type', label: 'Type', filter: false, sorter: false },
      ]
    case ALL_ENTITY_TYPES.tag:
      return [
        { key: 'id', label: 'ID', filter: false },
        { key: 'coverIcon', label: 'Cover Icon', filter: false, sorter: false },
        { key: 'shortDesc', label: 'Description', filter: false, sorter: false },
        {
          key: 'type',
          label: 'Type',
          filter: (values, onChange) => {
            const unique = [...new Set(values)].sort()
            return (
              <CMultiSelect
                size="sm"
                onChange={(selected) => {
                  const _selected = selected.map((element) => {
                    return element.value
                  })
                  onChange((item) => {
                    return Array.isArray(_selected) && _selected.length
                      ? _selected.includes(item.toLowerCase())
                      : true
                  })
                }}
                options={unique.map((element) => {
                  return {
                    value: element?.toLowerCase(),
                    text: element,
                  }
                })}
              />
            )
          },
          sorter: false,
        },
        { key: 'quoteListable', label: 'Quote Listable', filter: false, sorter: false },
        { key: 'premiumQuote', label: 'Premium Quote', filter: false, sorter: false },
      ]
    case ALL_ENTITY_TYPES?.mentor:
      return [
        {
          key: 'profilePicId',
          label: '',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'title',
          label: 'Title',
          filter: false,
          sorter: true,
          _style: { minWidth: '200px' },
        },
        {
          key: 'slug',
          label: 'Public Link',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'categories',
          label: 'Categories',
          filter: false,
          sorter: false,
          _style: { minWidth: '350px', maxWidth: '350px' },
        },
        {
          key: 'country',
          label: 'Country',
          filter: false,
          sorter: false,
          _style: { minWidth: '130px' },
        },
        {
          key: 'services',
          label: 'Service Types',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        },
        {
          key: 'kycStatus',
          label: 'KYC Status',
          _style: { minWidth: '130px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES?.agreement:
      return [
        {
          key: 'title',
          label: 'Title',
          filter: false,
          sorter: true,
          _style: { minWidth: '300px' },
        },
        {
          key: 'type',
          label: 'Agreement Type',
          filter: false,
          sorter: false,
        },
        {
          key: 'roles',
          label: 'Roles',
          filter: false,
          sorter: false,
          _style: { minWidth: '301px' },
        },
        {
          key: 'metaCategories',
          label: 'Categories',
          filter: false,
          sorter: false,
          _style: { minWidth: '350px' },
        },
        { key: 'version', label: 'Version', filter: false, sorter: true },
        {
          key: 'applicableSince',
          label: 'Applicable Since',
          filter: false,
          sorter: true,
          _style: { minWidth: '200px' },
        },
      ]
    case ALL_ENTITY_TYPES.metaCategoryType:
      return [
        { key: 'scope', label: 'App Scope', filter: false, sorter: false },
        {
          key: 'itemType',
          label: 'Item Type',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'enabledForDailyPlay',
          label: 'Enabled for daily play',
          _style: { minWidth: '90px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.role:
      return [
        getCSmartTableColumnData({ key: 'id', label: 'Role ID', filter: false }),
        getCSmartTableColumnData({ key: 'title', label: 'Role', filter: false, sorter: true }),
        getCSmartTableColumnData({
          key: 'portalUrl',
          label: 'Portal URL',
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'joiningURL',
          label: 'Joining URL',
          filter: false,
          sorter: false,
          _style: {
            minWidth: '200px',
          },
        }),
        getCSmartTableColumnData({
          key: 'agreementRequired',
          label: 'Agreement Required',
          filter: false,
          sorter: false,
        }),
      ]
    case ALL_ENTITY_TYPES.journal:
      return [
        {
          key: 'slug',
          label: 'Public Link',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'tags',
          label: 'Tags',
          filter: false,
          sorter: false,
          _style: { minWidth: '400px' },
        },
        {
          key: 'subscription',
          label: 'Subscription',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'journalQusAns',
          label: 'Questions',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        },
        {
          key: 'preMsgs',
          label: 'Pre Msgs',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'postMsgs',
          label: 'Post Msgs',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
      ]
    case ALL_ENTITY_TYPES.assessment:
      return [
        {
          key: 'slug',
          label: 'Public Link',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'journalQusAns',
          label: 'Questions',
          filter: false,
          sorter: false,
          _style: { minWidth: '70px' },
        },
        {
          key: 'subscription',
          label: 'Subscription',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'assessmentDomainList',
          label: 'Domains',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        },
      ]
    case ALL_ENTITY_TYPES?.supportStaff:
      return [
        {
          key: 'user',
          label: 'User',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        },
      ]
    case ALL_ENTITY_TYPES?.clinic:
      return [
        {
          key: 'ownerId',
          label: 'Owner',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        },
        {
          key: 'cloudClinic',
          label: 'Cloud Clinic',
          _style: { minWidth: '130px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'brandName',
          label: 'Brand Name',
          _style: { minWidth: '130px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'kycStatus',
          label: 'KYC Status',
          _style: { minWidth: '130px' },
          filter: false,
          sorter: false,
        },

        {
          key: 'email',
          label: 'Clinic Email',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        },
        {
          key: 'mobile',
          label: 'Mobile/Telephone',
          filter: false,
          sorter: false,
          _style: { minWidth: '150px' },
        },
        {
          key: 'availableServices',
          label: 'Available Services',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        },
      ]
    case ALL_ENTITY_TYPES.drugInfo:
      return [
        {
          key: 'genericName',
          label: 'Generic Name',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        { key: 'dosage', label: 'Dosage', filter: false, sorter: false },
        { key: 'qty', label: 'Quantity', filter: false, sorter: false },
        { key: 'note', label: 'Note', filter: false, sorter: false },
        {
          key: 'availableToAll',
          label: 'Available To All',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.diagnosis:
    case ALL_ENTITY_TYPES.drugAdviceDosage:
      return [
        {
          key: 'availableToAll',
          label: 'Available To All',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.healthComplaint:
      return [
        {
          key: 'availableToAll',
          label: 'Available To All',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.quote:
      return [
        {
          key: 'author',
          label: 'Author',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'title',
          label: 'Quote',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'slug',
          label: 'Public Link',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.activity:
      return [
        {
          key: 'activityTitle',
          label: 'Activity Title',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'metaCategoryId',
          label: 'Category',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'descriptionZ',
          label: 'What will this activity do to you',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'descriptionW',
          label: 'Why it is being offered',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.activityPlan:
      return [
        // {
        //   key: 'description',
        //   label: 'Description',
        //   _style: { minWidth: '200px' },
        //   filter: false,
        //   sorter: false,
        // },
        {
          key: 'slug',
          label: 'Public Link',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'subscriptionType',
          label: 'Subscription Type',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'pricePlan',
          label: 'Subscription Plan',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'visibility',
          label: 'Visibility',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'domainIds',
          label: 'Domains',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'status',
          label: 'Status',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'totalDays',
          label: 'Total Days',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'numberOfPhases',
          label: 'Number of Phases',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.planPhase:
      return [
        {
          key: 'description',
          label: 'Description',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'firstDay',
          label: 'First Day',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'lastDay',
          label: 'Last Day',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES?.article:
      return [
        {
          key: 'coverIcon',
          label: 'Square Image',
          _style: { minWidth: '90px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'title',
          label: 'Title',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'slug',
          label: 'Public Link',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'subscription',
          label: 'Subscription Level',
          _style: { minWidth: '200px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES?.oohMsg:
      return [
        getCSmartTableColumnData({
          key: 'mentorName',
          label: 'Mentor',
          _style: { minWidth: '200px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'media',
          label: 'Media',
          _style: { minWidth: '200px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'title',
          label: 'Title',
          _style: { minWidth: '200px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'clinicId',
          label: 'Clinic',
          _style: { minWidth: '200px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'clinicBrandId',
          label: 'Clinic Brand',
          _style: { minWidth: '200px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'durationInSec',
          label: 'Message Duration (in seconds)',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'orderId',
          label: 'Order ID',
          _style: { minWidth: '100px' },
          filter: false,
        }),
      ]
    case ALL_ENTITY_TYPES?.notifications:
      return [
        {
          key: 'iconImage',
          label: 'Icon Image',
          _style: { minWidth: '90px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'internalTitle',
          label: 'Internal Title',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'title',
          label: 'Title',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'description',
          label: 'Notification Description',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'Type',
          label: 'Type',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'medium',
          label: 'Medium',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES?.metaTabSetting:
      return [
        {
          key: 'description',
          label: 'Description',
          _style: { minWidth: '300px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'iosTabs',
          label: 'iOS Tabs',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'androidTabs',
          label: 'Android Tabs',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'webTabs',
          label: 'Web Tabs',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.tab:
      return [
        {
          key: 'image',
          label: 'Title',
          _style: { minWidth: '90px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'iosIcon',
          label: 'iOS Icon',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.displayMessageTemplates:
      return [
        {
          key: 'actionBtnText',
          label: 'Action Button Text',
          _style: { minWidth: '90px' },
          filter: false,
          sorter: false,
        },
        // {
        //   key: 'actionBtnUri',
        //   label: 'Action Button URI',
        //   _style: { minWidth: '150px' },
        //   filter: false,
        //   sorter: false,
        // },
        {
          key: 'type',
          label: 'Display Message Type',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.inAppMessages:
      return [
        {
          key: 'status',
          label: 'Status',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'screens',
          label: 'Screens',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'countries',
          label: 'Countries',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'startDate',
          label: 'Start Date',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'expiryDate',
          label: 'End Date',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'showFrequency',
          label: 'Show Frequency',
          _style: { minWidth: '150px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.planBundlePack:
    case ALL_ENTITY_TYPES.clinicSubscriptionPlan:
      return [
        { key: 'internalTitle', label: 'Internal Title', filter: false, sorter: false },
        {
          key: 'title',
          label: 'Package Name (Title)',
          filter: false,
          _style: { minWidth: '300px' },
        },
        { key: 'rowPrice', label: 'ROW Price', filter: false, _style: { minWidth: '100px' } },
        {
          key: 'rowFinalPrice',
          label: 'ROW Discounted Price',
          filter: false,
          _style: { minWidth: '100px' },
        },
        { key: 'inPrice', label: 'India Price', filter: false, _style: { minWidth: '100px' } },
        {
          key: 'inFinalPrice',
          label: 'India Discounted Price',
          filter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'planGrpId',
          label: 'Plan Group',
          filter: false,
          sorter: false,
          _style: { minWidth: '150px' },
        },
        { key: 'validity', label: 'Validity', filter: false, sorter: false },
        { key: 'trialDays', label: 'Trial Days', filter: false, sorter: false },
        { key: 'recurring', label: 'Recurring', filter: false, sorter: false },
      ]
    case ALL_ENTITY_TYPES.clinicBrand:
      return [
        {
          key: 'iconId',
          label: '',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'brandName',
          label: 'Clinic Brand',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        },
        {
          key: 'slug',
          label: 'Public Link',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'kycStatus',
          label: 'KYC Status',
          _style: { minWidth: '130px' },
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.prescriptionTemplate:
      return [
        {
          key: 'type',
          label: 'Prescription Type',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'clinicId',
          label: 'Clinic',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'doctorId',
          label: 'Doctor',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
      ]
    case ALL_ENTITY_TYPES.clinicAssistant:
      return [
        {
          key: 'clinicId',
          label: 'Clinic',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
        {
          key: 'management',
          label: 'Managers',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        },
      ]
    case ALL_ENTITY_TYPES.approvalRequest:
      return [
        getCSmartTableColumnData({
          key: 'title',
          label: 'Course',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
        getCSmartTableColumnData({
          key: 'status',
          label: 'Request Status',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
      ]
    case ALL_ENTITY_TYPES.unPublishRequest:
      return [
        getCSmartTableColumnData({
          key: 'title',
          label: 'Course',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
        getCSmartTableColumnData({
          key: 'courseStatus',
          label: 'Course Status',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
        getCSmartTableColumnData({
          key: 'status',
          label: 'Request Status',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
      ]
    case ALL_ENTITY_TYPES.courseDraft:
      return [
        getCSmartTableColumnData({
          key: 'title',
          label: 'Course',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
        getCSmartTableColumnData({
          key: 'state',
          label: 'Draft State',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
      ]
    case ALL_ENTITY_TYPES.onlineCourse:
      return [
        getCSmartTableColumnData({
          key: 'title',
          label: 'Course',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
        // {
        //   key: 'languages',
        //   label: 'Languages',
        //   filter: false,
        //   sorter: false,
        //   _style: { minWidth: '100px' },
        // },
        getCSmartTableColumnData({
          key: 'status',
          label: 'Status',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
        getCSmartTableColumnData({
          key: 'publicLink',
          label: 'Public Link',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        }),

        // {
        //   key: 'checkDraftOrCourse',
        //   label: 'Draft/Course Status',
        //   filter: false,
        //   sorter: false,
        //   _style: { minWidth: '100px' },
        // },

        // {
        //   key: 'duration',
        //   label: 'Duration (in mins)',
        //   filter: false,
        //   sorter: false,
        //   _style: { minWidth: '100px' },
        // },
        // {
        //   key: 'approved',
        //   label: 'Approved',
        //   filter: false,
        //   sorter: false,
        //   _style: { minWidth: '100px' },
        // },
        // {
        //   key: 'reqToUnpublish',
        //   label: 'Req to unpublish',
        //   filter: false,
        //   sorter: false,
        //   _style: { minWidth: '100px' },
        // },
      ]
    case ALL_ENTITY_TYPES.cacheEvict:
      return [
        {
          key: 'title',
          label: 'API Param',
          filter: false,
        },
        {
          key: 'requests',
          label: 'Cache Requests',
          filter: false,
        },
        {
          key: 'hits',
          label: 'Cache Hits',
          filter: false,
        },
        {
          key: 'misses',
          label: 'Cache Misses',
          filter: false,
        },
        {
          key: 'evictionWeight',
          label: 'Eviction Weight',
          filter: false,
        },
        {
          key: 'loads',
          label: 'Cache Loads',
          filter: false,
        },
        {
          key: 'size',
          label: 'Cache Size',
          filter: false,
        },
        {
          key: 'totalLoadTime',
          label: 'Total Load Time',
          filter: false,
        },
        {
          key: 'actions',
          label: 'Actions',
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      return [
        {
          key: 'description',
          label: 'Description',
          filter: false,
          sorter: false,
        },
        {
          key: 'gateways',
          label: 'Related Gateways',
          filter: false,
          sorter: false,
        },
        {
          key: 'grpId',
          label: 'Plan Group',
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.paymentGateways:
      return [
        {
          key: 'active',
          label: 'Active',
          filter: false,
          sorter: false,
        },
        {
          key: 'title',
          label: 'Title',
          filter: false,
          sorter: false,
        },
        {
          key: 'refundPolicy',
          label: 'Refund Policy',
          filter: false,
          sorter: false,
        },
      ]
    case ALL_ENTITY_TYPES.doctorUsers:
      return [
        getCSmartTableColumnData({
          key: 'profilePicId',
          label: '',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'title',
          label: 'User',
          filter: false,
          sorter: true,
          _style: { minWidth: '200px' },
        }),
        // getCSmartTableColumnData({
        //   key: 'associations',
        //   label: 'Associations',
        //   _style: { minWidth: '100px' },
        //   filter: false,
        //   sorter: false,
        // }),
        getCSmartTableColumnData({
          key: 'authorities',
          label: 'User Roles',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'userId',
          label: 'User ID',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
      ]
    case ALL_ENTITY_TYPES.clinicAssistantUser:
      return [
        getCSmartTableColumnData({
          key: 'profilePicId',
          label: '',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'title',
          label: 'User',
          filter: false,
          sorter: true,
          _style: { minWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'associations',
          label: 'Associations',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'authorities',
          label: 'User Roles',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'userId',
          label: 'User ID',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
      ]
    case ALL_ENTITY_TYPES.users:
      return [
        getCSmartTableColumnData({
          key: 'profilePicId',
          label: '',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'title',
          label: 'User',
          filter: false,
          sorter: true,
          _style: { minWidth: '200px' },
        }),
        // getColumnData({
        //   key: 'userLanguage',
        //   label: 'Preferred Language',
        //   _style: { minWidth: '100px' },
        //   filter: false,
        //   sorter: false,
        // }),
        getCSmartTableColumnData({
          key: 'segmentId',
          label: 'Login Info',
          filter: false,
          sorter: false,
          _style: { minWidth: '480px' },
        }),
        getCSmartTableColumnData({
          key: 'authorities',
          label: 'User Roles',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
        getCSmartTableColumnData({
          key: 'userId',
          label: 'User ID',
          _style: { minWidth: '100px' },
          filter: false,
          sorter: false,
        }),
      ]
    case ALL_ENTITY_TYPES.segment:
      return [
        getCSmartTableColumnData({
          key: 'tabAndTilePolicy',
          label: 'Tabs/Tiles',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'onboardPolicy',
          label: 'Onboard',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        }),
      ]
    case ALL_ENTITY_TYPES.paywalls:
      return [
        getCSmartTableColumnData({
          key: 'currencySymbol',
          label: 'Currency',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        }),
      ]
    case ALL_ENTITY_TYPES.backgroundMusic:
      return [
        getCSmartTableColumnData({
          key: 'track',
          label: 'Music Track',
          filter: false,
          sorter: false,
          _style: { minWidth: '100px' },
        }),
      ]
    case ALL_ENTITY_TYPES.supportUser:
      return [
        getCSmartTableColumnData({
          key: 'screenName',
          label: 'Screen Name',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
      ]
    case ALL_ENTITY_TYPES.guest:
      return [
        getCSmartTableColumnData({
          key: 'description',
          label: 'Description',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
        getCSmartTableColumnData({
          key: 'publicLink',
          label: 'Public Link',
          filter: false,
          sorter: false,
          _style: { minWidth: '300px' },
        }),
      ]
    case ALL_ENTITY_TYPES.investigation:
      return [
        getCSmartTableColumnData({
          key: 'description',
          label: 'Description',
          filter: false,
          sorter: false,
          _style: { maxWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'investigationType',
          label: 'Investigation Type',
          filter: false,
          sorter: false,
          _style: { minWidth: '150px' },
        }),
        getCSmartTableColumnData({
          key: 'type',
          label: 'Investigation Test Type',
          filter: false,
          sorter: false,
          _style: { minWidth: '150px' },
        }),
        getCSmartTableColumnData({
          key: 'source',
          label: 'Source',
          filter: false,
          sorter: false,
          _style: { minWidth: '150px' },
        }),
      ]
    case ALL_ENTITY_TYPES.patientInfo:
      return [
        getCSmartTableColumnData({
          key: 'name',
          label: 'Name',
          filter: false,
          sorter: false,
          _style: { maxWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'importData.importedFromPatientNumber',
          label: 'Import Information',
          filter: false,
          sorter: true,
          _style: { minWidth: '150px' },
        }),
        getCSmartTableColumnData({
          key: 'clinicInfo',
          label: 'Clinic Information',
          filter: false,
          sorter: false,
          _style: { minWidth: '150px' },
        }),
        // getCSmartTableColumnData({
        //   key: 'merged',
        //   // show check box or
        //   // pending or completed
        //   label: 'Merged',
        //   filter: false,
        //   sorter: false,
        //   _style: { minWidth: '150px' },
        // }),
      ]
    case ALL_ENTITY_TYPES.bookedEvent:
      return [
        getCSmartTableColumnData({
          key: 'completed',
          label: 'Completed',
          filter: false,
          sorter: false,
          _style: { maxWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'clinic',
          label: 'Clinic Info',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
      ]
    case ALL_ENTITY_TYPES.feeModel:
      return [
        getCSmartTableColumnData({
          key: 'feeRange',
          label: 'Fee Range',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'entityFeeModelsUsingThisFeeModel',
          label: 'Linked Business Models',
          filter: false,
          sorter: false,
          _style: { width: '150px' },
        }),
        getCSmartTableColumnData({
          key: 'applicableTo',
          label: 'Applicable To',
          filter: false,
          sorter: false,
          _style: { maxWidth: '100px' },
        }),
      ]
    case ALL_ENTITY_TYPES.entityFeeModel:
      return [
        getCSmartTableColumnData({
          key: 'feeRange',
          label: 'Current Model',
          filter: false,
          sorter: false,
          _style: { maxWidth: '300px' },
        }),
        ...(tempApiParam === 'business-models-with-clinics-and-others'
          ? [
              getCSmartTableColumnData({
                key: 'entityType',
                label: 'Entity Type',
                filter: false,
                sorter: false,
                _style: { maxWidth: '100px' },
              }),
            ]
          : []),
        getCSmartTableColumnData({
          key: 'entityName',
          label: 'Entity Name',
          filter: false,
          sorter: false,
          _style: { maxWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'applicableTo',
          label: 'Applicable To',
          filter: false,
          sorter: false,
          _style: { maxWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'countryCode',
          label: 'Country',
          filter: false,
          sorter: false,
          _style: { maxWidth: '100px' },
        }),
        getCSmartTableColumnData({
          key: 'applicableFrom',
          label: 'In Force Since',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'applicableTill',
          label: 'In Force Till',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        // getCSmartTableColumnData({
        //   key: 'applicableTill',
        //   label: 'Applicable Till',
        //   filter: false,
        //   sorter: false,
        //   _style: { maxWidth: '200px' },
        // }),
      ]
    case ALL_ENTITY_TYPES.payoutDistributionReports:
      return [
        getCSmartTableColumnData({
          key: 'patientName',
          label: 'Patient Name',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'eventDetails',
          label: 'Event Details',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          // key: 'sessionNumOfSameTypeWithSameMentor',
          key: 'visitDetails',
          label: 'Visit Details',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'clinicBrandDetails',
          label: 'Clinic Brand Details',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'clinicLocationName',
          label: 'Clinic Location',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          // key: 'mentorName',
          key: 'mentorDetails',
          label: 'Service Provider Details',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        // getCSmartTableColumnData({
        //   key: 'clinicLocation',
        //   label: 'Clinic Location',
        //   filter: false,
        //   sorter: false,
        //   _style: { maxWidth: '200px' },
        // }),

        getCSmartTableColumnData({
          key: 'collectedBy',
          label: 'Collected Details',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'paymentMethod',
          label: 'Payment Method',
          filter: false,
          sorter: false,
          _style: { maxWidth: '200px' },
        }),
        // getCSmartTableColumnData({
        //   key: 'sourceSharePercentage',
        //   label: 'Clinic Brand Share (%)',
        //   filter: false,
        //   sorter: false,
        //   _style: { maxWidth: '150px' },
        // }),
        // getCSmartTableColumnData({
        //   key: 'sourceShare',
        //   label: 'Clinic Brand Share Amount',
        //   filter: false,
        //   sorter: false,
        //   _style: { maxWidth: '200px' },
        // }),
        // getCSmartTableColumnData({
        //   key: 'mentorSharePercentage',
        //   label: 'Mentor Share (%)',
        //   filter: false,
        //   sorter: false,
        //   _style: { maxWidth: '150px' },
        // }),
        // getCSmartTableColumnData({
        //   key: 'mentorShare',
        //   label: 'Mentor Share Amount',
        //   filter: false,
        //   sorter: false,
        //   _style: { maxWidth: '200px' },
        // }),

        getCSmartTableColumnData({
          key: 'grossMargin',
          label: 'Margin',
          filter: false,
          sorter: false,
          _style: { maxWidth: '150px' },
        }),
        // getCSmartTableColumnData({
        //   key: 'modelIds',
        //   label: 'Model IDs',
        //   filter: false,
        //   sorter: false,
        //   _style: { maxWidth: '250px' },
        // }),
        getCSmartTableColumnData({
          key: 'startTime',
          label: 'Event Start Time',
          filter: false,
          sorter: true,
          _style: { maxWidth: '200px' },
        }),
      ]
    case ALL_ENTITY_TYPES.clinicPayoutModels:
      return [
        getCSmartTableColumnData({
          key: 'patientName',
          label: 'Patient Name',
          filter: false,
          sorter: false,
          _style: { minWidth: '250px' },
        }),
        getCSmartTableColumnData({
          key: 'eventDetails',
          label: 'Booking Details',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'feeDetails',
          label: 'Fee Details',
          filter: false,
          sorter: false,
          _style: { minWidth: '375px' },
        }),
        getCSmartTableColumnData({
          key: 'settlementDetails',
          label: 'Transaction Needed',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'view',
          label: 'View',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        }),
      ]
    case ALL_ENTITY_TYPES.therapistPayoutModels:
      return [
        getCSmartTableColumnData({
          key: 'patientName',
          label: 'Patient Name',
          filter: false,
          sorter: false,
          _style: { minWidth: '250px' },
        }),
        getCSmartTableColumnData({
          key: 'eventDetails',
          label: 'Booking Details',
          filter: false,
          sorter: false,
          _style: { minWidth: '150px' },
        }),
        getCSmartTableColumnData({
          key: 'feeDetails',
          label: 'Fee Details',
          filter: false,
          sorter: false,
          _style: { minWidth: '375px' },
        }),
        getCSmartTableColumnData({
          key: 'settlementDetails',
          label: 'Mentor Share',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        }),
        getCSmartTableColumnData({
          key: 'view',
          label: 'View',
          filter: false,
          sorter: false,
          _style: { minWidth: '200px' },
        }),
      ]

    case ALL_ENTITY_TYPES.clinicEngagementModels:
      return [
        getCSmartTableColumnData({
          key: 'title',
          label: 'Clinic Brand Name',
          filter: false,
          sorter: false,
          _style: { minWidth: '250px' },
        }),
        ...ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY?.filter((applicableType) => {
          return applicableType?.for === APPLICABLE_TO_ENTITY.clinic.id
        })?.map((applicableType) => {
          return getCSmartTableColumnData({
            key: applicableType?.id,
            label: `${applicableType?.title} Model`,
            filter: false,
            sorter: false,
            _style: { minWidth: '200px' },
          })
        }),
      ]
    case ALL_ENTITY_TYPES.therapistEngagementModels:
      return [
        getCSmartTableColumnData({
          key: 'title',
          label: 'Therapist Name',
          filter: false,
          sorter: false,
          _style: { minWidth: '250px' },
        }),
        ...ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY?.filter((applicableType) => {
          return applicableType?.for === APPLICABLE_TO_ENTITY.mentor.id
        })?.map((applicableType) => {
          return getCSmartTableColumnData({
            key: applicableType?.id,
            label: `${applicableType?.title} Model`,
            filter: false,
            sorter: false,
            _style: { minWidth: '200px' },
          })
        }),
      ]

    default:
      return []
  }
}
