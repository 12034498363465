import { CFormInput, CFormLabel } from '@coreui/react-pro'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import { MentorOption } from 'src/components/controlCenter/common/mentors/mentorOptionComponent'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import { CCFormInput } from 'src/components/controlCenter/FormComponents'
import UserInputComponent, {
  UserMultiValueLabelOption,
} from 'src/components/controlCenter/userInput/UserInputComponent'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { CLINIC_BRAND_API_SIGNATURES } from 'src/services/apiSignatureMaps/clinicProduct/clinicBrand/apis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { checkIfSlugValid, loadOptionsForAsyncPaginate } from 'src/utilities/generalUtils'
import { CustomCreatableAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'
import {
  getNewProspectObject,
  getOptionLabelForMentorInput,
  getOptionLabelForUserInput,
} from './utils'

export default function ClinicBrandForm({
  initialObject = {},
  imageObject: clinicBrand,
  setImageObject: setClinicBrand,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  formMode,
  formHandleSubmit,
  formRef,
}) {
  const [slugValidation, setSlugValidation] = useState((prev) => ({
    valid: true,
    text: '',
    loading: false,
  }))

  async function validateSlug(currentSlug) {
    const validation = await checkIfSlugValid(initialObject?.slug, currentSlug, undefined, {
      apiParam: ALL_ENTITY_TYPES.clinicBrand,
    })
    console.log({ validation })
    if (validation?.unavailable) {
      setSlugValidation((prev) => ({
        ...prev,
        text: 'This URL is already taken.',
        valid: false,
      }))
    } else if (!validation?.valid) {
      setSlugValidation((prev) => ({
        ...prev,
        text: 'This URL is in the incorrect format.',
        valid: false,
      }))
    } else {
      setSlugValidation((prev) => ({
        ...prev,
        text: '',
        valid: true,
      }))
    }
    setSlugValidation((prev) => ({ ...prev, loading: false }))
  }
  // async function fetchClinics() {
  //   const resp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
  //     entityType: ALL_ENTITY_TYPES.clinic,
  //     page: 0,
  //     size: 1000,
  //     clinicBrandId: clinicBrand?.id,
  //   })
  //   if (resp.status === 200) {
  //     const modifiedListingData = await modifyListingData(
  //       ALL_ENTITY_TYPES.clinic,
  //       resp.data.content,
  //     )
  //     // setClinics(modifiedListingData)
  //   }
  //   // setClinicsLoading(false)
  // }
  async function getDeletableStatus() {
    const resp = await GetSettingsData(CLINIC_BRAND_API_SIGNATURES.checkIfPeopleAreDeletable.path, {
      clinicBrandId: clinicBrand?.id,
    })
    if (resp.status === 200) {
      if (resp.data.code === 0 || resp.data.code === 200 || resp.data.code === 2000) {
        console.log({ resp })
      } else {
        console.log({ resp })
      }
    }
  }
  const debSlugValidation = useCallback(
    debounce(async (slug) => {
      await validateSlug(slug)
    }, 500),
    [initialObject],
  )

  function handleSlug(event) {
    handleChangeObject(event)
    setSlugValidation((prev) => {
      return {
        ...prev,
        valid: false,
        text: 'Checking availability...',
        loading: true,
      }
    })
    debSlugValidation(event.target.value)
  }

  useEffect(() => {
    if (!!clinicBrand?.id) {
      // fetchClinics()
      getDeletableStatus()
    }
  }, [clinicBrand?.id])

  return (
    <>
      <Grid container spacing={2}>
        <GridSectionWrapper heading="Basic Details" shadow={false}>
          <Grid item xs={12}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  id="enabled"
                  checked={!!clinicBrand?.enabled}
                  onChange={handleChangeObject}
                />
              }
              label="Enabled: "
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormInput
              id="brandName"
              label="Brand Name"
              value={clinicBrand?.brandName || ''}
              disabled={disabled}
              placeholder="Enter the clinic brand name"
              required
              onChange={handleChangeObject}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormInput
              id="brandShortName"
              label="Brand Short Name"
              value={clinicBrand?.brandShortName || ''}
              disabled={disabled}
              placeholder="Enter the clinic brand short name..."
              required
              onChange={handleChangeObject}
            />
          </Grid>
          <Grid item xs={12}>
            <CCFormInput
              id="slug"
              label="Slug/Unique Identifier"
              preLabel="https://aumhum.com/clinics/"
              value={clinicBrand?.slug || ''}
              disabled={disabled}
              placeholder="Enter the slug..."
              required
              onChange={handleSlug}
              invalid={!slugValidation?.valid}
              feedbackInvalid={slugValidation?.text}
            />
            {(!slugValidation?.valid || slugValidation?.loading) && (
              <input value="" style={{ display: 'none' }} required />
            )}
          </Grid>
        </GridSectionWrapper>
        <GridSectionWrapper heading="People" shadow={false}>
          <Grid item xs={12} md={6}>
            <UserInputComponent
              SelectComponent={CustomCreatableAsyncPaginate}
              disabled={disabled}
              onChange={(val) =>
                handleChangeObject({ target: { id: 'ownerUserIds', value: [val] } })
              }
              getOptionLabel={getOptionLabelForUserInput}
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                return !selectOptions.find((option) => option.email === inputValue)
              }}
              onCreateOption={(inpVal) =>
                handleChangeObject({
                  target: {
                    id: 'ownerUserIds',
                    value: [getNewProspectObject(inpVal)],
                  },
                })
              }
              label="Owner"
              value={clinicBrand?.ownerUserIds?.[0]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserInputComponent
              isMulti
              SelectComponent={CustomCreatableAsyncPaginate}
              disabled={disabled}
              roleFilter="ROLE_DOCTOR"
              onChange={(val) =>
                handleChangeObject({ target: { id: 'attachedDoctorUserIds', value: val } })
              }
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                return !selectOptions.find((option) => option.email === inputValue)
              }}
              getOptionLabel={getOptionLabelForUserInput}
              onCreateOption={(inpVal) =>
                handleChangeObject({
                  target: {
                    id: 'attachedDoctorUserIds',
                    value: [
                      ...(clinicBrand?.attachedDoctorUserIds || []),
                      getNewProspectObject(inpVal),
                    ],
                  },
                })
              }
              label="Doctors"
              value={clinicBrand?.attachedDoctorUserIds}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormLabel>Therapists</CFormLabel>
            <CustomCreatableAsyncPaginate
              isMulti
              value={clinicBrand?.attachedTherapistUserIds}
              getOptionValue={(obj) => obj?.userId}
              getOptionLabel={getOptionLabelForMentorInput}
              debounceTimeout={500}
              isDisabled={disabled}
              additional={{
                getFunction: GetSettingsData,
                apiUrl: GENERIC_APIS.entity.getPaginated,
                otherParams: {
                  entityType: ALL_ENTITY_TYPES.mentor,
                  categories: 'psychiatrist,therapist,clinical_psychologist',
                },
              }}
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)) return false

                return !selectOptions.find((option) => option.email === inputValue)
              }}
              components={{ Option: MentorOption, MultiValueLabel: UserMultiValueLabelOption }}
              noOptionMessage="Enter a valid email to create a new therapist..."
              onCreateOption={(inpVal) =>
                handleChangeObject({
                  target: {
                    id: 'attachedTherapistUserIds',
                    value: [
                      ...(clinicBrand?.attachedTherapistUserIds || []),
                      getNewProspectObject(inpVal),
                    ],
                  },
                })
              }
              loadOptions={loadOptionsForAsyncPaginate}
              onChange={(selVal) =>
                handleChangeObject({ target: { id: 'attachedTherapistUserIds', value: selVal } })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserInputComponent
              isMulti
              SelectComponent={CustomCreatableAsyncPaginate}
              disabled={disabled}
              roleFilter="ROLE_CLINIC_ASSISTANT"
              onChange={(val) =>
                handleChangeObject({
                  target: { id: 'attachedClinicAssistantUserIds', value: val },
                })
              }
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                return !selectOptions.find((option) => option.email === inputValue)
              }}
              getOptionLabel={getOptionLabelForUserInput}
              onCreateOption={(inpVal) =>
                handleChangeObject({
                  target: {
                    id: 'attachedClinicAssistantUserIds',
                    value: [
                      ...(clinicBrand?.attachedClinicAssistantUserIds || []),
                      getNewProspectObject(inpVal),
                    ],
                  },
                })
              }
              label="Clinic Assistants"
              value={clinicBrand?.attachedClinicAssistantUserIds}
            />
          </Grid>
        </GridSectionWrapper>
        <Grid item xs={12}>
          <EntityBasedImageUploadComponent
            disabled={disabled}
            entity={entity}
            entityObject={clinicBrand}
            handleChange={handleChangeObject}
            noBorder
            cuiBg
          />
        </Grid>
        {/* TODO: CHECK IF THIS IS A VALID SLUG INPUT FIELD */}
      </Grid>
    </>
  )
}

/* <Grid item xs={12}>
            <Tabs value={activeTab} onChange={(e, newVal) => setActiveTab(newVal)}>
              <Tab value={clinicBrandTabs.basicDetails} label="Basic Details" />
              {clinicBrand?.id && (
                <Tab value={clinicBrandTabs.relatedAssistants} label="Related Assistants"></Tab>
              )}
            </Tabs>
          </Grid> */

/* <TabWrapper currentValue={activeTab} tabValue={clinicBrandTabs.relatedAssistants}>
        <GenericListingComponent
          apiParam={ALL_ENTITY_TYPES.clinicAssistant}
          customListingApiParams={{ clinicBrandId: clinicBrand?.id }}
          modifyNewObjectData={(newObject) => ({ ...newObject, clinicBrandId: clinicBrand?.id })}
        />
      </TabWrapper> */

/* <TabWrapper currentValue={activeTab} tabValue={clinicBrandTabs.relatedClinics}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>Related Clinics</h4>
                  {!!clinicBrand?.id && (
                    <IconButton onClick={() => addClinicBasedOnBrandId()}>
                      <Add />
                    </IconButton>
                  )}
                </Grid>
                {!!clinicBrand?.id ? (
                  <Grid item xs={12} md={12}>
                    <ListingComponent
                      loading={clinicsLoading}
                      columns={conditionalColumns(ALL_ENTITY_TYPES.clinic)}
                      items={clinics}
                      clickableRows
                      onRowClick={(item) => addClinicBasedOnBrandId(item.id)}
                      handleFormViewClick={(item) => addClinicBasedOnBrandId(item.id)}
                      tableTagProps={{ hover: true }}
                      scopedColumns={customScopedColumns(
                        ALL_ENTITY_TYPES.clinic,
                        customOptions,
                        customOptions,
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <span>Please save this Clinic Brand to view the related clinics</span>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </TabWrapper> */

/* <CFormLabel>Doctors</CFormLabel>
            <CustomCreatableAsyncPaginate
              isMulti
              value={clinicBrand?.attachedDoctorUserIds}
              getOptionValue={(obj) => obj?.userId}
              getOptionLabel={getOptionLabelForMentorInput}
              isDisabled={disabled}
              debounceTimeout={500}
              additional={{
                getFunction: GetSettingsData,
                apiUrl: GENERIC_APIS.entity.getPaginated,
                otherParams: {
                  entityType: ALL_ENTITY_TYPES.mentor,
                  categories: 'psychiatrist,doctor',
                },
              }}
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)) return false

                return !selectOptions.find((option) => option.email === inputValue)
              }}
              components={{ Option: MentorOption, MultiValueLabel: UserMultiValueLabelOption }}
              noOptionMessage="Enter a valid email to create a new doctor..."
              onCreateOption={(inpVal) =>
                handleChangeObject({
                  target: {
                    id: 'attachedDoctorUserIds',
                    value: [
                      ...(clinicBrand?.attachedDoctorUserIds || []),
                      getNewProspectObject(inpVal),
                    ],
                  },
                })
              }
              loadOptions={loadOptionsForAsyncPaginate}
              onChange={(val) =>
                handleChangeObject({ target: { id: 'attachedDoctorUserIds', value: val } })
              }
            /> */
