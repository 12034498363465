import { transformInitialClinicFormData } from 'src/config/clinic/transformInitialData'
import { transformInitialClinicAssistantData } from 'src/config/clinicAssistant/transformInitialData'
import { transformInitialClinicBrandData } from 'src/config/clinicBrand/transformInitialData'
import { transformInitialClinicChamberData } from 'src/config/clinicChamber/transformInitialData'
import { transformInitialInAppMessageData } from 'src/config/inAppMessages/transformInitialData'
import { transformInitialInvestigationData } from 'src/config/investigation/transformInitialData'
import { transformInitialMetaTabSettingData } from 'src/config/metaTabSetting/transformInitialData'
import { transformInitialNotificationData } from 'src/config/notifications/transformInitialData'
import { transformInitialOOHMsgData } from 'src/config/oohMsgs/transformInitialData'
import { transformInitialUserData } from 'src/config/users/transfromInitialData'
import { ALL_ENTITY_TYPES } from '../../common/genericListingAndFormConfig'
import { transformInitialPlanBundlePackData } from '../bundlePacks/transformInitialData'
import { transformInitialSubscriptionPlanData } from '../subscriptionPlans/transformInitialData'

export async function transformFetchedEntity(apiParam, fetchedData, extraData = {}) {
  const { locationParams } = extraData

  switch (apiParam) {
    case ALL_ENTITY_TYPES.notifications:
      return await transformInitialNotificationData(fetchedData)
    case ALL_ENTITY_TYPES.inAppMessages:
      return await transformInitialInAppMessageData(fetchedData)
    case ALL_ENTITY_TYPES.metaTabSetting:
      return await transformInitialMetaTabSettingData(fetchedData)

    case ALL_ENTITY_TYPES.planBundlePack:
      const subscriptionData = await transformInitialPlanBundlePackData(fetchedData)
      return transformInitialSubscriptionPlanData(subscriptionData)

    case ALL_ENTITY_TYPES.subscriptionPlan:
    case ALL_ENTITY_TYPES.clinicSubscriptionPlan:
      return transformInitialSubscriptionPlanData(fetchedData)

    case ALL_ENTITY_TYPES.clinicBrand:
      return await transformInitialClinicBrandData(fetchedData)
    case ALL_ENTITY_TYPES.clinic:
      return await transformInitialClinicFormData(fetchedData, {
        fetchTeachers: true,
        fetchManagement: true,
        fetchOwner: true,
      })
    case ALL_ENTITY_TYPES.oohMsg:
      return await transformInitialOOHMsgData(fetchedData)
    case ALL_ENTITY_TYPES.clinicAssistant:
      return await transformInitialClinicAssistantData(fetchedData)
    case ALL_ENTITY_TYPES.clinicChamber:
      return await transformInitialClinicChamberData(fetchedData, locationParams)
    case ALL_ENTITY_TYPES.users:
      return await transformInitialUserData({ fetchedData, locationParams })
    case ALL_ENTITY_TYPES.investigation:
      return await transformInitialInvestigationData({ fetchedData })
    default:
      return fetchedData
  }
}
