import PropTypes from 'prop-types'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { userLogOut } from '../../../context/AuthContext'

const ProtectedRoute = ({ children }) => {
  // const { currentUser } = UserAuth()

  const token = localStorage.getItem('token')
  const role = localStorage.getItem('authorities')
  const role_array = JSON.parse(role)
  const [store, dispatch] = useAppSettingsStore()
  // dispatch({ type: 'set', roles: role_array }) //TODO THIS MIGHT BE THE PROBLEM WITH

  if (token === null) {
    console.log(token)
    return <Navigate to="/login" />
  }
  if (
    !role_array?.find(
      (element) =>
        element === 'ROLE_APP_MANAGER' ||
        element === 'ROLE_ADMIN' ||
        element === 'ROLE_SUB_ADMIN' ||
        element === 'ROLE_TRANSLATOR' ||
        element === 'ROLE_DEVELOPER' ||
        element === 'ROLE_CONTENT_WRITER' ||
        element === 'ROLE_THERAPY_SUP_L2',
    )
  ) {
    userLogOut()
    console.log('found')
    toast.error('Access denied')
    return <Navigate to="/login" />
  }
  sessionStorage.setItem('token', token)
  sessionStorage.setItem('authorities', role)
  return children
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtectedRoute
