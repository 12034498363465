import {
  CCol,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
} from '@coreui/react-pro'
import { Add, Delete } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import {
  EVENT_TYPES_ARRAY,
  PLAN_GROUP_TYPES,
  SERVICE_TYPES_ARRAY,
  SUBSCRIPTION_TYPES_LAYMAN,
  VISIT_EVENT_TYPES,
  VISIT_EVENT_TYPES_ARRAY,
} from 'src/utilities/constants'
import { genericHandleChange, giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { CustomSelect } from 'src/views/plugins/calendar/components/CustomSelect'
import { FREE_TRIAL_PRICING_DETAILS } from '../../utils'
import BundlePackComponent from '../BundlePack/BundlePackComponent'
import AndroidPricingTable from './components/AndroidPricingTable'
import PricingMatrixComponent from './components/PricingMatrixComponent'

const DETAILS_ACTIONS = {
  init: 'init',
  update: 'update',
  addBenefits: 'addBenefits',
}

export default function BaseSubscriptionPlanComponent({
  subscription,
  setSubscription,
  mode,
  allPlanLevels,
  allPlanGroups,
  allPlanTypes,
  allPaymentGateways = [],
  allPaymentGatewaySkus = [],
  hidePlanGroup,
  hideCustomerType,
  allSubscriptionPlans,
  showClinicPlanFields = false,
  clinicBrands = [],
  clinicLocations = [],
}) {
  const [store, dispatch] = useAppSettingsStore()
  const allLanguages = store.languages

  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  const [subscriptionDetails, detailsDispatcher] = useReducer(changeDetails, {})

  const relevantClinicLocations = clinicLocations.filter(
    (loc) => loc?.clinicBrandId === subscription?.clinicBrandId,
  )

  function changeDetails(state, action) {
    if (action.payload?.selectedLanguage === 'en') {
      switch (action.type) {
        case DETAILS_ACTIONS.init:
          return {
            title: action.payload.subscription?.title,
            description: action.payload.subscription?.description,
            subscriptionSuccessMsg: action?.payload?.subscription?.subscriptionSuccessMsg,
            benefits: action?.payload?.subscription?.benefits,
          }
        case DETAILS_ACTIONS.update:
          let updatedValue
          if (action?.payload?.field === 'benefits') {
            updatedValue = [...state?.benefits]
            updatedValue[action?.payload?.index] = action?.payload?.value
          } else {
            updatedValue = action?.payload?.value
          }
          setSubscription((prev) => {
            return {
              ...prev,
              [action?.payload?.field]: updatedValue,
              translations: {
                ...prev?.translations,
                [action?.payload?.selectedLanguage]: {
                  ...prev?.translations?.[action.payload?.selectedLanguage],
                  [action?.payload?.field]: updatedValue,
                },
              },
            }
          })
          return { ...state, [action?.payload?.field]: updatedValue }
        case DETAILS_ACTIONS.addBenefits:
          if (!subscription?.benefits || subscription?.benefits?.length === 0) {
            setSubscription((prev) => {
              return {
                ...prev,
                [action?.payload?.field]: [''],
                translations: allLanguages?.reduce((acc, language) => {
                  acc[language?.id] = {
                    ...prev?.translations?.[language.id],
                    benefits: [''],
                  }
                  return acc
                }, {}),
              }
            })
            return { ...state, [action?.payload?.field]: [''] }
          } else {
            setSubscription((prev) => {
              return {
                ...prev,
                benefits: [...prev?.benefits, ''],
                translations: allLanguages?.reduce((acc, language) => {
                  acc[language.id] = {
                    ...prev?.translations?.[language.id],
                    benefits: [...prev.translations?.[language.id]?.benefits, ''],
                  }
                  return acc
                }, {}),
              }
            })
            return { ...state, [action?.payload?.field]: [...state?.benefits, ''] }
          }
        default:
          console.log('details details reducer error')
      }
    } else {
      switch (action.type) {
        case DETAILS_ACTIONS.init:
          let trans =
            action?.payload?.subscription?.translations?.[action.payload?.selectedLanguage]
          return {
            title: trans?.title || '',
            description: trans?.description || '',
            subscriptionSuccessMsg: trans?.subscriptionSuccessMsg || '',
            benefits: trans?.benefits || [],
          }
        case DETAILS_ACTIONS.update:
          let updatedValue
          if (action?.payload?.field === 'benefits') {
            updatedValue = [...state?.benefits]
            updatedValue[action.payload?.index] = action?.payload?.value
          } else {
            updatedValue = action?.payload?.value
          }
          setSubscription((prev) => {
            return {
              ...prev,
              translations: {
                ...prev?.translations,
                [action?.payload?.selectedLanguage]: {
                  ...prev?.translations?.[action.payload?.selectedLanguage],
                  [action?.payload?.field]: updatedValue,
                },
              },
            }
          })
          return { ...state, [action.payload?.field]: updatedValue }
        case DETAILS_ACTIONS.addBenefits:
          if (!subscription?.benefits || subscription?.benefits?.length === 0) {
            setSubscription((prev) => {
              return {
                ...prev,
                [action.payload?.field]: [''],
                translations: allLanguages?.reduce((acc, language) => {
                  acc[language.id] = {
                    ...prev?.translations?.[language.id],
                    benefits: [''],
                  }
                  return acc
                }, {}),
              }
            })
            return { ...state, [action.payload?.field]: [''] }
          } else {
            setSubscription((prev) => {
              return {
                ...prev,
                benefits: [...prev?.benefits, ''],
                translations: allLanguages?.reduce((acc, language) => {
                  acc[language.id] = {
                    ...prev.translations?.[language.id],
                    benefits: [...prev?.translations?.[language.id]?.benefits, ''],
                  }
                  return acc
                }, {}),
              }
            })
            return { ...state, [action.payload?.field]: [...state?.benefits, ''] }
          }

        default:
          console.log('details details reducer error')
      }
    }
  }

  function changeAppLanguage(value) {
    setSelectedLanguage(value)
    detailsDispatcher({
      type: DETAILS_ACTIONS.init,
      payload: { selectedLanguage: value, subscription: subscription },
    })
  }

  const pricingMatrix = useMemo(() => {
    return subscription?.pricingMatrix
  }, [subscription?.pricingMatrix])

  function setPricingMatrix(prevEveFunction) {
    setSubscription((prev) => ({
      ...prev,
      pricingMatrix: giveGenericHandleChangedData(prevEveFunction, prev?.pricingMatrix || []),
    }))
  }

  function handleChange(event) {
    genericHandleChange(event, setSubscription)
  }

  function changeSubscriptionType(eve) {
    const val = eve.target.value

    //for free trial make recurring false and add payment gateway as free at index 0 else remove the free payment gateway and set recurring to true
    if (val === 'freeTrial') {
      setSubscription((prev) => {
        return {
          ...prev,
          subscriptionPlanType: val,
          recurring: false,
          pymntGtyInfo: [
            {
              pymntGtyId: 'free',
              pymntGtyProductId: '',
            },
            ...(prev?.pymntGtyInfo || [])?.filter((gty) => gty?.pymntGtyId !== 'free'),
          ],
        }
      })
    } else {
      setSubscription((prev) => {
        return {
          ...prev,
          subscriptionPlanType: val,
          pymntGtyInfo: prev?.pymntGtyInfo?.filter((gty) => gty?.pymntGtyId !== 'free'),
        }
      })
    }
  }

  function changePlanGroup(event) {
    if (event.target.value !== 'subscription') {
      changeSubscriptionType({ target: { value: SUBSCRIPTION_TYPES_LAYMAN?.[0]?.id } })
    }
    setSubscription((subscription) => ({ ...subscription, planGrpId: event.target.value }))
  }

  function deletePymntGtyInfo(idx) {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'delete',
      body: 'Are you sure you want to delete this payment gateway?',
      buttonColor: 'warning',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [
        () => {
          setSubscription((prev) => {
            return {
              ...prev,
              pymntGtyInfo: prev?.pymntGtyInfo?.filter((_, i) => i !== idx),
            }
          })
        },
      ],
    }))
  }

  function handleChangePaymentGatewayInfo(eve, idx) {
    const { id: pymntGtyInfoField, value } = eve.target
    setSubscription((prev) => {
      return {
        ...prev,
        pymntGtyInfo: prev?.pymntGtyInfo?.map((info, i) => {
          return i === idx ? { ...info, [pymntGtyInfoField]: value } : info
        }),
      }
    })
  }

  function addPymntGty() {
    setSubscription((prev) => {
      return {
        ...prev,
        pymntGtyInfo: [
          ...prev?.pymntGtyInfo,
          {
            pymntGtyId: '',
            pymntGtyProductId: '',
          },
        ],
      }
    })
  }

  const gtyFilter = (gty, idx) => {
    return (
      gty.id === subscription?.pymntGtyInfo?.[idx]?.pymntGtyId ||
      !subscription?.pymntGtyInfo?.some((skuGtyItm) => skuGtyItm?.pymntGtyId === gty?.id)
    )
  }

  useEffect(() => {
    detailsDispatcher({
      type: DETAILS_ACTIONS.init,
      payload: { selectedLanguage: selectedLanguage, subscription: subscription },
    })
  }, [subscription?.id])

  return (
    <>
      <CCol sm={12}>
        <div className="row g-0">
          <CCol md={6} className="px-2">
            <CFormLabel htmlFor="internalTitle">Internal Title</CFormLabel>
            <CFormInput
              value={subscription?.internalTitle}
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, internalTitle: event.target.value }
                })
              }}
              type="text"
              id="internalTitle"
              disabled={mode === 'View' || selectedLanguage !== 'en'}
              valid={subscription?.internalTitle?.length !== 0}
              invalid={subscription?.internalTitle?.length === 0}
              required
            />
          </CCol>
          <CCol md={6} className="d-flex align-items-end flex-wrap gap-2">
            <CFormSwitch
              id="active"
              checked={subscription?.active}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
              onChange={() => {
                setSubscription((prev) => {
                  return { ...prev, active: !prev.active }
                })
              }}
              label="Active"
            />
            <CFormSwitch
              id="enabled"
              checked={subscription?.enabled}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
              onChange={() => {
                setSubscription((prev) => {
                  return { ...prev, enabled: !prev.enabled }
                })
              }}
              label="Enabled"
            />
            <CFormCheck
              id="popular"
              type="checkbox"
              checked={subscription?.popular}
              label="Popular"
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, popular: event.target.checked }
                })
              }}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
            />
            {subscription?.subscriptionPlanType === SUBSCRIPTION_TYPES_LAYMAN?.[0]?.id && (
              <>
                <CFormSwitch
                  id="recurring"
                  checked={subscription?.recurring}
                  label="Recurring"
                  onChange={(event) => {
                    !event.target.checked
                      ? setSubscription((prev) => {
                          return { ...prev, recurring: false, autoRenew: false }
                        })
                      : setSubscription((prev) => {
                          return { ...prev, recurring: true }
                        })
                  }}
                  disabled={mode === 'View' || selectedLanguage !== 'en'}
                />
                {subscription?.recurring && (
                  <CFormSwitch
                    id="autoRenew"
                    checked={subscription?.autoRenew}
                    disabled={mode === 'View' || selectedLanguage !== 'en'}
                    onChange={(event) => {
                      setSubscription((prev) => {
                        return { ...prev, autoRenew: !prev.autoRenew }
                      })
                    }}
                    label="Auto Renewal"
                  />
                )}
              </>
            )}
          </CCol>
        </div>
      </CCol>
      <CCol xs={12}>
        <h3>Visible Details</h3>
        <small className="text-secondary">Details that the Users will see</small>
      </CCol>
      <CCol md={12}>
        <div className="d-flexflex-column">
          <CFormLabel htmlFor="appLang">App Languages</CFormLabel>
          <CustomSelect
            value={{
              value: selectedLanguage,
              label: store?.languages?.find((language) => {
                return language.id === selectedLanguage
              })?.languageName,
            }}
            options={store?.languages?.map((language) => {
              return { value: language.id, label: language.languageName }
            })}
            onChange={(value) => {
              changeAppLanguage(value.value)
            }}
            id="appLang"
            disabled={mode === 'View'}
          ></CustomSelect>
        </div>
      </CCol>
      <CCol md={12}>
        <CFormLabel htmlFor="title">Title</CFormLabel>
        <CFormInput
          value={subscriptionDetails?.title}
          type="text"
          onChange={(event) => {
            detailsDispatcher({
              type: DETAILS_ACTIONS.update,
              payload: {
                value: event.target.value,
                field: 'title',
                selectedLanguage: selectedLanguage,
              },
            })
          }}
          id="title"
          disabled={mode === 'View'}
          valid={subscriptionDetails?.title?.length !== 0}
          invalid={subscriptionDetails?.title?.length === 0}
          required
        />
      </CCol>
      <CCol xs={12}>
        <CFormLabel htmlFor="description">Description</CFormLabel>
        <CFormInput
          value={subscriptionDetails?.description}
          onChange={(event) => {
            detailsDispatcher({
              type: DETAILS_ACTIONS.update,
              payload: {
                value: event.target.value,
                field: 'description',
                selectedLanguage: selectedLanguage,
              },
            })
          }}
          id="description"
          placeholder="Add Description"
          disabled={mode === 'View'}
          valid={subscriptionDetails?.description?.length !== 0}
          invalid={subscriptionDetails?.description?.length === 0}
          required
        />
      </CCol>
      <CCol xs={12}>
        <CFormLabel htmlFor="subscriptionSuccessMsg">Success Text</CFormLabel>
        <CFormInput
          value={subscriptionDetails?.subscriptionSuccessMsg}
          onChange={(event) => {
            detailsDispatcher({
              type: DETAILS_ACTIONS.update,
              payload: {
                value: event.target.value,
                field: 'subscriptionSuccessMsg',
                selectedLanguage: selectedLanguage,
              },
            })
          }}
          id="subscriptionSuccessMsg"
          placeholder="Enter Success Text"
          disabled={mode === 'View'}
          valid={subscriptionDetails?.subscriptionSuccessMsg?.length !== 0}
          invalid={subscriptionDetails?.subscriptionSuccessMsg?.length === 0}
          required
        />
      </CCol>
      {subscription?.isCorporate && (
        <div className="col-12 row g-3">
          <CCol md={11}>
            <h4>Benefits</h4>
            <small className="text-secondary">Benefits for the users</small>
          </CCol>
          <CCol md={1}>
            <IconButton
              color="info"
              disabled={mode === 'View'}
              onClick={() => {
                detailsDispatcher({
                  type: DETAILS_ACTIONS.addBenefits,
                  payload: {
                    field: 'benefits',
                    selectedLanguage: selectedLanguage,
                  },
                })
              }}
            >
              <Add />
            </IconButton>
          </CCol>
          {subscriptionDetails?.benefits?.length !== 0 ? (
            subscriptionDetails?.benefits?.map((benefit, index) => {
              return (
                <CCol md={6} key={index}>
                  {selectedLanguage !== 'en' && subscription?.benefits?.[index] && (
                    <CFormLabel className="text-info" htmlFor={`benefit: ${index}`}>
                      English(en) Translation: {subscription?.benefits?.[index]}
                    </CFormLabel>
                  )}
                  <CFormInput
                    id={`benefit: ${index}`}
                    disabled={mode === 'View'}
                    value={benefit}
                    onChange={(event) => {
                      detailsDispatcher({
                        type: DETAILS_ACTIONS.update,
                        payload: {
                          value: event.target.value,
                          field: 'benefits',
                          selectedLanguage: selectedLanguage,
                          index: index,
                        },
                      })
                    }}
                    placeholder={`Enter benefit ${index + 1}`}
                  />
                </CCol>
              )
            })
          ) : (
            <CCol md={12} className="d-flexflex-row justify-content-center">
              <h5 className="text-secondary">No benefits have been added for this language</h5>
            </CCol>
          )}
        </div>
      )}
      <CCol xs={12}>
        <h3>Other Details</h3>
        <small className="text-secondary">Details that are same to all the languages</small>
      </CCol>
      {!hideCustomerType && (
        <CCol xs={3}>
          <CFormLabel htmlFor="corporateCheck">Customer Type</CFormLabel>
          <CFormSelect
            value={!!subscription?.isCorporate}
            onChange={(event) => {
              setSubscription((prev) => {
                return {
                  ...prev,
                  isCorporate: !prev?.isCorporate,
                  corporatePackage: !prev?.isCorporate,
                }
              })
            }}
            disabled={mode !== 'Add'}
          >
            <option className="dark:bg-dark" value={true}>
              Business Customer
            </option>
            <option className="dark:bg-dark" value={false}>
              End User
            </option>
          </CFormSelect>
        </CCol>
      )}
      {!hidePlanGroup && (
        <CCol md={3}>
          <CFormLabel htmlFor="group">Plan Group</CFormLabel>
          <CFormSelect
            value={subscription?.planGrpId}
            onChange={changePlanGroup}
            id="group"
            disabled={mode !== 'Add' || selectedLanguage !== 'en'}
          >
            {allPlanGroups?.map((planGroup) => {
              return (
                <option className="dark:bg-dark" value={planGroup?.id} key={planGroup?.id}>
                  {planGroup?.name}
                </option>
              )
            })}
          </CFormSelect>
        </CCol>
      )}

      <CCol md={3}>
        <CFormLabel htmlFor="group">Subscription Plan Level</CFormLabel>
        <CFormSelect
          value={subscription?.subscriptionType?.id}
          id="group"
          onChange={(event) => {
            setSubscription((prev) => {
              const ret = allPlanLevels.find((level) => level?.id === event.target.value)
              return { ...prev, subscriptionType: ret }
            })
          }}
          disabled={mode === 'View' || selectedLanguage !== 'en'}
        >
          {allPlanLevels?.map((planLevel) => {
            return (
              <option className="dark:bg-dark" value={planLevel?.id} key={planLevel?.id}>
                {planLevel?.name}
              </option>
            )
          })}
        </CFormSelect>
      </CCol>
      {showClinicPlanFields && (
        <>
          <CCol md={3}>
            <CustomReactSelect
              id="brandId"
              label="Clinic Brand"
              value={subscription?.clinicBrandId}
              options={clinicBrands}
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, clinicBrandId: event.target.value }
                })
              }}
              getOptionLabel={(optn) => optn?.brandName || optn?.title}
              getOptionValue={(optn) => optn?.id}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
            />
          </CCol>
          <CCol md={3}>
            <CustomReactSelect
              id="locationId"
              label="Clinic Location"
              value={subscription?.clinicLocationId}
              options={relevantClinicLocations}
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, clinicLocationId: event.target.value }
                })
              }}
              getOptionLabel={(optn) => optn?.brandName || optn?.title}
              getOptionValue={(optn) => optn?.id}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
            />
          </CCol>
          <CCol md={3}>
            <CustomReactSelect
              id="serviceType"
              label="Service Type"
              value={subscription?.serviceType}
              options={SERVICE_TYPES_ARRAY}
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, serviceType: event.target.value }
                })
              }}
              getOptionLabel={(optn) => optn?.brandName || optn?.title}
              getOptionValue={(optn) => optn?.id}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
            />
          </CCol>
          <CCol md={3}>
            <CustomReactSelect
              id="visitEventType"
              label="For Visit Event Type"
              value={subscription?.eventType}
              options={VISIT_EVENT_TYPES_ARRAY}
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, eventType: event.target.value }
                })
              }}
              getOptionLabel={(optn) => optn?.brandName || optn?.title}
              getOptionValue={(optn) => optn?.id}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
            />
          </CCol>
        </>
      )}
      {subscription?.planGrpId === 'subscription' && (
        <CCol md={3}>
          <CustomReactSelect
            id="subscriptionPlanType"
            label="Subscription Plan Type"
            value={subscription?.subscriptionPlanType}
            options={allPlanTypes}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            selectType={selectTypes.string}
            onChangeEvent={changeSubscriptionType}
            required
            disabled={mode === 'View' || selectedLanguage !== 'en'}
          />
        </CCol>
      )}
      {subscription?.planGrpId === 'subscription' && (
        <>
          <CCol md={6}>
            <CFormLabel htmlFor="inputCity">Validity(Days)</CFormLabel>
            <CFormInput
              value={subscription?.validity}
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, validity: event.target.value }
                })
              }}
              id="inputCity"
              type="number"
              placeholder="Please enter the vaildity of the plan..."
              invalid={subscription?.validity?.length === 0}
              valid={subscription?.validity?.length !== 0}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
              required
            />
          </CCol>
          <CCol md={6}>
            <CFormLabel htmlFor="inputCity">Trial Days</CFormLabel>
            <CFormInput
              value={subscription?.trialDays}
              onChange={(event) => {
                setSubscription((prev) => {
                  return { ...prev, trialDays: event.target.value }
                })
              }}
              id="inputCity"
              placeholder="Enter trial days..."
              type="number"
              valid={subscription?.trialDays?.length !== 0}
              invalid={subscription?.trialDays?.length === 0}
              disabled={mode === 'View' || selectedLanguage !== 'en'}
              required
            />
          </CCol>
        </>
      )}
      <CCol xs={12}>
        {subscription?.planGrpId === PLAN_GROUP_TYPES.bundle && (
          <BundlePackComponent
            subscription={subscription}
            setSubscription={setSubscription}
            disabled={mode === 'View'}
            allSubscriptionPlans={allSubscriptionPlans}
          />
        )}
      </CCol>
      {/* <SessionsPricingSection
            data={subscription}
            handleChange={handleChange}
            disabled={mode === 'View'}
            setData={setSubscription}
          /> */}
      {subscription?.subscriptionPlanType !== SUBSCRIPTION_TYPES_LAYMAN?.[1]?.id ? (
        <PricingMatrixComponent
          disabled={mode === 'View'}
          pricingMatrix={pricingMatrix}
          setPricingMatrix={setPricingMatrix}
        />
      ) : (
        <PricingMatrixComponent
          disabled={true}
          pricingMatrix={FREE_TRIAL_PRICING_DETAILS}
          subheading="Free Trial Plan will have this pricing"
          setPricingMatrix={() => {}}
        />
      )}
      <CCol xs={12}>
        <Grid container spacing={2}>
          <GridSectionWrapper
            shadow={false}
            headerComponents={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>Payment Gateways</h3>
                {subscription?.pymntGtyInfo?.length < allPaymentGateways?.length &&
                  mode !== 'View' && (
                    <IconButton onClick={addPymntGty}>
                      <Add />
                    </IconButton>
                  )}
              </div>
            }
          >
            {subscription?.pymntGtyInfo?.map((payment, pymntGtyIdx) => {
              return (
                <GridSectionWrapper key={payment?.pymntGtyId} shadow={false}>
                  <Grid item xs={12} md={6}>
                    <CustomReactSelect
                      id="pymntGtyId"
                      label="Payment Gateway"
                      value={payment?.pymntGtyId}
                      options={allPaymentGateways?.filter((gty) => gtyFilter(gty, pymntGtyIdx))}
                      getOptionValue={(optn) => optn?.id}
                      getOptionLabel={(optn) => optn?.title}
                      onChangeEvent={(eve) => handleChangePaymentGatewayInfo(eve, pymntGtyIdx)}
                      disabled={mode === 'View' || payment?.pymntGtyId === 'free'}
                      selectType={selectTypes.string}
                      invalid={!payment?.pymntGtyId}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6} display="flex" alignItems="flex-end" gap="5px">
                    <CustomReactSelect
                      label="Payment Gateway Sku"
                      value={payment?.pymntGtyProductId}
                      id="pymntGtyProductId"
                      options={allPaymentGatewaySkus?.filter(
                        (sku) =>
                          sku?.grpId === subscription?.planGrpId &&
                          sku?.paymentGatewayList?.includes(payment?.pymntGtyId),
                      )}
                      getOptionValue={(optn) => optn?.id}
                      getOptionLabel={(optn) => optn?.title}
                      onChangeEvent={(eve) => handleChangePaymentGatewayInfo(eve, pymntGtyIdx)}
                      disabled={mode === 'View' ? true : !payment?.pymntGtyId}
                      selectType={selectTypes.string}
                    />
                    {(payment?.pymntGtyId === 'free' ? false : mode !== 'View') && (
                      <IconButton onClick={() => deletePymntGtyInfo(pymntGtyIdx)}>
                        <Delete />
                      </IconButton>
                    )}
                  </Grid>
                </GridSectionWrapper>
              )
            })}
          </GridSectionWrapper>
        </Grid>
        <ConfirmationModal newImplementation {...confirmationModalProps} />
      </CCol>
      {subscription?.androidIAPPrices && (
        <CCol xs={12}>
          <AndroidPricingTable
            heading="Android Pricing"
            androidPricingData={Object.entries(subscription?.androidIAPPrices || {})?.map(
              ([countryCode, value]) => ({ ...value, countryCode }),
            )}
            refreshIAPPricingData={() => {
              console.log('refreshIAPPricingData')
            }}
          />
        </CCol>
      )}
    </>
  )
}
