import axios from 'axios'
import { toast } from 'react-toastify'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { apiURL } from 'src/services'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'

const API_URL = [apiURL, '/api/secure/cc/'].join('')
const NON_CC_API_URL = [apiURL, '/api/secure/'].join('')

const token = localStorage.getItem('token')
const Headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
}

export async function PostSettingsData(
  url,
  body,
  Params = {},
  tst,
  returnAxiosOnly = false,
  customErrorMessageFunction = (msg) => msg || 'Something went wrong',
  showToast = true,
  isCommonResponseApi = false,
  isNonCCApi = false,
) {
  if (returnAxiosOnly) {
    return axios.post(`${isNonCCApi ? NON_CC_API_URL : API_URL}${url}`, body, {
      headers: Headers,
      params: Params,
    })
  }

  try {
    const response = await axios.post(`${isNonCCApi ? NON_CC_API_URL : API_URL}${url}`, body, {
      headers: Headers,
      params: Params,
    })
    if (isCommonResponseApi && !isCommonResponseSuccessful(response.data.code)) {
      if (tst) {
        toast.update(tst, {
          render: customErrorMessageFunction(response.data.message),
          type: toast.TYPE.ERROR,
          isLoading: false,
          ...TOAST_UTILS,
        })
        return response
      }
    }
    if (tst) {
      toast.update(tst, {
        render: 'Saved Successfully',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
    }
    return response
  } catch (error) {
    if (tst) {
      toast.update(tst, {
        render: customErrorMessageFunction(error),
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
    } else {
      if (showToast) {
        toast.error(customErrorMessageFunction(error))
      }
    }
    console.log(error)
    return error
  }
}
