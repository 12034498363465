import {
    getSavableSubscriptionPlan,
    getUploadablePricingMatrix,
} from 'src/views/subscriptions/plans/utils'

export async function transformClinicSubscriptionPlan(formedData, customStoreData) {
  let dataToWorkWith = getSavableSubscriptionPlan({
    ...formedData,
    pricingMatrix: getUploadablePricingMatrix(formedData?.pricingMatrix || []),
  })

  return dataToWorkWith
}
