import { CFormInput } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import React, { useState } from 'react'
import { GENERIC_APIS } from 'src/services/genericApis'
import { isCommonResponseSuccessfulV2 } from 'src/utilities/generalUtils'
import DefualtCsvUploadForm from 'src/views/setup/userStats/components/comps/DefualtCsvUploadForm'
import { uploadCSVDataToDB } from './util'

export default function DefaultCsvUploadComponent({
  closeDialog,
  apiParam,
  handleRefresh,
  setConfirmationModalProps,
  toggleSwitchComponent = <></>,
}) {
  const [uploadedCsvFile, setUploadedCsvFile] = useState()
  const [csvDelimiter, setCsvDelimiter] = useState()
  const [listDelimiter, setListDelimiter] = useState()
  const [communicationTypes, setCommunicationTypes] = useState()

  async function saveDataToDb() {
    const resp = await uploadCSVDataToDB({
      apiParam,
      fileKey: 'file',
      csvFile: uploadedCsvFile,
      params: {
        entityType: apiParam,
        'csv-delimiter': csvDelimiter,
        'list-delimiter': listDelimiter,
        'communication-type': communicationTypes?.join(','),
      },
      apiSignature: GENERIC_APIS.entity.saveCsv,
    })
    if (isCommonResponseSuccessfulV2(resp)) {
      handleRefresh()
      closeDialog()
    }
  }

  function setShowConfirmation() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'save',
      body: 'Do you want to save the data in the uploaded CSV?',
      buttonColor: 'success',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      submitButtonText: 'Save Data',
      closeButtonText: 'Cancel',
      onSubmitFunctions: [
        () => {
          saveDataToDb()
        },
      ],
    }))
  }

  function handleCsvUpload(event) {
    event.preventDefault()
    if (!!event.target?.files && !!event.target.files[0]) {
      setUploadedCsvFile(event.target.files[0])
    }
  }

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {toggleSwitchComponent}
          <>
            <DefualtCsvUploadForm
              communicationTypes={communicationTypes}
              csvDelimiter={csvDelimiter}
              listDelimiter={listDelimiter}
              setCommunicationTypes={setCommunicationTypes}
              setCsvDelimiter={setCsvDelimiter}
              setListDelimiter={setListDelimiter}
            />
          </>
          <Grid item xs={12}>
            <CFormInput type="file" accept=".csv" onChange={handleCsvUpload} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!uploadedCsvFile}
          color="success"
          onClick={() => setShowConfirmation()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}
