export const MUI_THEME_CONFIG = (themeMode) => ({
  palette: {
    mode: themeMode,
    primary: {
      main: themeMode === 'dark' ? 'rgb(133, 125, 204)' : '#4335c2',
      mainRGB: themeMode === 'dark' ? '133, 125, 204' : '67, 53, 194',
      light: 'rgb(50, 31, 219)',
      dark: '#1f1498',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(157, 165, 177)',
      light: 'rgb(157, 165, 177)',
      dark: 'rgb(167, 167, 167)',
      contrastText: '#fff',
    },
    tertiary: {
      main: themeMode === 'dark' ? '#dda6ff' : '#846597',
      light: themeMode === 'dark' ? '#846597' : '#846597', // implemented in the future
      dark: themeMode === 'dark' ? '#846597' : '#846597', // implemented in the future
      contrastText: '#fff',
    },
    error: {
      main: 'rgb(229, 83, 83)',
      dark: 'rgb(199, 113, 113)',
      // contrastText: theme === 'dark' ? 'rgb(98,38,38)' : 'rgb(255,106,106)',
    },
    warning: {
      main: 'rgb(249, 177, 21)',
      dark: 'rgb(213, 164, 57)',
      // contrastText: '#c2b2da',
      contrastText: '#fff',
    },
    info: {
      main: 'rgb(51, 153, 255)',
      dark: 'rgb(82, 153, 224)',
      contrastText: '#fff',
    },
    success: {
      main: 'rgb(46, 184, 92)',
      dark: 'rgb(81, 150, 104)',
    },
    whiteBlack: {
      main: themeMode === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',
      light: themeMode === 'dark' ? 'rgb(255, 255, 255, 0.9)' : '#383838',
    },
    background: {
      dividingContainer: themeMode !== 'dark' ? 'var(--cui-border-color)' : 'var(--cui-border-color)',
      dialogBackground: themeMode !== 'dark' ? '#fff' : '#383838',
    },
    emrPrimary: {
      default: '#7450aa',
      main: '#7450aa',
      light: '#8b6eb8',
      dark: '#47306a',
      // contrastText: '#c2b2da',
      contrastText: '#fff',
    },
    emrPrimaryLightBackground: {
      default: '#c2b2da',
      main: '#c2b2da',
      light: '#8492e8',
      dark: '#404b8e',
      // contrastText: '#dee2f9',
      contrastText: '#000',
    },
    emrSecondary: {
      default: '#6779e3',
      main: '#6779e3',
      light: '#8492e8',
      dark: '#404b8e',
      // contrastText: '#dee2f9',
      contrastText: 'white',
    },
    emrSecondaryLightBackground: {
      default: '#dee2f9',
      main: '#dee2f9',
      light: '#8b6eb8',
      dark: '#47306a',
      // contrastText: '#c2b2da',
      contrastText: '#000',
    },
    emrSidebar: {
      default: '#585369',
    },
    emrPrescriptionPrimary: {
      default: '#c9eee8',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  typography: {
    fontFamily: 'inherit',
    h1: {
      fontSize: '40px',
      fontWeight: 600,
    },
    h2: {
      fontSize: 'calc(1.325rem + 0.9vw)',
      fontWeight: 600,
    },
    h6: {
      fontSize: 'calc(1.325rem + 0.9vw)',
      fontWeight: 600,
      '@media (min-width:768px)': {
        fontSize: '30px',
      },
    },
  },
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '20px 20px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '30px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderColor: themeMode === 'dark' ? 'rgb(255, 255, 255, 0.9)' : 'rgb(0 ,0, 0 , 0.7)',
        },
      },
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
})
