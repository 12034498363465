import { Paper, Popover, Tabs, styled as muiStyled } from '@mui/material'

export const CoolerTabs = muiStyled(Tabs)(({ theme }) => ({
  selected: {
    backgroundColor: 'green',
  },
}))

export const GenericListingPageDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
max-height: 90vh;
height: 100%;
width: 100%;
margin: 0 !important;
@media(min-width: 576px){
  margin: 32px;
  max-width: 90vw;
}
`,
)

export const GenericListingPageSmallDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
max-height: 40vh;
height: 100%;
width: 100%;
max-width: 1200px !important;
margin: 0 !important;
@media(min-width: 576px){
  margin: 32px;
  max-width: 60vw;
}
`,
)

export const FilterPopover = muiStyled(Popover)(
  ({ theme }) => `
  .MuiPaper-root {
    max-width: 700px;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 14px;
    padding: 25px;
    background: ${theme.palette.mode === 'dark' ? '#050505' : '#f7f7f7'};
    overflow: visible!important; 
`,
)
