import { CBadge, CSmartPagination, CTableDataCell } from '@coreui/react-pro'
import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  IconButton,
  InputBase,
  Paper,
  alpha,
} from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import styled from 'styled-components'
import { CustomSelect } from '../../views/plugins/calendar/components/CustomSelect'

export function getListingComponentStyles(enabled) {
  return { verticalAlign: 'middle' }
}

const IMAGE_BOX = {
  overflow: 'hidden',
  borderRadius: '6px',
  height: '200px',
  width: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
const LIST_BOX = {
  overflow: 'hidden',
  borderRadius: '6px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}

export const muiIconButtonPrimaryStyles = {
  bgcolor: 'primary.main',
  color: 'white',
  '&:hover': { backgroundColor: 'primary.main' },
}

export function getGradientColors(type, colorIds) {
  switch (type) {
    case 'linear-gradient':
      return colorIds
        .map((colorId) => {
          return `${hexToRGBA(colorId.hexCode, colorId.opacity)} ${colorId.position}`
        })
        ?.join(',')
    default:
      return ''
  }
}

export function handleValidateColor(color) {
  if (!color) {
    return false
  }
  if (color?.type === 'solid') {
    if (color?.colorIds?.length < 1) {
      return false
    }
    if (color?.colorIds?.some((colorId) => colorId.hexCode === '')) {
      return false
    }
  }
  if (color?.type !== 'solid') {
    if (color?.colorIds?.length < 2) {
      return false
    }
    if (color?.colorIds?.some((colorId) => colorId.hexCode === '' || colorId.position === '')) {
      return false
    }
  }
  return true
}

function getTemplateCardColor(color) {
  switch (color.type) {
    case 'solid':
      return `background-color: ${hexToRGBA(
        color?.colorIds?.lastItem?.hexCode,
        color?.colorIds?.lastItem?.opacity,
      )}`
    default:
      return `background-image: linear-gradient(
      ${color?.degrees || 0}deg,
      ${getGradientColors(color.type, color?.colorIds)})`
  }
}

// export function hexToRGBA(hex, opacity) {
//   var validHex = !!hex ? hex : '#FFFFFF'
//   var validOpacity = !!opacity ? opacity : 0
//   var c
//   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(validHex)) {
//     c = validHex?.substring(1)?.split('')
//     if (c.length == 3) {
//       c = [c[0], c[0], c[1], c[1], c[2], c[2]]
//     }
//     c = '0x' + c.join('')
//     return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${validOpacity})`
//   }
//   throw new Error('Bad Hex')
// }

export function hexToRGBA(hex, opacity) {
  var validHex = !!hex ? hex : '#FFFFFF'
  var validOpacity = !!opacity ? opacity : 0
  var c

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(validHex)) {
    c = validHex?.substring(1)?.split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')

    // Calculate the alpha value in hex format
    var alphaHex = Math.round(validOpacity * 255)
      .toString(16)
      .padStart(2, '0')

    return (
      '#' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255]
        .map((component) => component.toString(16).padStart(2, '0'))
        .join('') +
      alphaHex
    )
  }

  throw new Error('Bad Hex')
}

///UTILS

export const TableCheckBox = styled(CTableDataCell)``
export const RedirectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`
export const ImageTypeContainer = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
`

export const ImageContainer = styled.div(({ containerType = 'unset' }) => ({
  ...IMAGE_BOX,
  cursor: containerType === 'button' ? 'pointer' : 'default',
}))
export const ListContainer = styled.div(() => ({ ...LIST_BOX }))
export const GalleryImageContainer = muiStyled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'selectedType',
})(({ theme, selectedType }) => ({
  ...IMAGE_BOX,
  textAlign: 'center',
  backgroundImage: selectedType
    ? 'linear-gradient(154.7deg, #93AEFF 4.49%, #CBD8FF 179.88%);'
    : null,
  backgroundColor: selectedType ? null : theme.palette.secondary.main,
  transition: 'padding 0.1s ease-out, border 0.5s',
  '&:hover': {
    padding: 2,
    border: `1px solid ${theme.palette.primary.light}`,
  },
}))

export const ImageSizeContainer = styled.div`
  padding: 1.5rem;
`

export const NoImage = styled.div`
  background-color: #5a6aa3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`
export const ImageDimentionsContainer = styled.span`
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
`

export const ChooseUploadButtons = muiStyled(ButtonBase, {
  shouldForwardProp: (prop) =>
    prop !== 'customBackgroundColor' && prop !== 'height' && prop !== 'width',
})(({ theme, customBackgroundColor, width, height }) => ({
  width: !!width ? width : '334.27px',
  height: !!height ? height : '480.05px',
  borderRadius: '23px',
  background: !!customBackgroundColor ? customBackgroundColor : theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))
export const ButtonImage = styled.img`
  width: 154.23px;
  height: 154.23px;
`
export const GalleryImages = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 200px);
  justify-content: space-evenly;
  padding: 1rem 0;
  gap: 1.25rem;
  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 200px);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 200px);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 200px);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 200px);
  }
`
export const GalleryFiltersContainer = muiStyled(Paper)(({ theme }) => ({
  position: 'sticky',
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  top: '0rem',
  zIndex: 100,
  padding: '0.5rem',
}))

export const Search = muiStyled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

export const SearchIconWrapper = muiStyled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledInputBase = muiStyled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))

export const DialogBar = styled.div`
  position: sticky;
  padding: 0.5rem;
  top: 0;
  z-index: 101;
`

export const MainHeaderUpload = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  @media (min-width: 768px) {
    font-size: 51px;
    line-height: 64px;
  }
`
export const SmallHeaderUpload = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  @media (min-width: 768px) {
    line-height: 25px;
    font-size: 20px;
  }
`
export const ListingComponentUpperBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`
export const AspectRatioContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: ${(props) => (!props.uploaded ? '' : '3px dashed #5a6aa3')};
  height: ${(props) => (props.aspectRatio < 1 ? '100%' : `${(1 / props.aspectRatio) * 100}%`)};
  width: ${(props) => (props.aspectRatio >= 1 ? '100%' : `${props.aspectRatio * 100}%`)};
  border-radius: 6px;
  color: #5a6aa2;
  font-weight: 400;
  font-size: 30px;
`
export const SelectImageTypeImageHeading = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  white-space: nowrap;
`
export const ImageSizeSelectContent = styled.div`
  display: grid;
  grid-template-columns: 20%;
  justify-content: center;
  algin-items: stretch;
  padding: 95px 120px;
  gap: 65px;
  @media (min-width: 576px) {
    grid-template-columns: 20% 20%;
    gap: 150px;
  }
  @media (min-width: 768px) {
    grid-template-columns: 20% 20%;
    gap: 150px;
  }
  @media (min-width: 992px) {
    grid-template-columns: 20% 20% 20% 20%;
    gap: 65px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 20% 20% 20% 20% 20%;
    gap: 65px;
  }
`
export const ImageSizeSelectFooter = muiStyled(DialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  algin-items: center;
  padding-bottom: 30px;
`

// export const PreviewBox = muiStyled(Box)`
//   height: 200px;
//   width: 200px;
//   backgroundimage: linear-gradient(154.7deg, #93aeff 4.49%, #cbd8ff 179.88%);
// `

export const PreviewBox = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'degree' && prop !== 'colorIds',
})(({ theme, type, degree, colorIds }) => ({
  ...IMAGE_BOX,
  padding: 10,
  position: 'relative',
  backgroundColor:
    type === 'solid'
      ? `${hexToRGBA(colorIds?.lastItem?.hexCode, colorIds?.lastItem?.opacity)}`
      : null,
  backgroundImage:
    type !== 'solid'
      ? `linear-gradient(
    ${degree}deg,
    ${getGradientColors(type, colorIds)})`
      : null,
}))
export const ColorBox = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'degree' && prop !== 'colorIds',
})(({ theme, type, degree, colorIds }) => ({
  padding: 10,
  backgroundColor:
    type === 'solid'
      ? `${hexToRGBA(colorIds?.lastItem?.hexCode, colorIds?.lastItem?.opacity)}`
      : null,
  backgroundImage:
    type !== 'solid'
      ? `linear-gradient(
    ${degree}deg,
    ${getGradientColors(type, colorIds)})`
      : null,
}))

export const ImageUploadDialogComponent = muiStyled(Dialog)(({ theme }) => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
}))
export const PreviewImage = styled.img`
  height: ${(props) => (props.aspectRatio < 1 ? '100%' : `${(1 / props.aspectRatio) * 100}%`)};
  width: ${(props) => (props.aspectRatio >= 1 ? '100%' : `${props.aspectRatio * 100}%`)};
  object-fit: cover;
`

export const GridListingContainer = muiStyled('div')(
  ({ theme, itemsPerBreakPoint }) => `
      display: grid;
      background-color: var(${(props) =>
        props.theme === 'dark' ? '--tableColDark' : '--sideBar'});
        grid-template-columns: repeat(${itemsPerBreakPoint?.xs}, ${100 / itemsPerBreakPoint?.xs}%);
      justify-content: space-between;
      padding: 5px 30px;
      gap: 5px;
      @media (min-width: 576px) {
        grid-template-columns: repeat(${itemsPerBreakPoint?.sm}, ${100 / itemsPerBreakPoint?.sm}%);
      }
      @media (min-width: 768px) {
        grid-template-columns: repeat(${itemsPerBreakPoint?.md}, ${100 / itemsPerBreakPoint?.md}%);
      }
      @media (min-width: 1024px) {
        grid-template-columns: repeat(${itemsPerBreakPoint?.lg}, ${100 / itemsPerBreakPoint?.lg}%);
      }
      @media (min-width: 1200px) {
        grid-template-columns: repeat(${itemsPerBreakPoint?.xl}, ${100 / itemsPerBreakPoint?.xl}%);
      }
`,
)

export const GridListingItem = muiStyled(ButtonBase)(({ theme }) => ({
  width: '100%',
  height: '200px',
  borderRadius: '6px',
  background: 'rgb(157, 165, 177)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
}))

export const CCPaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  justify-content: flex-start;
  @media (min-width: 576px) {
    justify-content: space-around;
    flex-direction: row;
  }
`

export const ImageViewImage = styled.img`
  display: ${(props) => (props.loading ? 'none' : 'block')};
  object-fit: contain;
  max-height: 80vh;
  max-width: 80vw;
`

export const ImageViewAllImagesBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
`

export const ImageViewDetails = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
`
export const ImageGalleryViewContainer = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  height: calc(100% - 154.25px);
  align-items: center;
`
export const GalleryViewImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`
export const ImageGalleryViewBackground = styled.div`
  width: 100%;
  height: 100%;
  ${(props) => getTemplateCardColor(props.imageColor)}
`
export const ImageGalleryViewPreviewImgs = styled.img`
  height: 40px;
  width: 40px;
  filter: brightness(${(props) => (props.selected ? 1 : 0.3)});
`

export const DispMsgViewContainer = styled.div`
  display: flex;
  color: #fff;
  ${(props) =>
    handleValidateColor(props.msgColor)
      ? getTemplateCardColor(props.msgColor)
      : 'background: #aa82e0'};
  justify-content: space-between;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  border: 1px solid;
  gap: 10px;
  ${(props) => (props.clickFunction ? 'pointer-events:fill' : 'pointer-events: none')}
`
export const DisplayMsgViewDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  overflow: hidden;
`
export const DisplayMsgViewImg = styled.img`
  width: 86.55px;
  height: 86.55px;
`
export const DisplayMsgViewSubText = styled.h6`
  font-weight: 400;
  font-size: 14px;
  color: #e0c9ff;
  margin: 0;
`
export const DisplayMsgViewTitleText = styled.h5`
  color: rgb(255, 255, 255);
  margin: 6px 0px 10px 0px;
`
export const DisplayMsgViewButtonText = styled.span`
  color: #444444;
  font-size: 14px;
`
export const DisplayMsgViewButtonLink = styled.a`
  color: #e0c9ff;

  &:hover {
    color: #fff;
  }
`

const sectionBorder = ' 0.15rem solid rgba(0, 0, 0, 0.5)'

export const ColorGradientBorder = styled.div`
  border: ${sectionBorder};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1.25rem;
  border-radius: 1.25rem;
`
export const ImageUploadSectionContainer = styled.div`
  border: ${sectionBorder};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1.25rem;
  border-radius: 1.25rem;
`
export const DispLayMsgDetailsSectionContainer = styled.div`
  border: ${sectionBorder};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 1.25rem;
`
export const DisplayTempBadge = styled(CBadge)`
  white-space: inherit;
`
export const EditIconButton = muiStyled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  backgroundColor: theme.palette.warning.main,
}))

export const GenericViewComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 37px;
  background: ${(props) => (!!props.bgColor ? '#ffb9ae' : '')};
`
export const GenericSwitchesContainer = styled.div`
  padding-bottom: 1rem;
`
export const UploadOrGalleryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-direction: column;
  flex-grow: 1;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const DarkOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`
export const BGSButtonOverLay = styled.div`
  position: absolute;
  background: rgba(235, 240, 255, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`

export const ColorGradInputWidth = styled.div`
  width: 100%;
`
export const ListingComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ListingFilterContainer = styled.div`
  flex-shrink: 0;
`
export const ColorStripContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: '10px';
  flex-direction: row;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const DividingContainer = muiStyled('div')(
  ({ theme, lightBg = 'var(--cui-border-color)', darkBg = 'var(--cui-border-color)' }) => `
  background: ${theme.palette.mode === 'dark' ? darkBg : lightBg};
  box-shadow: 0px 0px 5px var(--cui-bg-primary) inset;
  border-radius: 10px;
  width: 100%;
  transition: all 150ms ease-in-out;
`,
)
export const ServerPointContainer = styled.div`
  display: none;
  @media (min-width: 576px) {
    display: flex;
    align-items: center;
  }
`

export const CustomReactSelectComponent = muiStyled(CustomSelect)(
  ({ theme, noOptionTextEllipisis }) => `
  width: 100%;
  ${
    noOptionTextEllipisis
      ? `
    // > .react-select_control > .react-select_value-container > .react-select__single-value {
  .react-select__single-value {
  text-overflow: unset;
  white-space: unset;
}
    `
      : ''
  }
`,
)
export const GenericFormHeadingStyles = muiStyled('div')(
  ({ theme }) => `
position: sticky;
top: var(--header-height);
z-index: 10;
background: ${
    theme?.palette?.mode === 'dark'
      ? 'var(--cui-body-bg)'
      : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
  };
`,
)

function getTabButtonColor(theme, selected, invalid) {
  if (invalid) {
    return theme.palette.error.main
  }
  if (selected) {
    return '#A864FF'
  } else {
    return 'transparent'
  }
}

export const StyledTabContainer = muiStyled(Box)`
  display: flex;
  position: relative;
  overflow-x: auto;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  ::-webkit-scrollbar {
    display: none;
  }
`

function getSelectedTabTextColor(theme, selected, invalid) {
  if (theme?.palette?.mode === 'dark') {
    return '#fff'
  }
  if (selected) {
    return '#fff'
  }
  return '#000'
}

function getTabHoverBackgroundFunction(theme, selected, invalid) {
  if (theme.palette.mode === 'dark') {
    if (invalid) {
      return theme.palette.error.light
    }
    if (selected) {
      return '#b880ff'
    }
    return '#242424'
  } else {
    if (invalid) {
      return theme.palette.error.light
    }
    if (selected) {
      return '#b880ff'
    } else {
      return '#d6d6d6'
    }
  }
}

export const StyledTabButton = muiStyled(ButtonBase)(
  ({ theme, selected, showBorder, invalid = false }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border:${showBorder ? ' 1px solid #0000003b' : 'unset'};
  border-radius: 38px;
  flex-shrink: 0;
  padding: 8px 23px;
  background: ${getTabButtonColor(theme, selected, invalid)};
  color : ${getSelectedTabTextColor(theme, selected, invalid)} !important;
  transition: background 150ms ease-in-out;
  :hover {
    background: ${getTabHoverBackgroundFunction(theme, selected, invalid)};
  }
`,
)

export const EditableTableContainer = muiStyled('div')(`
`)

export const EditableTableRowContainer = muiStyled('div')(
  ({ theme, header = false }) => `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-weight: ${header ? 'bold' : 'normal'};
  color: ${theme.palette.mode === 'dark' ? '#ebebeb' : 'black'};
`,
)

export const EditableTableCellContainer = muiStyled('div')(
  ({ theme }) => `
`,
)

export const SwitchApiUrlSelect = muiStyled('select')(
  ({ theme }) => `
  width: 180px;
  background: #00000069;
  color: white;
  border-radius: 7px;
  padding: 7px;
`,
)

export const TrackUploadDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
margin: 0!important;
`,
)

export const QuillWrapper = muiStyled('div')(
  ({ theme }) => `
  background: #fff;
height: calc(100% - 48.1px);
font-family: 'Outfit';
.quill > .ql-container {
    height: calc(100% - 43px);
  }
.quill {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #242424;
  font-size: 1rem !important; /* Equivalent to 16px */
  p {
    font-size: 1rem !important;
    strong {
      font-weight: 500;
    }
  }
  h1 {
    font-size: 1.375rem !important;
    font-weight: 500;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
  h2 {
    font-size: 1.325rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.3rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.275rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h5 {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h6 {
    font-size: 1rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  img {
    margin: auto;
    border: 1px solid #f4f4f4;
  }
  a {
    font-size: 1rem !important;
  }
  img {
    width: 100%;
  }
  blockquote {
    font-size: 1rem !important;
    margin-bottom: 1rem;
    padding-left: 5px;
  }
  ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin: 16px 0;
  }
  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 16px 0;
  }
  li {
    font-size: 1rem !important;
  }

  @media (min-width: 1200px) {
    font-size: 1rem !important;
    p {
      strong {
        font-weight: 500;
      }
    }
    h1 {
      font-size: 2.5rem !important;
      font-weight: 500;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    h2 {
      font-size: 2rem !important;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-size: 1.75rem !important;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    h4 {
      font-size: 1.5rem !important;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
  }
}
`,
)

export const RichTextContentWrapper = muiStyled('div')(
  ({ theme }) => `
position: relative;
display: flex;
flex-direction: column;
width: 100%;
color: #242424;
font-size: 1rem !important; /* Equivalent to 16px */
p {
  font-size: 1rem !important;
  strong {
    font-weight: 500;
  }
}
h1 {
  font-size: 1.375rem !important;
  font-weight: 500;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
h2 {
  font-size: 1.325rem !important;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
h3 {
  font-size: 1.3rem !important;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
h4 {
  font-size: 1.275rem !important;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
h5 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
h6 {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
img {
  margin: auto;
  border: 1px solid #f4f4f4;
}
a {
  font-size: 1rem !important;
}
img {
  width: 100%;
}
blockquote {
  font-size: 1rem !important;
  margin-bottom: 1rem;
  padding-left: 5px;
}
ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin: 16px 0;
}
ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 16px 0;
}
li {
  font-size: 1rem !important;
}

@media (min-width: 1200px) {
  font-size: 1rem !important;
  p {
    strong {
      font-weight: 500;
    }
  }
  h1 {
    font-size: 2.5rem !important;
    font-weight: 500;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
  h2 {
    font-size: 2rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.75rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.5rem !important;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
}
`,
)

export const GeneralDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  max-width: 1200px;
`,
)

export const CCSmartPaginationStyledComponent = muiStyled(CSmartPagination)(
  ({ theme }) => `
  
  margin-bottom:0;
`,
)

export const OverlayLoaderContainer = muiStyled('div')(
  ({ theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff24;
  backdrop-filter: blur(5px);
`,
)

export const OverlayDiv = muiStyled('div')(
  ({ theme }) => `
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #523c7d;
  transition: all 100ms ease-in-out;
`,
)
