import CourseActionButtonWrapper from 'src/components/controlCenter/course/listingPage/CourseActionButtonWrapper'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS, PLAN_GROUP_TYPES } from 'src/utilities/constants'
import { SETUP_APIS } from 'src/views/setup/utils/api-utils'
import { cacheEvictRowClick } from '../cacheEvict/genericListing/rowClick'

export function entityListingSettings(apiParam, urlApiParam, contextData) {
  const { store, getUrls, ...restContextData } = contextData
  const defaultPaginatedURL = getUrls.paginated
  const defaultNormalURl = getUrls.normal

  let listingConfig = {
    pagination: true,
    customGetURL: defaultPaginatedURL,
    enabledDisabledFieldForOpacityChangesInTable: 'enabled',
    onRowClick: null,
    overrideDefaultParams: (defaultParams) => {
      return defaultParams
    },
    ActionButtonWrapper: null,
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.journal:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.reflection:
      listingConfig.overrideDefaultParams = (params) => {
        return { ...params, journalType: 'regular' }
      }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.clinic:
      listingConfig.overrideDefaultParams = (params) => {
        return { ...params, kycStatus: urlApiParam === 'clinics' ? 'approved' : 'pending' }
      }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.tab:
      listingConfig.pagination = false
      listingConfig.customGetURL = SETUP_APIS.tiles.get.url
      listingConfig.overrideDefaultParams = (params) => {
        return { ...params, includedFeatures: true }
      }
      break
    // case ALL_ENTITY_TYPES_FROM_CONSTANTS.notifications:
    //   listingConfig.pagination = true
    //   listingConfig.customGetURL = 'notification/get/all/paginated'
    //   break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.planBundlePack:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicSubscriptionPlan:
      listingConfig.enabledDisabledFieldForOpacityChangesInTable = 'active'
      listingConfig.overrideDefaultParams = (defaultParams) => {
        return { ...defaultParams, planGrpId: PLAN_GROUP_TYPES.bundle }
      }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.cacheEvict:
      listingConfig.customGetURL = 'cache/metrics/get'
      listingConfig.pagination = false
      listingConfig.enabledDisabledFieldForOpacityChangesInTable = undefined
      listingConfig.onRowClick = (item) => cacheEvictRowClick(item)
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.courseCmsFieldType:
      listingConfig.customGetURL = defaultNormalURl
      listingConfig.pagination = false
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.users:
      listingConfig.customGetURL = USERS_API_SIGNATURES_MAP?.users?.get
      listingConfig.enabledDisabledFieldForOpacityChangesInTable = undefined
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.onlineCourse:
      listingConfig.ActionButtonWrapper = CourseActionButtonWrapper
      break
    default:
  }

  return listingConfig
}
