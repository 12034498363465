import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { CCPaginationContainer, CCSmartPaginationStyledComponent } from './styledComponents'

export default function CCPagination({
  paginationProps,
  pageSize,
  handlePageSizeChange,
  itemsPerPageOptions,
  hidePageSize = false,
  hidePaginationIfNoExtraPages = false,
}) {
  const isPaginationHiddenBasedOnProps = useMemo(() => {
    if (hidePaginationIfNoExtraPages) {
      const totalPages = paginationProps.pages || 0
      return totalPages <= 1
    }
    return false
  }, [hidePaginationIfNoExtraPages, paginationProps])

  function handlePgSizeChange(event) {
    handlePageSizeChange(event.target.value)
  }

  return !isPaginationHiddenBasedOnProps ? (
    <>
      <CCPaginationContainer>
        {/* <Pagination count={totalPages} page={pagination.page + 1} onChange={handlePageChange} /> */}
        <CCSmartPaginationStyledComponent {...paginationProps} />
        {!hidePageSize && (
          <FormControl variant="standard" sx={{ minWidth: 120 }} size="small">
            <InputLabel id="items-per-page">Items per page</InputLabel>
            <Select
              value={pageSize}
              size="small"
              labelId="items-per-page"
              onChange={handlePgSizeChange}
            >
              {itemsPerPageOptions ? (
                itemsPerPageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Options Not set</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
      </CCPaginationContainer>
    </>
  ) : (
    <></>
  )
}

CCPagination.propTypes = {
  pagination: PropTypes.object.isRequired,
  totalPages: PropTypes.number,
  handleChange: PropTypes.func,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
}
