import { ALL_ENTITY_TYPES_FROM_CONSTANTS, PLAN_GROUP_TYPES } from 'src/utilities/constants'
import { SUBSCRIPTION_PLAN_OBJECT } from 'src/views/subscriptions/plans/utils'
import { getSummarizableObject } from '../abstractObjects/Summarizable'

export const CLINIC_SUBSCRIPTION_PLAN_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS),
  ...SUBSCRIPTION_PLAN_OBJECT,
  planGrpId: PLAN_GROUP_TYPES.consumer,
  type: '',
  pricingType: '',
  minSeats: '',
  maxSeats: '',
}
