import { CFormInput } from '@coreui/react-pro'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import React, { useMemo, useState } from 'react'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { genericHandleChange, isCommonResponseSuccessfulV2 } from 'src/utilities/generalUtils'
import { uploadCSVDataToDB } from '../../util'

export default function HealthPlixBookedEventsAndPrescptionsUpload({
  closeDialog,
  apiParam,
  handleRefresh,
  setConfirmationModalProps,
  customOptions,
  toggleSwitchComponent = <></>,
}) {
  const [uploadedCsvFile, setUploadedCsvFile] = useState()
  const [params, setParams] = useState({
    importIdHeader: 'Patient ID',
    drugListHeader: 'Medicines',
    diagnosisListHeader: 'Diagnosis',
    visitsListHeader: 'Visits',
    importFrom: 'HP',
    appointmentNumberPrefix: 'JA',
  })

  const selectedClinic = useMemo(() => {
    return customOptions?.clinics?.find((cln) => cln?.id === params?.['clinic-id'])
  }, [params, customOptions])

  const currentMentors = useMemo(() => {
    const mentrContacts =
      [...(selectedClinic?.doctors || []), ...(selectedClinic?.therapists || [])] || []

    const teacherIdsToConsider = mentrContacts?.map((cont) => cont?.uid)

    const mentorObjects = customOptions?.mentors?.filter((teach) => {
      return teacherIdsToConsider?.includes(teach?.id)
    })
    return mentorObjects
  }, [customOptions, selectedClinic])

  const currentEventTypes = useMemo(() => {
    const selectedMentor = currentMentors?.find((mntr) => mntr?.id === params?.['clinic-doctor-id'])
    return selectedMentor?.eventTypes || []
  }, [currentMentors, params])

  const currentClinics = useMemo(() => {
    return customOptions?.clinics?.filter(
      (cln) => cln?.clinicBrandId === params?.['clinic-brand-id'],
    )
  }, [customOptions, params])

  async function saveDataToDb() {
    const resp = await uploadCSVDataToDB({
      apiParam,
      fileKey: 'booked-events',
      csvFile: uploadedCsvFile,
      params: {
        entityType: apiParam,
        importFrom: 'HP',
        ...params,
      },
      apiSignature: 'csv-upload/hp-booked-events',
      isNotCCApi: true,
    })
    if (isCommonResponseSuccessfulV2(resp)) {
      handleRefresh()
      closeDialog()
    }
  }

  function setShowConfirmation() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'save',
      body: 'Do you want to save the data in the uploaded CSV?',
      buttonColor: 'success',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      submitButtonText: 'Save Data',
      closeButtonText: 'Cancel',
      onSubmitFunctions: [
        () => {
          saveDataToDb()
        },
      ],
    }))
  }

  function handleCsvUpload(event) {
    event.preventDefault()
    if (!!event.target?.files && !!event.target.files[0]) {
      setUploadedCsvFile(event.target.files[0])
    }
  }

  function handleChangeParams(e) {
    genericHandleChange(e, setParams)
  }

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {toggleSwitchComponent}
          <>
            <Grid item xs={12} md={4}>
              <CustomReactSelect
                id="clinic-brand-id"
                value={params?.['clinic-brand-id']}
                options={customOptions?.clinicBrands || []}
                getOptionValue={(object) => object?.id}
                getOptionLabel={(object) => object?.brandName || object?.title}
                label="Clinic Brand"
                onChangeEvent={(e) => {
                  handleChangeParams(e)
                  setParams((prev) => ({ ...prev, 'clinic-id': null, 'clinic-doctor-id': null }))
                }}
                selectType={selectTypes.string}
                placeholder="Select a clinic brand..."
                required
              />
            </Grid>
            {!!params?.['clinic-brand-id'] && (
              <Grid item xs={12} md={4}>
                <CustomReactSelect
                  id="clinic-id"
                  value={params?.['clinic-id']}
                  options={currentClinics || []}
                  getOptionValue={(object) => object?.id}
                  getOptionLabel={(object) => object?.title || object?.id}
                  label="Clinic Location"
                  onChangeEvent={(e) => {
                    handleChangeParams(e)
                    setParams((prev) => ({ ...prev, 'clinic-doctor-id': null }))
                  }}
                  selectType={selectTypes.string}
                  placeholder="Select a clinic..."
                  required
                />
              </Grid>
            )}
            {!!params?.['clinic-id'] && (
              <Grid item xs={12} md={4}>
                <CustomReactSelect
                  id="clinic-doctor-id"
                  label="Mentor"
                  value={params?.['clinic-doctor-id']}
                  options={currentMentors?.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionValue={(object) => object?.id}
                  // getOptionLabel={(object) =>
                  //   `${object?.name || object?.title || object?.id} (${getRoleTitleForMentor(object)})`
                  // }
                  getOptionLabel={(object) => `${object?.name || object?.title || object?.id}`}
                  onChangeEvent={handleChangeParams}
                  selectType={selectTypes.string}
                  placeholder="Select a mentor..."
                  required
                />
              </Grid>
            )}
            {!!params?.['clinic-doctor-id'] && (
              <Grid item xs={12} md={4}>
                <CustomReactSelect
                  id="event-id"
                  label="Event Type"
                  value={params?.['event-id']}
                  // options={currentEventTypes?.sort((a, b) => a.name.localeCompare(b.name))}
                  options={currentEventTypes?.sort((a, b) => a?.title.localeCompare(b?.title))}
                  getOptionValue={(object) => object?.uid}
                  // getOptionLabel={(object) =>
                  //   `${object?.name || object?.title || object?.id} (${getRoleTitleForMentor(object)})`
                  // }
                  getOptionLabel={(object) => `${object?.name || object?.title || object?.id}`}
                  onChangeEvent={handleChangeParams}
                  selectType={selectTypes.string}
                  placeholder="Select an event type..."
                  required
                />
              </Grid>
            )}
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="import-id-header"
                value={params?.['import-id-header']}
                label="ImportId Header"
                onChange={handleChangeParams}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="drug-list-header"
                value={params?.['drug-list-header']}
                label="Drugs List Header"
                onChange={handleChangeParams}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="diagnosis-list-header"
                value={params?.['diagnosis-list-header']}
                label="Diagnosis List Header"
                onChange={handleChangeParams}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="visits-list-header"
                value={params?.['visits-list-header']}
                label="Visits List Header"
                onChange={handleChangeParams}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCFormInput
                id="appointment-number-prefix"
                value={params?.['appointment-number-prefix']}
                label="Appointment Number Prefix"
                onChange={handleChangeParams}
              />
            </Grid>
          </>
          <Grid item xs={12}>
            <CFormInput type="file" accept=".csv" onChange={handleCsvUpload} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!uploadedCsvFile}
          color="success"
          onClick={() => setShowConfirmation()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}
