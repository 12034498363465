import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { saveCurrentClinic } from '../clinic'
import { getClinicBrandProspectMentorObjectForClinicSelect, getFinalRemovedStaff } from './utils'
import { isTherapistApprovedForClinic } from 'src/utilities/clinicHelpers'

export const fetchTherapistsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/fetchTherapistsForCurrentClinic',
  async (_, { getState, dispatch }) => {
    dispatch(fetchTherapistsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicTherapists = currentClinic?.therapists
    const clinicTherapistIds = clinicTherapists
      ?.filter((therapist) => therapist?.uid)
      ?.map((therapist) => therapist?.uid)

    const therapistResp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.mentor,
      ids: clinicTherapistIds?.join(','),
    })
    if (therapistResp) {
      return clinicTherapists?.map((clinicTherapist) => {
        const therapist =
          therapistResp.find((respTherapist) => respTherapist.id === clinicTherapist.uid) || {}
        return {
          ...clinicTherapist,
          ...therapist,
          status: clinicTherapist?.status,
        }
      })
    } else {
      return therapistResp.message
    }
  },
)

export const addEditTherapistsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/addEditTherapistsForCurrentClinic',
  async ({ idx, data }, { getState, dispatch }) => {
    dispatch(addEditTherapistsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicTherapists = currentClinic?.therapists
    const updatedTherapists = [...(clinicTherapists || []), ...(data || [])]?.map((therapist) => ({
      ...therapist,
      role: 'ROLE_THERAPIST',
    }))

    await dispatch(
      saveCurrentClinic({
        dataToSave: {
          ...currentClinic,
          therapists: updatedTherapists,
        },
      }),
    )
    dispatch(fetchTherapistsForCurrentClinic())
  },
)
export const removeTherapistsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/removeTherapistsForCurrentClinic',
  async ({ idx, type = 'prospect' }, { getState, dispatch }) => {
    dispatch(removeTherapistsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicApprovedTherapists = currentClinic?.therapists?.filter(
      (therapist) => !!isTherapistApprovedForClinic(therapist),
    )
    const prospectTherapists = currentClinic?.therapists?.filter(
      (therapist) => !isTherapistApprovedForClinic(therapist),
    )
    const updatedTherapists = getFinalRemovedStaff({
      approvedStaff: clinicApprovedTherapists,
      prospectStaff: prospectTherapists,
      type,
      idx,
    })
    await dispatch(
      saveCurrentClinic({
        dataToSave: {
          ...currentClinic,
          therapists: updatedTherapists,
        },
      }),
    )
    dispatch(fetchTherapistsForCurrentClinic())
  },
)

export const fetchTherapistsFromCurrentClinicBrand = createAsyncThunk(
  'clinicFlowData/fetchTherapistsFromCurrentClinicBrand',
  async (_, { getState, dispatch }) => {
    dispatch(fetchTherapistsFromCurrentClinicBrand.pending())
    const { clinicFlowData } = getState()
    const currentClinicBrand = clinicFlowData.currentClinicBrandData.data
    const therapistsUserIds = currentClinicBrand?.workforce?.therapists?.map((therapist) => {
      return therapist?.userId || therapist?.uid
    })
    const therapistEmailIds = currentClinicBrand?.prospects?.therapists
      ?.slice()
      ?.filter((therapist) => !!therapist?.email)
      ?.map((therapist) => therapist?.email)

    const resp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.mentor,
      userIds: therapistsUserIds?.join(','),
      size: 999,
    })
    if (resp) {
      return [...(therapistsUserIds || []), ...(therapistEmailIds || [])]?.map((therapist) => {
        var foundTherapists = resp.find((t) => t.userId === therapist)
        if (!foundTherapists) {
          foundTherapists = getClinicBrandProspectMentorObjectForClinicSelect(therapist)
        } else {
          foundTherapists = {
            ...foundTherapists,
            value: foundTherapists.id,
            label: foundTherapists.title,
            status: 'approved',
          }
        }

        return foundTherapists
      })
    } else {
      return resp.message
    }
  },
)
