import moment from 'moment'
import React from 'react'
import { ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY } from 'src/utilities/constants'
import { getExactDiffFromCurrentTime } from 'src/utilities/generalUtils'

/**
 * Returns the applicable model based on the provided brandId and applicableTo type.
 * A model is considered applicable if:
 *   - Its `entityId` matches the provided `brandId`
 *   - Its `applicableTo` matches the provided applicable type
 *   - The current time is on or after the model's `applicableFrom` date
 *   - And, if provided, the current time is on or before the model's `applicableTill` date
 *
 * @param {Array} models - Array of fee models.
 * @param {string|number} brandId - The clinic's brand id.
 * @param {string|number} applicableTo - The type identifier to match.
 * @returns {Object|null} - The applicable model or null if none found.
 */
function getApplicableModel(models = [], brandId, applicableTo) {
  const now = moment()

  return models.find((m) => {
    if (m.entityId !== brandId || m.applicableTo !== applicableTo) {
      return false
    }

    const from = moment.utc(m.applicableFrom).local()
    const till = m.applicableTill ? moment.utc(m.applicableTill).local() : null

    return from.isSameOrBefore(now) && (!till || till.isSameOrAfter(now))
  })
}

/**
 * Prepares scoped columns for entity models based on the defined types.
 *
 * It returns an object where each key is a type id and the corresponding value is a function
 * that accepts an `item` and returns a table cell (<td>) with fee model details if an applicable model is found.
 */
export function prepareScopedColumnsForEntityModelsBasedOnTypes() {
  const typesArr = ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY
  const scopedColumns = {}

  typesArr.forEach((type) => {
    scopedColumns[type.id] = (item) => {
      const models = item.models || []
      const brandId = item.brand?.id
      const model = getApplicableModel(models, brandId, type.id)

      if (!model) {
        return <td>Not Active</td>
      }

      // Format the applicableFrom date
      const formattedFromDate = moment.utc(model.applicableFrom).local().format('Do MMM YYYY')

      // Prepare expiry details if applicableTill is available
      let expiryDetails = null
      if (model.applicableTill) {
        const expiryMoment = moment.utc(model.applicableTill).local()
        const formattedTillDate = expiryMoment.format('Do MMM YYYY')
        const exactDiff = getExactDiffFromCurrentTime(expiryMoment)
        expiryDetails = (
          <div>
            Expires On: {formattedTillDate} ({exactDiff})
          </div>
        )
      }

      return (
        <td>
          <div>{model.title}</div>
          <div>Since {formattedFromDate}</div>
          {expiryDetails}
        </td>
      )
    }
  })

  return scopedColumns
}
