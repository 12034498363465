import { PAYMENT_METHOD_KV_MAP } from 'src/utilities/constants'
import { getPriceStringWithCurrency, isValidNumber } from 'src/utilities/generalUtils'

export function getSettlementDetailsForClinic(payoutItem) {
  /* if collected by aumhum 
      return source share (aumhum ows {final digit } to clinic)
      else (meaning collected by them)
      fee paid - source share (Clinic ows {final digit} to aumhum)
      */

  let owingEntity = 'AUMHUM'
  let toOwningEntity = 'Clinic'

  let sourceShare = payoutItem?.sourceShare

  let finalDigit = 0

  if (!isValidNumber(sourceShare)) {
    return { color: 'default', string: 'Cannot Calculate' }
  }

  if (
    payoutItem.paymentMethod ===
    PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_COLLECTED_BY_AUMHUM
  ) {
    finalDigit = sourceShare
  } else {
    finalDigit = payoutItem?.feeCollected - sourceShare
    owingEntity = 'Clinic'
    toOwningEntity = 'AUMHUM'
  }

  if (owingEntity === 'Clinic') {
    return {
      color: 'error',
      string: `Pay ${getPriceStringWithCurrency(finalDigit, payoutItem?.currency)} to AUMHUM`,
    }
  }

  return {
    color: 'success',
    string: `Collect ${getPriceStringWithCurrency(finalDigit, payoutItem?.currency)} from AUMHUM`,
  }

  // return `${owingEntity} owes ${getPriceStringWithCurrency(
  //   finalDigit,
  //   payoutItem?.currency,
  // )} to ${toOwningEntity}`
}
export function getSettlementDetailsForMentor(payoutItem) {
  /* if collected by aumhum 
      return source share (aumhum ows {final digit } to clinic)
      else (meaning collected by them)
      fee paid - source share (Clinic ows {final digit} to aumhum)
      */

  let mentorShare = payoutItem?.mentorShare

  let finalDigit = 0

  if (!isValidNumber(mentorShare)) {
    return { color: 'default', string: 'Cannot Calculate' }
  }

  finalDigit = mentorShare

  if (finalDigit < 0) {
    return {
      color: 'error',
      string: `Pay ${getPriceStringWithCurrency(finalDigit, payoutItem?.currency)} to AUMHUM`,
    }
  }

  return {
    color: 'success',
    string: `Collect ${getPriceStringWithCurrency(finalDigit, payoutItem?.currency)} from AUMHUM`,
  }

  // return `${owingEntity} owes ${getPriceStringWithCurrency(
  //   finalDigit,
  //   payoutItem?.currency,
  // )} to ${toOwningEntity}`
}
