import { CFormInput } from '@coreui/react-pro'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { genericHandleChange, isCommonResponseSuccessful } from 'src/utilities/generalUtils'
import DefualtCsvUploadForm from 'src/views/setup/userStats/components/comps/DefualtCsvUploadForm'
import HPPatientsUpload from 'src/views/setup/userStats/components/comps/HPPatientsUpload'

const initialHPPatientInfoParams = {
  'import-id-header': 'ID',
  'patient-name-header': 'Title',
  'age-header': 'Age',
  'mobile-header': 'Mobile',
  'gender-header': 'Gender',
  'email-header': 'E-mail',
  'city-header': 'City',
  'address-header': 'Addrs.',
  'registration-date-header': 'Reg date',
}

const requiredHPFields = [
  'import-id-header',
  'patient-name-header',
  'age-header',
  'mobile-header',
  'gender-header',
  'email-header',
  'city-header',
  'address-header',
  'registration-date-header',
  'clinic-id',
  'clinic-brand-id',
  'clinic-doctor-id',
]

export default function PatientsCsvUploadComponent({
  apiParam,
  handleRefresh,
  closeDialog,
  customOptions,
  storeData,
}) {
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )
  const [uploadedCsvFile, setUploadedCsvFile] = useState()
  const [csvDelimiter, setCsvDelimiter] = useState()
  const [listDelimiter, setListDelimiter] = useState()
  const [communicationTypes, setCommunicationTypes] = useState()
  const [params, setParams] = useState(initialHPPatientInfoParams)
  const [uploadType, setUploadType] = useState('default')

  const validParams = useMemo(() => {
    if (uploadType === 'default') {
      return true
    }

    // Check if all required fields are present and not empty
    return requiredHPFields.every((field) => params?.[field])
  }, [params, uploadType, requiredHPFields])

  const uploadApiSignature = useMemo(() => {
    if (apiParam === ALL_ENTITY_TYPES_FROM_CONSTANTS.patientInfo) {
      if (uploadType !== 'default') {
        return 'csv-upload/patients'
      }
    }

    return GENERIC_APIS.entity.saveCsv
  }, [apiParam, uploadType])

  function setShowConfirmation() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'save',
      body: 'Do you want to save the data in the uploaded CSV?',
      buttonColor: 'success',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      submitButtonText: 'Save Data',
      closeButtonText: 'Cancel',
      onSubmitFunctions: [
        () => {
          saveDataToDb()
        },
      ],
    }))
  }

  async function saveDataToDb() {
    const tst = toast.loading('Saving Bulk Data')
    const formData = new FormData()
    let paramsToUse = {
      entityType: apiParam,
      'csv-delimiter': csvDelimiter,
      'list-delimiter': listDelimiter,
      'communication-type': communicationTypes?.join(','),
    }

    if (uploadApiSignature !== GENERIC_APIS.entity.saveCsv) {
      formData.append('patients', uploadedCsvFile)
      paramsToUse = params || {}
    } else {
      formData.append('file', uploadedCsvFile)
    }

    const resp = await PostSettingsData(
      uploadApiSignature,
      formData,
      paramsToUse,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      uploadApiSignature !== GENERIC_APIS.entity.saveCsv,
    )
    if (resp.status === 200) {
      if (isCommonResponseSuccessful(resp.data.code)) {
        toast.update(tst, {
          ...TOAST_UTILS,
          render: "Save operation has begun. The admins will be notified once it's done",
          closeOnClick: false,
          autoClose: 7000,
          isLoading: false,
          type: toast.TYPE.SUCCESS,
        })
        handleRefresh()
        closeDialog()
        return
      }
    }
  }

  function handleChangeParams(e) {
    genericHandleChange(e, setParams)
  }

  function handleCsvUpload(event) {
    event.preventDefault()
    if (!!event.target?.files && !!event.target.files[0]) {
      setUploadedCsvFile(event.target.files[0])
    }
  }

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {apiParam === ALL_ENTITY_TYPES_FROM_CONSTANTS.patientInfo && (
            <Grid item xs={12} sx={{ justifyContent: 'center' }}>
              <ToggleButtonGroup
                value={uploadType}
                size="small"
                exclusive
                onChange={(e, val) => !!val && setUploadType(val)}
              >
                <ToggleButton value="default">Default</ToggleButton>
                <ToggleButton value="healthPlix">HealthPlix</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

          {uploadType === 'default' ? (
            <DefualtCsvUploadForm
              communicationTypes={communicationTypes}
              setCommunicationTypes={setCommunicationTypes}
              csvDelimiter={csvDelimiter}
              setCsvDelimiter={setCsvDelimiter}
              listDelimiter={listDelimiter}
              setListDelimiter={setListDelimiter}
            />
          ) : (
            <HPPatientsUpload
              params={params}
              setParams={setParams}
              handleChangeParams={handleChangeParams}
              customOptions={customOptions}
              storeData={storeData}
            />
          )}
          <Grid item xs={12}>
            <CFormInput
              disabled={!validParams}
              type="file"
              accept=".csv"
              onChange={handleCsvUpload}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!uploadedCsvFile || !validParams}
          color="success"
          onClick={() => setShowConfirmation()}
        >
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}
