import moment from 'moment/moment'
import { DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { APPLICABLE_TO_ENTITY, MOMENT_FORMATS } from 'src/utilities/constants'
import { StyledPayoutListView } from './styledComponents'
import { getOrdinalSuffix } from 'src/utilities/generalUtils'

export default function FeeModelViewDetails({
  modelFor,
  feeModel,
  startTime,
  entityFeeModel,
  showModelTitle = false,
  forMentor = false,
}) {
  const modelForTitle =
    modelFor || APPLICABLE_TO_ENTITY?.[entityFeeModel?.entityType]?.title || 'Others'

  // <span>
  //           Fee Model For {entityName} Fee Model
  //         </span>

  return (
    <>
      <DialogTitle className="mb-2">
        {showModelTitle ? (
          <span>{entityFeeModel?.title || 'No Title'}</span>
        ) : (
          <span>
            Fee Model for {modelForTitle} as on{' '}
            {moment
              .utc(startTime)
              .local()
              .format(MOMENT_FORMATS.completeDateDetailsWithMonthShortForm)}
          </span>
        )}
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column gap-2">
          <ViewEntityFeeModel
            entityFeeModel={entityFeeModel}
            showModelTitle={showModelTitle}
            forMentor={forMentor}
          />
          <ViewFeeModel feeModel={feeModel} forMentor={forMentor} />
        </div>
      </DialogContent>
    </>
  )
}

function ViewEntityFeeModel({ entityFeeModel, forMentor }) {
  return (
    <div className="d-flex flex-column">
      <span>
        <strong>Validity: </strong>
        <span>
          {moment
            .utc(entityFeeModel?.applicableFrom)
            .local()
            .format(MOMENT_FORMATS.completeDateDetailsWithMonthShortForm)}{' '}
          -{' '}
          {entityFeeModel?.applicableTill
            ? moment
                .utc(entityFeeModel?.applicableTill)
                .local()
                .format(MOMENT_FORMATS.completeDateDetailsWithMonthShortForm)
            : 'Currenty Active'}
        </span>
      </span>
    </div>
  )
}
export function ViewFeeModel({ feeModel, hideHeading = false, forMentor = false }) {
  const hasComments = feeModel?.payoutList?.some((item) => !!item?.comment)

  return (
    <div className="d-flex flex-column gap-2">
      {!hideHeading && <h4>Model Details</h4>}
      <div className="d-flex flex-column gap-1">
        <StyledPayoutListView
          style={{ borderBottom: '1px solid black', marginBottom: '8px', fontWeight: 600 }}
          showCommentColumn={hasComments}
        >
          <span>Sessions</span>
          <span>{forMentor ? 'Therapist Share' : 'Clinic Share'}</span>
          {hasComments && <span>Comment</span>}
        </StyledPayoutListView>
        {feeModel?.payoutList.map((payout, idx) => {
          const startId = payout?.startId || 1

          if (startId === payout?.endId) {
            return (
              <StyledPayoutListView key={idx} showCommentColumn={hasComments}>
                <span>{getOrdinalSuffix(startId)} Session</span>
                <span>{payout?.partnerFee}%</span>
                <div>{payout?.comment}</div>
              </StyledPayoutListView>
            )
          }

          if (!payout?.endId) {
            return (
              <StyledPayoutListView key={idx} showCommentColumn={hasComments}>
                <span>{getOrdinalSuffix(startId)} Session and Onwards</span>
                <span>{payout?.partnerFee}%</span>
                <div>{payout?.comment}</div>
              </StyledPayoutListView>
            )
          }

          if (!startId) {
            return (
              <StyledPayoutListView key={idx} showCommentColumn={hasComments}>
                <span>{getOrdinalSuffix(startId)} Session and Onwards</span>
                <span>{payout?.partnerFee}%</span>
                <div>{payout?.comment}</div>
              </StyledPayoutListView>
            )
          }

          return (
            <StyledPayoutListView key={idx} showCommentColumn={hasComments}>
              <span>
                {getOrdinalSuffix(startId)} {payout?.endId - startId > 1 ? 'to' : 'and'}{' '}
                {getOrdinalSuffix(payout?.endId)} Session
              </span>
              <span>{payout?.partnerFee}%</span>
              {/* {hasComments && <div>{payout?.comment}</div>} */}
              <div>{payout?.comment}</div>
            </StyledPayoutListView>
          )
        })}
      </div>
    </div>
  )
}
