export function isDoctorApprovedForClinic(doctor) {
  return !!doctor?.uid
}

export function isTherapistApprovedForClinic(therapist) {
  return !!therapist?.uid
}

export function isAssistantApprovedForClinic(assistant){
  return !!assistant?.uid
}