import { getHighestNumberInList, getPriceStringWithCurrency } from 'src/utilities/generalUtils'

export function getNewRange(newRange, oldRanges) {
  const highestMaxItemNum = getHighestNumberInList(oldRanges?.map((range) => range?.max))

  return { ...newRange, min: (highestMaxItemNum || 0) + 1, max: (highestMaxItemNum || 0) + 2 }
}

// export function getFeeModelFeeRangeString(refFeeRange, currency) {
//   // optn?.currencyCode,
//   // optn?.refFeeRange?.max
//   return `${getPriceStringWithCurrency(
//     refFeeRange?.min,
//     currency,
//   )} - ${getPriceStringWithCurrency(refFeeRange?.max, currency)}`
// }


export function getFeeModelFeeRangeString(refFeeRange, currency) {
  let min = refFeeRange?.min ?? "∞"
  let max = refFeeRange?.max ?? "∞"

  console.log({min, max})

  return `${getPriceStringWithCurrency(min, currency)} - ${getPriceStringWithCurrency(
    max,
    currency,
  )}`
}
