import { toast } from 'react-toastify'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { isCommonResponseSuccessfulV2 } from 'src/utilities/generalUtils'
import { TOAST_UTILS } from '../controlCenter/toast-utils'

export async function uploadCSVDataToDB({
  apiParam,
  apiSignature = GENERIC_APIS.entity.saveCsv,
  fileKey = 'file',
  csvFile,
  params,
  isNotCCApi = false,
}) {
  const tst = toast.loading('Saving Bulk Data')
  const formData = new FormData()
  let paramsToUse = {
    entityType: apiParam,
    ...params,
  }
  formData.append(fileKey, csvFile)

  const resp = await PostSettingsData(
    apiSignature,
    formData,
    paramsToUse,
    tst,
    undefined,
    undefined,
    undefined,
    undefined,
    isNotCCApi,
  )
  if (resp.status === 200) {
    if (isCommonResponseSuccessfulV2(resp.data.code)) {
      toast.update(tst, {
        ...TOAST_UTILS,
        render: "Save operation has begun. The admins will be notified once it's done",
        closeOnClick: false,
        autoClose: 7000,
        isLoading: false,
        type: toast.TYPE.SUCCESS,
      })
      return resp
    }
    return resp
  }
  return resp
}
