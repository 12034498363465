import { CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS } from 'src/components/controlCenter/CCExtendedCSmartTable'
import { disableForContentWriter } from 'src/config/common/genericListingAndFormConfig'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export function getExcludedListOfColumnsForSmartTable(apiParam, tableIsViewOnly) {
  let toReturn = []
  if (disableForContentWriter()) {
    toReturn.push('modifiedBy', 'modifiedTime')
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.cacheEvict:
      toReturn = [
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
      ]
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.doctorUsers:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicAssistantUser:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.users:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.payoutDistributionReports:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicPayoutModels:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.therapistPayoutModels:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicEngagementModels:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.therapistEngagementModels:
      toReturn = [
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
        CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
      ]
      break

    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.agreement:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.role:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.mentor:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.supportStaff:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.quote:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.activity:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.article:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.notifications:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.tab:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.clinicBrand:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.oohMsg:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.onlineCourse:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.paymentGateways:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS?.patientInfo:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.supportUser:
      toReturn = [...(toReturn || []), CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title]
      break
    default:
      break
  }

  if (tableIsViewOnly) {
    toReturn.push(CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions)
  }

  return toReturn
}
