import React, { useMemo } from 'react'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { giveGenericHandleChangedData } from 'src/utilities/generalUtils'

export default function PatientInfoHeaderFirstLayer({ dropDownData, filters, setFilters, rStore }) {
  const selectedClinicBrand = dropDownData?.clinicBrands?.find(
    (brand) => filters?.clinicBrandId === brand?.id,
  )

  const mentorsInSelectedClinicUserIds = useMemo(() => {
    if (!!selectedClinicBrand) {
      return [
        ...(selectedClinicBrand?.workforce?.therapists?.map((ass) => ass?.userId) || []),
        ...(selectedClinicBrand?.workforce?.doctors?.map((ass) => ass?.userId) || []),
      ]
    }
    return null
  }, [selectedClinicBrand])

  const isClinicBrandSelected = !!filters?.clinicBrandId
  const isOnlyMentorSelected = !!filters?.mentorId && !isClinicBrandSelected

  return (
    <>
      <div style={{ width: '300px' }}>
        <CustomReactSelect
          id="clinicBrandId"
          value={filters?.clinicBrandId}
          options={dropDownData?.clinicBrands?.sort((a, b) => {
            const aTitle = a?.brandName || a?.title
            const bTitle = b?.brandName || b?.title

            return aTitle.localeCompare(bTitle)
          })}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option?.brandName || option?.title}
          placeholder="Select a clinicBrand"
          isClearable
          label="Clinic Brand"
          onChangeEvent={setFilters}
          selectType={selectTypes.string}
        />
      </div>
      <div style={{ width: '300px' }}>
        <CustomReactSelect
          id="mentorId"
          value={filters?.mentorId}
          options={dropDownData.mentors
            ?.filter((mentor) => {
              if (!!mentorsInSelectedClinicUserIds && mentorsInSelectedClinicUserIds?.length > 0) {
                return (mentorsInSelectedClinicUserIds || []).includes(mentor?.userId)
              }
              return true
            })
            ?.sort((a, b) => a.name.localeCompare(b.name))}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option?.name}
          placeholder="Select a mentor"
          isClearable
          label="Mentor"
          onChangeEvent={(e) => {
            setFilters((prev) => {
              let data = giveGenericHandleChangedData(e, prev)
              return { ...data }
            })
          }}
          selectType={selectTypes.string}
        />
      </div>
      {/* <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div style={threeWayButtonStyles}>
          <CButtonGroup variant="outlined" size="sm">
            <CButton
              color={'secondary'}
              active={filters?.mode === 'aumhum'}
              onClick={() => setFilters({ target: { id: 'mode', value: 'aumhum' } })}
            >
              Online
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.mode === 'offline'}
              onClick={() => setFilters({ target: { id: 'mode', value: 'offline' } })}
            >
              In Person
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.mode === null || filters?.mode === undefined}
              onClick={() => setFilters({ target: { id: 'mode', value: null } })}
            >
              Show All
            </CButton>
          </CButtonGroup>
        </div>
        <div style={threeWayButtonStyles}>
          <CButtonGroup variant="outlined" size="sm">
            <CButton
              color={'secondary'}
              active={filters?.completedBookings === true}
              onClick={() => setFilters({ target: { id: 'completedBookings', value: true } })}
            >
              Show Completed Events
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.completedBookings === false}
              onClick={() => setFilters({ target: { id: 'completedBookings', value: false } })}
            >
              Show Yet to be Completed Events
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.completedBookings === null || filters?.mode === undefined}
              onClick={() => setFilters({ target: { id: 'completedBookings', value: null } })}
            >
              Show All
            </CButton>
          </CButtonGroup>
        </div>
      </div> */}
      {/* <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div style={{ width: '270px' }}>
          <CoreMuiDateTimePicker
            inputId={'reportDuration.gte'}
            label="After Time"
            // maxDate={moment()}
            maxDate={moment()}
            value={filters?.reportDuration?.gte ? moment(filters?.reportDuration.gte) : null}
            onChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                'reportDuration.gte': date.format(MOMENT_FORMATS.javaInstantFormat),
              }))
            }
            defaultValue={moment()}
          />
        </div>
        <div style={{ width: '270px' }}>
          <CoreMuiDateTimePicker
            inputId={'reportDuration.lte'}
            label="Before Time"
            maxDate={moment()}
            value={filters?.reportDuration?.lte ? moment(filters?.reportDuration?.lte) : null}
            onChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                'reportDuration?.lte': date.format(MOMENT_FORMATS.javaInstantFormat),
              }))
            }
            defaultValue={moment()}
          />
        </div>
      </div> */}
      <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div>
          <CCFormInput
            inputId={'importId'}
            label="Import ID"
            // maxDate={moment()}
            value={filters?.['importId']}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                importId: e.target.value,
              }))
            }
          />
        </div>
      </div>
      {/* <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <CustomReactSelect
            id="paymentMethod"
            value={filters?.paymentMethod}
            options={PAYMENT_METHODS_ARRAY?.sort((a, b) => a.title.localeCompare(b.title))}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.title}
            placeholder="Select a Event Type"
            isClearable
            label="Payment Method"
            onChangeEvent={setFilters}
            selectType={selectTypes.string}
          />
          <CustomReactSelect
            id="eventType"
            value={filters?.eventType}
            options={EVENT_TYPES_ARRAY?.sort((a, b) => a.title.localeCompare(b.title))}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.title}
            placeholder="Select a Event Type"
            isClearable
            label="Event Type"
            onChangeEvent={setFilters}
            selectType={selectTypes.string}
          />
        </div>
      </div> */}
      <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div style={{ width: '300px', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <CCFormInput
            inputId={'importPatientNumber.gte'}
            label="Import Patient Number GTE"
            // maxDate={moment()}
            value={filters?.['importPatientNumber.gte']}
            type="number"
            selectType={selectTypes.string}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                'importPatientNumber.gte': e.target.value,
              }))
            }
          />
          <CCFormInput
            inputId={'importPatientNumber.lte'}
            label="Import Patient Number LTE"
            // maxDate={moment()}
            value={filters?.['importPatientNumber.lte']}
            type="number"
            selectType={selectTypes.string}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                'importPatientNumber.lte': e.target.value,
              }))
            }
          />
        </div>
      </div>
      {/* <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', gap: '10px' }}
        >
          {' '}
          <CustomReactSelect
            id="feeModelId"
            value={filters?.feeModelId}
            options={dropDownData?.allModels?.sort((a, b) => a.title.localeCompare(b.title))}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.title}
            placeholder="Select a Fee Model"
            isClearable
            label="Fee Model"
            onChangeEvent={setFilters}
            selectType={selectTypes.string}
          />
          <CustomReactSelect
            id="entityFeeModelId"
            value={filters?.entityFeeModelId}
            options={dropDownData?.allEntityModels?.sort((a, b) => a.title.localeCompare(b.title))}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.title}
            placeholder="Select a Entity Fee Model"
            isClearable
            label="Entity Fee Model"
            onChangeEvent={setFilters}
            selectType={selectTypes.string}
          />
        </div>
      </div>
      <br /> */}
    </>
  )
}

{
  /* {!!filters?.durationOperator && filters?.durationOperator !== 'eq' && (
          <div style={{ width: '300px', display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
            <CCFormInput
              inputId={'duration2InMins'}
              label="Duration (Mins)"
              // maxDate={moment()}
              value={filters?.duration2InMins}
              type="number"
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  duration2InMins: e.target.value,
                }))
              }
            />
            <CustomReactSelect
              label="Operator"
              id="op"
              // maxDate={moment()}
              value={filters?.duration2Operator}
              getOptionValue={(optn) => optn?.id}
              getOptionLabel={(optn) => optn?.title}
              placeholder=""
              options={CRITERIA_OPERATORS?.filter(
                (op) => op?.id !== 'gt' && op?.id !== 'lt' && op?.id !== 'eq',
              )}
              type="number"
              selectType={selectTypes.string}
              onChangeEvent={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  duration2Operator: e.target.value,
                }))
              }
            />
          </div>
        )} */
}
