import { fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export async function transformPatientInfoListingData({ listingData }) {
  // const idToUse = listingData?.map((segment) => segment?.id)

  let allBrandIds = listingData?.map((eventInfo) => eventInfo?.clinicBrandId)?.filter((id) => !!id)

  const brandIdsToUse = [...new Set(allBrandIds || [])]
  const allClinicIds = listingData?.map((eventInfo) => eventInfo?.clinicId)?.filter((id) => !!id)
  let clinicIds = [...new Set(allClinicIds || [])]

  console.log({ clinicIds })

  let brandsToUse = []
  if (!!brandIdsToUse && brandIdsToUse?.length > 0) {
    brandsToUse = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicBrand,
      ids: brandIdsToUse?.join(','),
    })
  }

  let clinicsToUse = []

  if (!!clinicIds && clinicIds?.length > 0) {
    clinicsToUse = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinic,
      ids: clinicIds?.join(','),
    })
  }

  return listingData?.map((eventInfo) => {
    const foundBrand = brandsToUse?.find((brand) => brand?.id === eventInfo?.clinicBrandId)
    const foundClinic = clinicsToUse?.find((clinic) => clinic?.id === eventInfo?.clinicId)
    return {
      ...eventInfo,
      brandData: foundBrand,
      clinicData: foundClinic,
    }
  })
}
