import { Info } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { getPriceStringWithCurrency, isValidNumber } from 'src/utilities/generalUtils'
import FeeModelViewDetails from './components/FeeModelViewDetails'
import { ModelViewDialogPaper } from './components/styledComponents'

export default function PayoutDistributionReportModelDetails({
  report,
  getSharePercent,
  getShareAmount,
  getEntityTitle,
  forMentor = false,
  getEntityFeeModelDetails,
  getFeeModelDetails,
  getCurrencyCode,
  hideShareDetails,
  hideAllTableSummaryDetails,
  viewButton = (onClick) => (
    <IconButton
      size="small"
      onClick={() => {
        onClick()
      }}
    >
      <Info />
    </IconButton>
  ),
}) {
  const [showModelDetails, setShowModelDetails] = useState(false)
  const itemTitle = getEntityTitle(report)
  const sharePercent = getSharePercent(report)
  const shareAmount = getShareAmount(report)

  const entityFeeModel = getEntityFeeModelDetails(report)
  const feeModel = getFeeModelDetails(report)

  const currencyCode = getCurrencyCode(report)

  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-1">
        <div className="d-flex flex-column gap-2">
          {!hideAllTableSummaryDetails && <span>{itemTitle || 'No Title'}</span>}
          {!hideShareDetails && !hideAllTableSummaryDetails && (
            <span>
              Share Amount:{' '}
              {isValidNumber(shareAmount)
                ? getPriceStringWithCurrency(shareAmount, currencyCode)
                : 'No Data'}{' '}
              ({!!sharePercent ? `${sharePercent}%` : 'No Data'})
            </span>
          )}
        </div>
        {!hideShareDetails &&
          entityFeeModel &&
          feeModel &&
          viewButton(() => {
            setShowModelDetails(true)
          })}
      </div>
      <Dialog
        PaperComponent={ModelViewDialogPaper}
        open={showModelDetails}
        onClose={() => {
          setShowModelDetails(false)
        }}
      >
        <FeeModelViewDetails
          entityFeeModel={entityFeeModel}
          feeModel={feeModel}
          forMentor={forMentor}
          closeDialog={() => setShowModelDetails(false)}
        />
      </Dialog>
    </>
  )
}
