import { createAsyncThunk } from '@reduxjs/toolkit'
import { transformFetchedEntity } from 'src/config/genericForm/fetch/fetchEntityTransformer'
import { fetchFunction, fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getUsersSummaryFromUserIds } from 'src/utilities/helpers/usersHelpers'
import { saveCurrentClinic } from '../clinic'
import { getClinicBrandProspectMentorObjectForClinicSelect, getFinalRemovedStaff } from './utils'
import { isAssistantApprovedForClinic } from 'src/utilities/clinicHelpers'

export async function getClinicAssistantsWithTheirNames(params) {
  const resp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicAssistant,
    ...params,
  })
  const userIdsToFind = await getUsersSummaryFromUserIds(resp?.map((res) => res.userId))
  return resp?.map((assistant) => {
    const userName = userIdsToFind.find((user) => user.userId === assistant.userId)
    return {
      ...assistant,
      title: userName?.firstName || userName?.name || assistant?.email,
    }
  })
}

export const fetchAssistantsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/fetchAssistantsForCurrentClinic',
  async (_, { getState, dispatch }) => {
    dispatch(fetchAssistantsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const currentClinicBrand = clinicFlowData.currentClinicBrandData.data

    const clinicAssistants = currentClinic?.assistants
    const clinicAssistantIds = clinicAssistants
      ?.filter((assistant) => isAssistantApprovedForClinic(assistant))
      ?.map((assistant) => assistant?.uid)

    const resp = await getClinicAssistantsWithTheirNames({
      ids: clinicAssistantIds?.join(','),
      clinicBrandId: currentClinicBrand?.id,
      clinicId: currentClinic?.id,
    })

    if (resp) {
      return clinicAssistants?.map((clinicObjectAssistants) => {
        const assistant =
          resp.find((respAssistant) => respAssistant.id === clinicObjectAssistants.uid) || {}
        return {
          ...clinicObjectAssistants,
          ...assistant,
          status: clinicObjectAssistants?.status,
          title: assistant?.title || clinicObjectAssistants?.email,
        }
      })
    } else {
      return resp.message
    }
  },
)

export const fetchAssistantsFromCurrentClinicBrand = createAsyncThunk(
  'clinicFlowData/fetchAssistantsFromCurrentClinicBrand',
  async (_, { getState, dispatch }) => {
    dispatch(fetchAssistantsFromCurrentClinicBrand.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const currentClinicBrand = clinicFlowData.currentClinicBrandData.data
    const assistantsUserIds = currentClinicBrand?.workforce.clinicAssistants?.map(
      (assistant) => assistant.userId,
    )
    const assistantsEmailIds = currentClinicBrand?.prospects.clinicAssistants
      ?.filter((assistant) => !!assistant?.email)
      ?.map((assistant) => assistant.email)

    const resp = await getClinicAssistantsWithTheirNames({
      userIds: assistantsUserIds?.join(','),
      clinicBrandId: currentClinicBrand?.id,
    })
    if (resp) {
      return [...(assistantsUserIds || []), ...(assistantsEmailIds || [])]?.map(
        (assistantUserOrEmailIds) => {
          var foundAssistant = resp.find(
            (fetchedAssistant) =>
              fetchedAssistant.userId === assistantUserOrEmailIds &&
              fetchedAssistant.clinicId === currentClinic?.id,
          )
          if (!foundAssistant) {
            foundAssistant =
              getClinicBrandProspectMentorObjectForClinicSelect(assistantUserOrEmailIds)
          } else {
            foundAssistant = {
              ...foundAssistant,
              value: foundAssistant.id,
              label: foundAssistant.title,
              status: 'approved',
            }
          }

          return foundAssistant
        },
      )
    } else {
      return resp.message
    }
  },
)

export const addEditAssistantsProspectForClinic = createAsyncThunk(
  'clinicFlowData/addEditAssistantsProspectForClinic',
  async ({ data }, { getState, dispatch }) => {
    dispatch(addEditAssistantsProspectForClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicAssistants = currentClinic?.assistants
    const updatedAssistants = [...(clinicAssistants || []), ...(data || [])]?.map((assistant) => ({
      ...assistant,
      role: 'ROLE_CLINIC_ASSISTANT',
    }))
    await dispatch(
      saveCurrentClinic({
        dataToSave: {
          ...currentClinic,
          assistants: updatedAssistants,
        },
      }),
    )
    dispatch(fetchAssistantsForCurrentClinic())
  },
)

export const removeAssistantsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/removeAssistantsForCurrentClinic',
  async ({ idx, type = 'prospect' }, { getState, dispatch }) => {
    dispatch(removeAssistantsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicApprovedAssistants = currentClinic?.assistants?.filter(
      (assistant) => !!isAssistantApprovedForClinic(assistant),
    )
    const prospectClinicAssistants = currentClinic?.assistants?.filter(
      (assistant) => !isAssistantApprovedForClinic(assistant),
    )
    const updatedAssistants = getFinalRemovedStaff({
      approvedStaff: clinicApprovedAssistants,
      prospectStaff: prospectClinicAssistants,
      type,
      idx,
    })
    await dispatch(
      saveCurrentClinic({
        dataToSave: {
          ...currentClinic,
          assistants: updatedAssistants,
        },
      }),
    )
    dispatch(fetchAssistantsForCurrentClinic())
  },
)

export const fetchCurrentAssistant = createAsyncThunk(
  'clinicFlowData/fetchCurrentAssistant',
  async (assistantId, { getState, dispatch }) => {
    dispatch(fetchCurrentAssistant.pending())
    const { clinicFlowData } = getState()
    const currentAssistantData = clinicFlowData.currentAssistantData.data
    const currentAssistantId = currentAssistantData?.uid

    if (assistantId === currentAssistantId) {
      dispatch(fetchCurrentAssistant.fulfilled(currentAssistantData))
    }
    const resp = await fetchFunction(GENERIC_APIS.entity.details, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicAssistant,
      id: assistantId,
    })
    if (resp) {
      const transformedData = transformFetchedEntity(
        ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicAssistant,
        resp,
      )
      return transformedData
    } else {
      dispatch(fetchCurrentAssistant.rejected(resp))
      return currentAssistantData
    }
  },
)

// export const setupClinicPeopleData = createAsyncThunk(
//   'clinicFlowData/setupClinicPeopleData',
//   async (_, { dispatch }) => {
//     console.log('setupClinicPeopleData')
//     dispatch(fetchDoctorsForCurrentClinic())
//   },
// )
