import React from 'react'
import ReactPlayer from 'react-player'
import ImageRenderComponent from 'src/components/controlCenter/imageUpload/ImageRenderComponent'
import { OOH_MSG_TYPES } from 'src/utilities/constants'

export default function ShowEnlargedOOH({ oohMsg }) {
  return oohMsg?.imageType === OOH_MSG_TYPES.image ? (
    <ImageRenderComponent
      sx={{ minWidth: '600px', maxHeight: '400px', height: '100%', width: '100%' }}
      
      src={oohMsg?.imageId}
    />
  ) : (
    <ReactPlayer
      url={oohMsg?.youtubeUrl}
      style={{
        width: '100%',
        height: '100%',
        padding: '10px',
        maxWidth: '600px',
        maxHeight: '400px',
      }}
    />
  )
}
