import { CButton, CButtonGroup } from '@coreui/react-pro'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { threeWayButtonStyles } from 'src/config/common/genericListingAndFormConfig'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { CRITERIA_OPERATORS, EVENT_TYPES_ARRAY, MOMENT_FORMATS } from 'src/utilities/constants'
import {
  giveGenericHandleChangedData,
  loadOptionsForAsyncPaginate,
} from 'src/utilities/generalUtils'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import {
  CCFormInput,
  CoreMuiDateTimePicker,
  CustomReactSelect,
  selectTypes,
} from '../../FormComponents'
import {
  UserInputOption,
  UserMultiValueLabelOption,
  UserSelectedOption,
} from '../../userInput/UserInputComponent'

export default function BookedEventFilters({ filters, setFilters, dropDownData }) {
  const [selectedUser, setSelectedUser] = useState()
  const [loading, setLoading] = useState(false)

  const selectedClinic = dropDownData?.clinics?.find((clinic) => filters?.clinicId === clinic?.id)
  const selectedClinicBrand = dropDownData?.clinicBrands?.find(
    (brand) => filters?.clinicBrandId === brand?.id,
  )

  const assistantsInSelectedClinicUserIds = useMemo(() => {
    if (!!selectedClinic) {
      return [...(selectedClinic?.assistants?.map((ass) => ass?.userId) || [])]
    }
    if (!!selectedClinicBrand) {
      return [
        ...(selectedClinicBrand?.workforce?.clinicAssistants?.map((ass) => ass?.userId) || []),
      ]
    }
  }, [selectedClinic, selectedClinicBrand])

  const mentorsInSelectedClinicUserIds = useMemo(() => {
    if (!!selectedClinic) {
      return [
        ...(selectedClinic?.therapists?.map((ass) => ass?.userId) || []),
        ...(selectedClinic?.doctors?.map((ass) => ass?.userId) || []),
      ]
    }
    if (!!selectedClinicBrand) {
      return [
        ...(selectedClinicBrand?.workforce?.therapists?.map((ass) => ass?.userId) || []),
        ...(selectedClinicBrand?.workforce?.doctors?.map((ass) => ass?.userId) || []),
      ]
    }
    return null
  }, [selectedClinic, selectedClinicBrand])

  const isClinicBrandSelected = !!filters?.clinicBrandId
  const isOnlyMentorSelected = !!filters?.mentorId && !isClinicBrandSelected

  const selUser = useMemo(() => {
    return filters?.userId === selectedUser?.userId
  }, [filters, selectedUser])

  console.log({
    assistantsInSelectedClinicUserIds,
    mentorsInSelectedClinicUserIds,
    selectedClinic,
    selectedClinicBrand,
  })

  return (
    <>
      <div style={{ width: '300px' }}>
        <CustomReactSelect
          id="clinicBrandId"
          value={filters?.clinicBrandId}
          options={dropDownData?.clinicBrands?.sort((a, b) => {
            const aTitle = a?.brandName || a?.title
            const bTitle = b?.brandName || b?.title

            return aTitle.localeCompare(bTitle)
          })}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option?.brandName || option?.title}
          placeholder="Select a clinicBrand"
          isClearable
          label="Clinic Brand"
          onChangeEvent={setFilters}
          selectType={selectTypes.string}
        />
      </div>
      {isClinicBrandSelected && (
        <div style={{ width: '300px' }}>
          <CustomReactSelect
            id="clinicId"
            value={filters?.clinicId}
            options={dropDownData?.clinics
              ?.filter((cln) => cln?.clinicBrandId === filters?.clinicBrandId)
              ?.sort((a, b) => {
                const aTitle = a?.title
                const bTitle = b?.title

                return aTitle.localeCompare(bTitle)
              })}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.title}
            placeholder="Select a clinic..."
            isClearable
            label="Clinic Location"
            onChangeEvent={setFilters}
            selectType={selectTypes.string}
          />
        </div>
      )}
      <div style={{ width: '300px' }}>
        <CustomReactSelect
          id="mentorId"
          value={filters?.mentorId}
          options={dropDownData.mentors
            ?.filter((mentor) => {
              if (!!mentorsInSelectedClinicUserIds && mentorsInSelectedClinicUserIds?.length > 0) {
                return (mentorsInSelectedClinicUserIds || []).includes(mentor?.userId)
              }
              return true
            })
            ?.sort((a, b) => a.name.localeCompare(b.name))}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option?.name}
          placeholder="Select a mentor"
          isClearable
          label="Mentor"
          onChangeEvent={(e) => {
            setFilters((prev) => {
              let data = giveGenericHandleChangedData(e, prev)
              return { ...data, isClinicalEvent: null }
            })
          }}
          selectType={selectTypes.string}
        />
      </div>
      {isOnlyMentorSelected && (
        <div className="w-100  d-flex align-items-center justify-content-start gap-3">
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="outlined" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.isClinicalEvent === 'true'}
                onClick={() => setFilters({ target: { id: 'isClinicalEvent', value: 'true' } })}
              >
                Clinical Events
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.isClinicalEvent === 'false'}
                onClick={() => setFilters({ target: { id: 'isClinicalEvent', value: 'false' } })}
              >
                Non Clinical Events
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.isClinicalEvent === null || filters?.isClinicalEvent === undefined}
                onClick={() => setFilters({ target: { id: 'isClinicalEvent', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
        </div>
      )}
      {isClinicBrandSelected && (
        <>
          <div style={{ width: '300px' }}>
            <div className="mb-2">Clinic Assistant</div>
            <ActivityItemAsyncSelect
              classNamePrefix="activity-item-select"
              cacheUniqs={[filters?.clinicId, filters?.clinicBrandId]}
              menuPortalTarget={document.body}
              isClearable
              value={selectedUser ? selectedUser : null}
              placeholder="Select assistant"
              loadOptions={loadOptionsForAsyncPaginate}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.firstName}
              components={{
                Option: UserInputOption,
                SingleValue: UserSelectedOption,
                MultiValueLabel: UserMultiValueLabelOption,
              }}
              debounceTimeout={1000}
              additional={{
                size: 30,
                getFunction: GetSettingsData,
                apiUrl: 'end-user/list/all/paginated',
                transformDataToReturn: (users) => {
                  return users?.filter((usr) => {
                    if (
                      !!assistantsInSelectedClinicUserIds &&
                      assistantsInSelectedClinicUserIds?.length > 0
                    ) {
                      return assistantsInSelectedClinicUserIds?.includes(usr?.userId)
                    }
                    return true
                  })
                },
                otherParams: {
                  role: 'ROLE_CLINIC_ASSISTANT',
                },
                setLoading: setLoading,
              }}
              onChange={(selVal) => {
                setFilters((prev) => ({
                  ...prev,
                  userId: selVal?.userId,
                }))
                setSelectedUser(selVal)
              }}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="eventType"
              value={filters?.eventType}
              options={EVENT_TYPES_ARRAY?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a Event Type"
              isClearable
              label="Event Type"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )}
      <div style={{ width: '400px' }}>
        <labe className="mb-2">Attendee</labe>
        <ActivityItemAsyncSelect
          classNamePrefix="activity-item-select"
          menuPortalTarget={document.body}
          isClearable
          value={selectedUser ? selectedUser : null}
          placeholder="Select user"
          loadOptions={loadOptionsForAsyncPaginate}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.firstName}
          components={{
            Option: UserInputOption,
            SingleValue: UserSelectedOption,
            MultiValueLabel: UserMultiValueLabelOption,
          }}
          debounceTimeout={1000}
          additional={{
            size: 30,
            getFunction: GetSettingsData,
            apiUrl: 'end-user/list/all/paginated',
            setLoading: setLoading,
          }}
          onChange={(selVal) => {
            setFilters((prev) => ({
              ...prev,
              userId: selVal?.userId,
            }))
            setSelectedUser(selVal)
          }}
        />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div style={threeWayButtonStyles}>
          <CButtonGroup variant="outlined" size="sm">
            <CButton
              color={'secondary'}
              active={filters?.meetingSrc === 'aumhum'}
              onClick={() => setFilters({ target: { id: 'meetingSrc', value: 'aumhum' } })}
            >
              Online
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.meetingSrc === 'offline'}
              onClick={() => setFilters({ target: { id: 'meetingSrc', value: 'offline' } })}
            >
              In Person
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.meetingSrc === null || filters?.meetingSrc === undefined}
              onClick={() => setFilters({ target: { id: 'meetingSrc', value: null } })}
            >
              Show All
            </CButton>
          </CButtonGroup>
        </div>
        <div style={threeWayButtonStyles}>
          <CButtonGroup variant="outlined" size="sm">
            <CButton
              color={'secondary'}
              active={filters?.markedCompleted === 'true'}
              onClick={() => setFilters({ target: { id: 'markedCompleted', value: 'true' } })}
            >
              Completed
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.markedCompleted === 'false'}
              onClick={() => setFilters({ target: { id: 'markedCompleted', value: 'false' } })}
            >
              Not Started
            </CButton>
            <CButton
              color={'secondary'}
              active={filters?.markedCompleted === null || filters?.markedCompleted === undefined}
              onClick={() => setFilters({ target: { id: 'markedCompleted', value: null } })}
            >
              Show All
            </CButton>
          </CButtonGroup>
        </div>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div style={{ width: '270px' }}>
          <CoreMuiDateTimePicker
            inputId={'afterTime'}
            label="After Time"
            // maxDate={moment()}
            maxDate={moment()}
            value={filters?.afterTime ? moment(filters?.afterTime) : null}
            onChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                afterTime: date.format(MOMENT_FORMATS.formatThatServerSends),
              }))
            }
            defaultValue={moment()}
          />
        </div>
        <div style={{ width: '270px' }}>
          <CoreMuiDateTimePicker
            inputId={'beforeTime'}
            label="Before Time"
            maxDate={moment()}
            value={filters?.beforeTime ? moment(filters?.beforeTime) : null}
            onChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                beforeTime: date.format(MOMENT_FORMATS.formatThatServerSends),
              }))
            }
            defaultValue={moment()}
          />
        </div>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-start gap-3">
        <div style={{ width: '300px', display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
          <CCFormInput
            inputId={'durationInMins'}
            label="Duration (Mins)"
            // maxDate={moment()}
            value={filters?.durationInMins}
            type="number"
            selectType={selectTypes.string}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                durationInMins: e.target.value,
              }))
            }
          />
          <CustomReactSelect
            label="Operator"
            id="op"
            // maxDate={moment()}
            value={filters?.durationOperator}
            options={CRITERIA_OPERATORS?.filter((op) => op?.id !== 'gt' && op?.id !== 'lt')}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            placeholder=""
            type="number"
            selectType={selectTypes.string}
            onChangeEvent={(e) =>
              setFilters((prev) => ({
                ...prev,
                durationOperator: e.target.value,
              }))
            }
          />
        </div>
        {!!filters?.durationOperator && filters?.durationOperator !== 'eq' && (
          <div style={{ width: '300px', display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
            <CCFormInput
              inputId={'duration2InMins'}
              label="Duration (Mins)"
              // maxDate={moment()}
              value={filters?.duration2InMins}
              type="number"
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  duration2InMins: e.target.value,
                }))
              }
            />
            <CustomReactSelect
              label="Operator"
              id="op"
              // maxDate={moment()}
              value={filters?.duration2Operator}
              getOptionValue={(optn) => optn?.id}
              getOptionLabel={(optn) => optn?.title}
              placeholder=""
              options={CRITERIA_OPERATORS?.filter(
                (op) => op?.id !== 'gt' && op?.id !== 'lt' && op?.id !== 'eq',
              )}
              type="number"
              selectType={selectTypes.string}
              onChangeEvent={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  duration2Operator: e.target.value,
                }))
              }
            />
          </div>
        )}
      </div>
      <br />
    </>
  )
}

// {/* <UserInputComponent
//           onChange={(val) => {
//             setFilters((prev) => ({
//               ...prev,
//               userId: val?.userId,
//             }))
//             setSelectedUser(val)
//           }}
//           isValidNewOption={(inputValue, selectValue, selectOptions) => {
//             return !selectOptions.find((option) => option.email === inputValue)
//           }}
//           getOptionLabel={getOptionLabelForUserInput}
//           label="End User"
//           value={selUser}
//         /> */}
