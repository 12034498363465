import { Close } from '@mui/icons-material'
import { Button, Grid, IconButton } from '@mui/material'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import {
  CCFormInput,
  CoreMuiDateTimePicker,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import {
  APPLICABLE_TO_ENTITY,
  APPLICABLE_TO_ENTITY_ARRAY,
  currency_list,
  ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY,
  EVENT_MODES_ARRAY,
} from 'src/utilities/constants'
import {
  getChangedDataForSetters,
  getCurrencyObject,
  openInNewTab,
} from 'src/utilities/generalUtils'
import EntityFeeRanges from './components/EntityFeeRanges'
import { useLocation, useParams } from 'react-router'

export default function EntityFeeModelForm({
  initialObject,
  imageObject: entityFeeModel,
  setImageObject: setEntityFeeModel,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  extraActionButtonsRefObject,
  refreshViewedItem,
  closeDialog,
  refreshListing,
}) {
  const [feeModels, setFeeModels] = useState()
  const [loadingFeeModels, setLoadingFeeModels] = useState(false)
  const [showTimeFields, setShowTimeFields] = useState(true)
  const location = useLocation()
  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)

  const paramEntityType = urlParams.get('entityType')
  const paramEntityId = urlParams.get('entityId')
  const paramApplicableTo = urlParams.get('applicableTo')

  const selectedCurrencyObject = useMemo(() => {
    return getCurrencyObject(entityFeeModel?.currencyCode)
  }, [entityFeeModel])

  const entityOptions = useMemo(() => {
    if (entityFeeModel?.entityType === APPLICABLE_TO_ENTITY.clinic.id) {
      return customOptions?.clinicBrands
    }
    if (entityFeeModel?.entityType === APPLICABLE_TO_ENTITY.mentor.id) {
      return customOptions?.mentors
    }
    return []
  }, [customOptions, entityFeeModel])
  const relevantFeeModels = useMemo(() => {
    if (!entityFeeModel?.entityType) {
      return []
    }
    return feeModels?.filter((model) => {
      return model?.applicableTo === entityFeeModel?.entityType
    })
  }, [entityFeeModel, feeModels])

  async function getAllFeeModels() {
    setLoadingFeeModels(true)
    const resp = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.feeModel,
      size: 999,
    })
    if (resp.status === 200) {
      setFeeModels(resp.data.content)
    }
    setLoadingFeeModels(false)
  }

  function setFeeRanges(dPrev) {
    setEntityFeeModel((prev) => ({
      ...prev,
      feeRanges: getChangedDataForSetters(dPrev, prev?.feeRanges),
    }))
  }
  useEffect(() => {
    getAllFeeModels()
    setEntityFeeModel((prev) => ({
      ...prev,
      entityType: !!paramEntityType ? paramEntityType : prev?.entityType,
      entityId: !!paramEntityId ? paramEntityId : prev?.entityId,
      applicableTo: !!paramApplicableTo ? paramApplicableTo : prev?.applicableTo,
    }))
    // setEntityFeeModel((prev) => ({
    //   ...prev,
    //   applicableFrom: moment().utc().format(MOMENT_FORMATS.formatThatServerSends),
    // }))
  }, [])

  useEffect(() => {
    console.log({ feeModels, relevantFeeModels })
  }, [feeModels, entityFeeModel])

  return (
    <div className="d-flex flex-column gap-2">
      <GridSectionWrapper heading="Model Info">
        <Grid item xs={12} md={4}>
          <CCFormInput
            id="title"
            value={entityFeeModel?.title}
            onChange={handleChangeObject}
            label="Internal Title"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="entityType"
            value={entityFeeModel?.entityType}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            onChangeEvent={(e) => {
              setEntityFeeModel((prev) => ({
                ...prev,
                entityType: e.target.value,
                feeRange: { ...(prev?.feeRange || {}), modelId: null },
              }))
            }}
            selectType={selectTypes.string}
            label="Entity Type"
            options={APPLICABLE_TO_ENTITY_ARRAY}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {entityFeeModel?.entityType && (
            <CustomReactSelect
              id="entityId"
              value={entityFeeModel?.entityId}
              getOptionValue={(optn) => optn?.id}
              getOptionLabel={(optn) => optn?.title || optn?.brandName}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
              isClearable
              label="Entity"
              options={entityOptions?.sort((a, b) => {
                return a?.title?.localeCompare(b.title)
              })}
              disabled={disabled}
              // required
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="d-flex align-items-end gap-2">
            <CoreMuiDateTimePicker
              key="applicableFrom"
              inputId={'applicableFrom'}
              label="Applicable From"
              required
              maxDate={moment().utc().local()}
              value={
                entityFeeModel?.applicableFrom
                  ? moment.utc(entityFeeModel?.applicableFrom).local()
                  : null
              }
              onChange={(date) => {
                handleChangeObject({
                  target: {
                    id: 'applicableFrom',
                    value: moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
                  },
                })
              }}
              disabled={disabled}
            />
            <IconButton
              disabled={disabled}
              sx={{ mb: 1 }}
              size="small"
              onClick={() => {
                // rerenderTimeFields()
                setEntityFeeModel((prev) => ({ ...prev, applicableFrom: null }))
              }}
            >
              <Close />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="d-flex align-items-end gap-2">
            {showTimeFields && (
              <CoreMuiDateTimePicker
                key={'applicableTill'}
                inputId={'applicableTill'}
                label="Applicable Till"
                minDate={moment().utc().local()}
                value={
                  entityFeeModel?.applicableTill
                    ? moment.utc(entityFeeModel?.applicableTill).local()
                    : null
                }
                onChange={(date) => {
                  handleChangeObject({
                    target: {
                      id: 'applicableTill',
                      value: moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
                    },
                  })
                }}
                disabled={disabled}
              />
            )}
            <IconButton
              disabled={disabled}
              sx={{ mb: 1 }}
              size="small"
              onClick={() => {
                // rerenderTimeFields()
                setEntityFeeModel((prev) => ({ ...prev, applicableTill: null }))
              }}
            >
              <Close />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="countryCode"
            value={customOptions?.countries?.find(
              (ctry) => ctry?.id === entityFeeModel?.countryCode,
            )}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => {
              const foundCurrency = currency_list?.find(
                (curr) => curr?.code === optn?.currency,
              ) || { code: optn?.currency }

              return `${optn?.title} - ${foundCurrency?.symbol || foundCurrency?.code || ''}`
            }}
            onChangeEvent={(e) => {
              setEntityFeeModel((prev) => ({
                ...prev,
                countryCode: e?.target?.value?.id,
                currencyCode: e?.target?.value?.currency,
              }))
            }}
            selectType={selectTypes.object}
            label="Country"
            required
            options={[
              // ...customOptions?.countries?.sort((a, b) => {
              //   return a?.title?.localeCompare(b.title)
              // }),
              ...customOptions?.countries,
              {
                id: 'row',
                currency: 'usd',
                title: 'Rest Of the World',
              },
            ]}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="mode"
            value={entityFeeModel?.mode}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            placeholder="Select Mode (Leaving empty applies to all modes)"
            isClearable
            onChangeEvent={(e) => {
              setEntityFeeModel((prev) => ({
                ...prev,
                mode: e.target.value,
              }))
            }}
            selectType={selectTypes.string}
            label="Mode"
            options={EVENT_MODES_ARRAY}
            disabled={disabled}
          />
        </Grid>
        {/* {entityFeeModel.entityType === APPLICABLE_TO_ENTITY.mentor.id && ( */}
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="applicableTo"
            value={entityFeeModel?.applicableTo}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            onChangeEvent={(e) => {
              setEntityFeeModel((prev) => ({
                ...prev,
                applicableTo: e.target.value,
              }))
            }}
            isClearable
            selectType={selectTypes.string}
            label="Applicable To"
            options={ENTITY_FEE_MODEL_APPLICABLE_TO_VALUES_ARRAY?.filter((appTo) => {
              console.log({ appTo, entityFeeModel })
              if (appTo.for === entityFeeModel.entityType) {
                return true
              }
              return false
            }).sort((a, b) => a?.title.localeCompare(b?.title))}
            disabled={disabled}
            required
          />
          {/* minimumFeePerSession */}
        </Grid>
        <Grid item xs={12} md={4}>
          <CCFormInput
            id="minimumFeePerSession"
            value={entityFeeModel?.minimumFeePerSession}
            type="number"
            label="Minimum Fee Per Session"
            onChange={handleChangeObject}
            disabled={disabled}
          />
        </Grid>
        {/* )} */}
        {/* <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <FormControlLabel
            control={
              <Checkbox
                id="defaultModel"
                checked={!!entityFeeModel?.defaultModel || false}
                disabled={disabled}
                onChange={handleChangeObject}
              />
            }
            label="Default Model"
          />
        </Grid> */}
      </GridSectionWrapper>
      <GridSectionWrapper
        heading={
          <div className="d-flex align-items-center gap-2">
            <span>Fee Range</span>{' '}
            <Button
              onClick={getAllFeeModels}
              disabled={loadingFeeModels}
              sx={{ textTransform: 'none', textDecoration: 'underline' }}
            >
              Refresh Fee Models
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                openInNewTab(`/health-care/business-models/business-model-templates/new?mode=Add`)
              }}
              sx={{ textTransform: 'none', textDecoration: 'underline' }}
            >
              Create New Fee Models
            </Button>
          </div>
        }
      >
        <EntityFeeRanges
          feeRanges={entityFeeModel?.feeRanges}
          setFeeRanges={setFeeRanges}
          refreshFeeModels={getAllFeeModels}
          relevantFeeModels={relevantFeeModels}
          loadingFeeModels={loadingFeeModels}
          disabled={disabled}
          currencyObject={selectedCurrencyObject}
        />
      </GridSectionWrapper>
    </div>
  )
}
