import { CFormInput } from '@coreui/react-pro'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { genericHandleChange, isCommonResponseSuccessful } from 'src/utilities/generalUtils'
import DefualtCsvUploadForm from 'src/views/setup/userStats/components/comps/DefualtCsvUploadForm'
import HPPatientsUpload from 'src/views/setup/userStats/components/comps/HPPatientsUpload'
import DefaultCsvUploadComponent from '../../DefaultCsvUploadComponent'
import HealthPlixBookedEventsAndPrescptionsUpload from './HealthPlixBookedEventsAndPrescptionsUpload'

const initialHPPatientInfoParams = {
  'import-id-header': 'ID',
  'patient-name-header': 'Title',
  'age-header': 'Age',
  'mobile-header': 'Mobile',
  'gender-header': 'Gender',
  'email-header': 'E-mail',
  'city-header': 'City',
  'address-header': 'Addrs.',
  'registration-date-header': 'Reg date',
}

const requiredHPFields = [
  'import-id-header',
  'patient-name-header',
  'age-header',
  'mobile-header',
  'gender-header',
  'email-header',
  'city-header',
  'address-header',
  'registration-date-header',
  'clinic-id',
  'clinic-brand-id',
  'clinic-doctor-id',
]

export default function BookedEventsCsvUploadComponent({
  apiParam,
  handleRefresh,
  closeDialog,
  customOptions,
  storeData,
  setConfirmationModalProps,
}) {
  const [uploadType, setUploadType] = useState('default')

  const FormComponent = useMemo(() => {
    switch (uploadType) {
      case 'healthPlix':
        return HealthPlixBookedEventsAndPrescptionsUpload
      default:
        return DefaultCsvUploadComponent
    }
  }, [uploadType])

  return (
    <>
      <FormComponent
        toggleSwitchComponent={
          <Grid item xs={12} sx={{ justifyContent: 'center' }}>
            <ToggleButtonGroup
              value={uploadType}
              size="small"
              exclusive
              onChange={(e, val) => !!val && setUploadType(val)}
            >
              <ToggleButton value="default">Default</ToggleButton>
              <ToggleButton value="healthPlix">HealthPlix</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        }
        apiParam={apiParam}
        closeDialog={closeDialog}
        handleRefresh={handleRefresh}
        setConfirmationModalProps={setConfirmationModalProps}
        customOptions={customOptions}
        storeData={storeData}
      />
    </>
  )
}
