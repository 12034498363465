import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { ALL_GENDERS, EVENT_TYPES_ARRAY, MOMENT_FORMATS } from 'src/utilities/constants'
import {
  getAgeFromDobWithTime,
  getOrdinalSuffix,
  getPriceStringWithCurrency,
  isValidNumber,
} from 'src/utilities/generalUtils'
import { ModelViewDialogPaper } from './components/styledComponents'
import PayoutDistributionReportModelDetails from './PayoutDistributionReportModelDetails'

export default function ClinicBrandPayoutReportsViewDetails({
  report,
  forMentor = false,
  hideFeeDetailsWhenShareZero = false,
}) {
  const [viewSessionDetails, setViewSessionDetails] = useState(false)

  function openSessionDetails() {
    setViewSessionDetails(true)
  }

  function closeSessionDetails() {
    setViewSessionDetails(false)
  }
  const shareAmt = forMentor ? report?.mentorShare : report?.sourceShare

  let shareIsZero = isValidNumber(shareAmt) && shareAmt === 0

  return (
    <div className="d-flex flex-column align-items-center">
      <Button
        onClick={openSessionDetails}
        sx={{ textTransform: 'none', textDecoration: 'underline' }}
      >
        View Session Details
      </Button>
      <PayoutDistributionReportModelDetails
        report={report}
        getSharePercent={(report) =>
          forMentor ? report?.mentorSharePercentage : report?.sourceSharePercentage
        }
        getShareAmount={(report) => shareAmt}
        getEntityTitle={(report) => (forMentor ? report?.mentorName : report?.sourceName)}
        getEntityFeeModelDetails={(report) =>
          forMentor ? report?.mentorEntityFeeModelUsed : report?.sourceEntityFeeModelUsed
        }
        hide
        getFeeModelDetails={(report) =>
          forMentor ? report?.mentorFeeModelUsed : report?.sourceFeeModelUsed
        }
        getCurrencyCode={(report) => report?.currency}
        hideAllTableSummaryDetails
        forMentor={forMentor}
        viewButton={(onClick) => {
          return (
            <Button onClick={onClick} sx={{ textTransform: 'none', textDecoration: 'underline' }}>
              Collection Model
            </Button>
          )
        }}
      />
      <Dialog
        PaperComponent={ModelViewDialogPaper}
        open={viewSessionDetails}
        onClose={closeSessionDetails}
      >
        <ViewSessionDetails
          hideFeeDetailsWhenShareZero={hideFeeDetailsWhenShareZero}
          shareIsZero={shareIsZero}
          report={report}
        />
      </Dialog>
    </div>
  )
}

function ViewSessionDetails({ report, hideFeeDetailsWhenShareZero }) {
  const foundEventType = EVENT_TYPES_ARRAY.find((eType) => eType.id === report?.eventType)

  return (
    <>
      {/* <DialogTitle>{foundEventType?.title} Details</DialogTitle> */}
      <DialogTitle>Session Details</DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column gap-1">
          <span>
            <strong>Patient Name: </strong>
            {report?.patientName} ({' '}
            {ALL_GENDERS.find((gndr) => gndr?.id === report?.patientGender)?.title ||
              report?.patientGender}
            /{getAgeFromDobWithTime(report?.patientDateOfBirth)})
          </span>
          <span>
            <strong>Patient Number: </strong>
            {report?.patientNumber}
          </span>
          <span>
            <strong>Fee Collected: </strong>
            {!hideFeeDetailsWhenShareZero
              ? getPriceStringWithCurrency(report?.feeCollected, report?.currency)
              : 'NA'}
          </span>
          <span>
            <strong>Fee Collected At: </strong>
            {moment
              .utc(report?.collectedAt)
              .local()
              .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
          </span>
          <span>
            <strong>Fee Collected By: </strong>
            {report?.collectedBy}
          </span>
          <span>
            <strong>Visit Number: </strong>
            {getOrdinalSuffix(report?.sessionNumOfSameTypeWithSameMentor)}
          </span>
          <span>
            <strong>Booking Time: </strong>
            {report?.collectedAt
              ? moment
                  .utc(report?.collectedAt)
                  .local()
                  .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)
              : 'No Data'}
          </span>
          <span>
            <strong>Booked At: </strong>
            {report?.sourceName || 'Clinic'} {report?.clinicLocationName || ''}
          </span>
          <span>
            <strong>{foundEventType.mentorNoun}: </strong>
            {report?.mentorName}
          </span>
          <span>
            <strong>Start Time: </strong>
            {report?.bookedEventStartTime
              ? moment
                  .utc(report?.bookedEventStartTime)
                  .local()
                  .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)
              : 'No Data'}
          </span>
          <span>
            <strong>End Time: </strong>
            {report?.bookedEventEndTime
              ? moment
                  .utc(report?.bookedEventEndTime)
                  .local()
                  .format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)
              : 'No Data'}
          </span>
          <span>
            <strong>Next Visit Date: </strong>
            {report?.nextVisitDate
              ? moment
                  .utc(report?.nextVisitDate)
                  .local()
                  .format(MOMENT_FORMATS.completeDateDetailsWithMonthShortForm)
              : 'No Data'}
          </span>
        </div>
      </DialogContent>
    </>
  )
}

// Session Details
// Visit Number:	3rd
// Booked By	Sharad Oberoi
// Booking Time:	4 January 2025, 15:30
// Booked At:	Ashirward Ambedkarnagar Clinic

// Therapist:	Sampada Vijayvargiya
// Start Time:
// End Time:

// Next Date:
