import { CButton, CButtonGroup, CFormInput } from '@coreui/react-pro'
import {
  Add,
  Close,
  DateRange,
  FilterAlt,
  FitnessCenter,
  PlusOne,
  PointOfSale,
  Science,
  Settings,
  SwapVert,
} from '@mui/icons-material'
import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import React from 'react'
import AutomaticListAddingComponent from 'src/components/controlCenter/common/AutomaticListAddingComponent'
import { StyledListingSearchBar } from 'src/components/controlCenter/EntityComponents/styledComponents'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import BookedEventFilters from 'src/components/controlCenter/genericListing/bookedEventInfo/BookedEventFilters'
import {
  disableForContentWriter,
  POPUP_ACTION_STATES_TYPES,
  StatsTabsToApiParamsMapper,
  threeWayButtonStyles,
} from 'src/config/common/genericListingAndFormConfig'
import {
  ALL_ENTITY_TYPES_FROM_CONSTANTS as ALL_ENTITY_TYPES,
  ALL_ENTITY_TYPES_FROM_CONSTANTS,
  ARTICLE_TYPES,
  INVESTIGATION_TEST_TYPES_ARRAY,
  INVESTIGATION_TYPES_ARRAY,
  LISTING_VIEWS,
  META_CATEGORY_SCOPE_TYPES,
  ONLINE_COURSE_AUTOPLAY_TYPE,
  ONLINE_COURSE_MEDIA_TYPES_ARRAY,
  ONLINE_COURSE_STATUSES,
  ONLINE_COURSE_TYPES,
  SUBSCRIPTION_TYPES_LAYMAN,
  TAG_TYPE,
} from 'src/utilities/constants'
import { AVAILABLE_SERVICES_OPTIONS } from 'src/views/settings/components/ClinicDetails'
import { CoolerTabs, FilterPopover } from 'src/views/setup/userStats/components/styledComponents'
import CacheEvictHeaderFirstLayer from './headerComponents/cacheEvict/FirstLayer'
import PatientInfoBulkActions from './headerComponents/patientInfo/PatientInfoBulkActions'
import PatientInfoHeaderFirstLayer from './headerComponents/patientInfo/PatientInfoHeaderFirstLayer'
import PaymentGatewayHeaderFirstLayer from './headerComponents/paymentGateway/FirstLayer'
import PaymentGatewaySKUHeaderFirstLayer from './headerComponents/paymentGatewaySku/FirstLayer'
import PayoutDistributionReportsHeaderFirstLayer from './headerComponents/payoutDistributionReports/PayoutDistributionReportsHeaderFirstLayer'
import UsersHeaderComponentsFirstLayer from './headerComponents/users/FirstLayer'

export default function EntityListingHeader({
  apiParam,
  urlApiParam,
  handleTabChange,
  handleShowReorder,
  filters,
  setFilters,
  handleAdd,
  debounceSearch,
  debounceSetFilter,
  clearFilters,
  filtersAnchorEl,
  setFiltersAnchorEl,
  closeFiltersPopover,
  navigate,
  dropDownData,
  rStore,
  setPopupActionsStates,
  listingView,
  setListingView,
  hideTableFilters,
  setConfirmationModalProps,
  routeParams,
  hideAddEntityButton,
  hideFilterButton,
  openCsvDialog,
  hideCsvSaveButton,
  refreshListingData,
  selectedItems,
}) {
  var toReturn
  var headerActionButtons
  var showAddButton = true
  var BulkActionsComponent = null

  console.log({ filters })

  switch (apiParam) {
    case ALL_ENTITY_TYPES.article:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="type"
              value={filters?.type}
              options={ARTICLE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select an article type..."
              label="Article Type"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="domainIds"
              value={filters?.domainIds}
              options={rStore.tags?.filter((tag) => tag.type === TAG_TYPE.domain)}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select article domains..."
              label="Domains"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.activity:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="metaCategoryId"
              value={filters?.metaCategoryId}
              options={rStore?.categories}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select some categories..."
              label="Category"
              onChangeEvent={setFilters}
              isClearable={true}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.activityPlan:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="status"
              value={filters?.status}
              options={DRAFT_PUBLISH_OPTIONS}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select the status of the activity plan..."
              label="Status"
              isClearable={true}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.assessment:
      toReturn = (
        <>
          <div style={{ width: '230px' }}>
            <CustomReactSelect
              isMulti
              id="tags"
              value={filters?.tags}
              options={rStore?.tags.filter((tag) =>
                tag?.categories?.some((category) => category === 'journal'),
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a tag..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="subscription"
              value={filters?.subscription}
              options={dropDownData?.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a subscription..."
              label="Subscription"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '230px' }}>
            <CustomReactSelect
              isMulti
              id="assessmentDomainList"
              value={filters?.assessmentDomainList}
              options={rStore?.tags.filter((tag) => tag?.type === 'domain')}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a domain..."
              label="Assessment Domains"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.badge:
      toReturn = (
        <CoolerTabs
          value={StatsTabsToApiParamsMapper[apiParam]}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="white"
          sx={{ selected: { backgroundColor: 'white' } }}
        >
          <Tab label="User Badges" />
          <Tab label="User Stats" />
        </CoolerTabs>
      )
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.bookedEvent:
      toReturn = (
        <BookedEventFilters dropDownData={dropDownData} filters={filters} setFilters={setFilters} />
      )
      break
    case ALL_ENTITY_TYPES.clinic:
      toReturn = (
        <>
          {/* <div style={{ width: '200px' }}>
                <CustomReactSelect
                  id="kycStatus"
                  value={filters?.kycStatus}
                  options={customFilterOptions?.kycStatus}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option?.label || option?.name}
                  placeholder="Select a status"
                  label="KYC Status"
                  onChangeEvent={setFilters}
                  selectType={selectTypes.string}
                />
              </div> */}
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="availableServices"
              value={filters?.availableServices}
              options={AVAILABLE_SERVICES_OPTIONS}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a service"
              label="Available Services"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="doctors.id"
              value={filters?.['doctors.id']}
              options={dropDownData?.doctors || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a doctor"
              label="Doctors"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="therapists.id"
              value={filters?.['therapists.id']}
              options={dropDownData?.therapists || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a therapist"
              label="Therapists"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="clinicBrandId"
              value={filters?.clinicBrandId}
              options={dropDownData?.clinicBrands || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.brandName}
              placeholder="Select a clinic brand"
              label="Clinic Brand"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px', display: 'flex', alignItems: 'flex-end' }}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  id="cloudClinic"
                  checked={!!filters?.cloudClinic || false}
                  onChange={setFilters}
                />
              }
              label="Cloud Clinic: "
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.userStats:
      toReturn = (
        <CoolerTabs
          value={StatsTabsToApiParamsMapper[apiParam]}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="white"
          sx={{ selected: { backgroundColor: 'white' } }}
        >
          <Tab label="User Badges" />
          <Tab label="User Stats" />
        </CoolerTabs>
      )
      break
    case ALL_ENTITY_TYPES.mentalState:
      toReturn = (
        <>
          <div style={{ minWidth: '300px' }}>
            <CustomReactSelect
              id="type"
              value={filters?.type}
              options={rStore?.mentalStateTypes}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a mental state type..."
              label="Type"
              isClearable={true}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div>
            <Button color="secondary" onClick={handleShowReorder}>
              Re-Order States
            </Button>
          </div>
          <div></div>
        </>
      )
      break
    case ALL_ENTITY_TYPES?.mentor:
      toReturn = (
        <>
          <div>
            <CFormInput
              id="city"
              placeholder="City..."
              defaultValue={filters?.city}
              onChange={debounceSearch}
              label="City"
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="country"
              value={filters?.country}
              options={rStore?.countries}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a country..."
              label="Countries"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="languages"
              isMulti
              value={filters?.languages}
              options={rStore?.languages}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="kycStatus"
              value={filters?.kycStatus}
              options={customFilterOptions?.kycStatus}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.label || option?.name}
              placeholder="Select a status"
              label="KYC Status"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="outlined" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.appListable === true}
                onClick={() => setFilters({ target: { id: 'appListable', value: true } })}
              >
                Listable In App Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.appListable === false}
                onClick={() => setFilters({ target: { id: 'appListable', value: false } })}
              >
                Not Listable In App Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.appListable === null || filters?.appListable === undefined}
                onClick={() => setFilters({ target: { id: 'appListable', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="contained" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.recorded === true}
                onClick={() => setFilters({ target: { id: 'recorded', value: true } })}
              >
                Publishable Content Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.recorded === false}
                onClick={() => setFilters({ target: { id: 'recorded', value: false } })}
              >
                Don&apos;t Show Publishable Content
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.recorded === null || filters?.recorded === undefined}
                onClick={() => setFilters({ target: { id: 'recorded', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="contained" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.groupLive === true}
                onClick={() => setFilters({ target: { id: 'groupLive', value: true } })}
              >
                Show Group Sessions Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.groupLive === false}
                onClick={() => setFilters({ target: { id: 'groupLive', value: false } })}
              >
                Don&apos;t Show Group Sessions
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.groupLive === null || filters?.groupLive === undefined}
                onClick={() => setFilters({ target: { id: 'groupLive', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="contained" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.live === true}
                onClick={() => setFilters({ target: { id: 'live', value: true } })}
              >
                1 - 1 Sessions Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.live === false}
                onClick={() => setFilters({ target: { id: 'live', value: false } })}
              >
                Don&apos;t Show 1 - 1 Sessions
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.live === null || filters?.live === undefined}
                onClick={() => setFilters({ target: { id: 'live', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.journal:
      toReturn = (
        <>
          <div style={{ width: '230px' }}>
            <CustomReactSelect
              isMulti
              id="tags"
              value={filters?.tags}
              options={rStore?.tags.filter((tag) =>
                tag?.categories?.some((category) => category === 'journal'),
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a tag..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="subscription"
              value={filters?.subscription}
              options={dropDownData?.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a subscription..."
              label="Subscription"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.oohMsg:
      toReturn = (
        <>
          {!hideTableFilters?.includes('clinicId') && (
            <div style={{ width: '300px' }}>
              <CustomReactSelect
                id="clinicId"
                value={filters?.clinicId}
                options={dropDownData?.clinics?.sort((a, b) => a.title?.localeCompare(b.title))}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.title}
                placeholder="Select a clinic"
                label="Clinic"
                onChangeEvent={setFilters}
                isClearable
                selectType={selectTypes.string}
              />
            </div>
          )}
          {!hideTableFilters?.includes('mentorId') && (
            <div style={{ width: '300px' }}>
              <CustomReactSelect
                id="mentorId"
                value={filters?.mentorId}
                options={dropDownData?.mentors?.sort((a, b) => a.title?.localeCompare(b.title))}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.title}
                placeholder="Select a mentor"
                label="Mentor"
                isClearable
                onChangeEvent={setFilters}
                selectType={selectTypes.string}
              />
            </div>
          )}
          {!hideTableFilters?.includes('clinicBrandId') && (
            <div style={{ width: '300px' }}>
              <CustomReactSelect
                id="clinicBrandId"
                value={filters?.clinicBrandId}
                options={dropDownData?.clinicBrands?.sort((a, b) =>
                  a.title?.localeCompare(b.title),
                )}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.brandName || option?.title}
                placeholder="Select a clinic brand..."
                label="Clinic Brand"
                onChangeEvent={setFilters}
                isClearable
                selectType={selectTypes.string}
              />
            </div>
          )}
        </>
      )
      break
    case ALL_ENTITY_TYPES.metaCategoryType:
      toReturn = (
        <>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="scope"
              value={filters?.scope}
              options={META_CATEGORY_SCOPE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a scope"
              label="Scope"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="itemType"
              value={filters?.itemType}
              options={dropDownData.itemTypes}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a itemType"
              label="Item Types"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.planBundlePack:
    case ALL_ENTITY_TYPES.clinicSubscriptionPlan:
      toReturn = (
        <>
          <div style={{ width: '200px' }}>
            <FormControlLabel
              control={<Switch id="recurring" checked={filters.recurring} onChange={setFilters} />}
              label="Recurring"
            />
          </div>
          {/* <div style={{ width: '300px' }}>
              <CustomReactSelect
                isMulti
                id="audience"
                label="Filter Audience"
                placeholder="Select filter audience..."
                value={filters.audience}
                options={SUBSCRIPTION_AUDIENCE_TYPES}
                onChangeEvent={setFilters}
                getOptionLabel={(optn) => optn.title}
                getOptionValue={(optn) => optn.id}
                selectType={selectTypes.string}
              />
            </div> */}
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="planTypeFilter"
              label="Plan Types"
              placeholder="Enter Plan Types..."
              value={filters?.planType}
              options={SUBSCRIPTION_TYPES_LAYMAN}
              getOptionLabel={(optn) => optn.title}
              getOptionValue={(optn) => optn.id}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          {/* <div style={{ width: '300px' }}>
              <CustomReactSelect
                isMulti
                id="planGroups"
                label="Plan Groups"
                placeholder="Select Plan Groups..."
                value={filters?.planGroups}
                options={dropDownData?.allPlanGroups}
                onChangeEvent={setFilters}
                getOptionLabel={(optn) => optn.name}
                getOptionValue={(optn) => optn.id}
                selectType={selectTypes.string}
              />
            </div> */}
        </>
      )
      break
    case ALL_ENTITY_TYPES.onlineCourse:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="languages"
              isMulti
              value={filters?.languages}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="tag"
              value={filters?.tag}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="createdFor"
              value={filters?.createdFor}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          {/* <div style={{ width: '300px' }}>
              <CustomReactSelect
                id="status"
                value={filters?.status}
                options={ONLINE_COURSE_STATUSES}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.title}
                placeholder="Select status..."
                label="Status"
                onChangeEvent={setFilters}
                isClearable
                selectType={selectTypes.string}
              />
            </div> */}
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="subscription"
              value={filters?.subscription}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseType"
              value={filters?.courseType}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="autoPlay"
              value={filters?.autoPlay}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="listabilityPolicy"
              value={filters?.listabilityPolicy}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="type"
              value={filters?.type}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="reqToUnpublish"
              value={filters?.reqToUnpublish}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.courseDraft:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.languages"
              isMulti
              value={filters?.['onlineCourse.languages']}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.tag"
              value={filters?.['onlineCourse.tag']}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.createdFor"
              value={filters?.['onlineCourse.createdFor']}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.status"
              value={filters?.['onlineCourse.status']}
              options={ONLINE_COURSE_STATUSES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select status..."
              label="Status"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.subscription"
              value={filters?.['onlineCourse.subscription']}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.courseType"
              value={filters?.['onlineCourse.courseType']}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.autoPlay"
              value={filters?.['onlineCourse.autoPlay']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.listabilityPolicy"
              value={filters?.['onlineCourse.listabilityPolicy']}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.type"
              value={filters?.['onlineCourse.type']}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.reqToUnpublish"
              value={filters?.['onlineCourse.reqToUnpublish']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.investigation:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isClearable
              id="investigationType"
              label="Investigation Type"
              placeholder="Enter investigation type..."
              value={filters?.investigationType}
              options={INVESTIGATION_TYPES_ARRAY}
              getOptionValue={(optn) => optn.id}
              getOptionLabel={(optn) => optn.title}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isClearable
              id="type"
              label="Test type"
              placeholder="Enter test type..."
              value={filters?.type}
              options={INVESTIGATION_TEST_TYPES_ARRAY}
              getOptionValue={(optn) => optn.id}
              getOptionLabel={(optn) => optn.title}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '100%' }}>
            <AutomaticListAddingComponent
              isMulti
              id="units"
              label="Units"
              value={filters?.units}
              onChange={setFilters}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.unPublishRequest:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <FormControlLabel
              labelPlacement="start"
              sx={{ alignSelf: 'flex-end', margin: '0 !important' }}
              control={
                <Switch
                  checked={filters?.status === null ? true : false}
                  onChange={(e) => {
                    setFilters({
                      target: { id: 'status', value: e.target.checked ? null : 'pending' },
                    })
                  }}
                />
              }
              label={'Show all requests?'}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.languages"
              isMulti
              value={filters?.['onlineCourse.languages']}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.tag"
              value={filters?.['onlineCourse.tag']}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.createdFor"
              value={filters?.['onlineCourse.createdFor']}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.status"
              value={filters?.['onlineCourse.status']}
              options={ONLINE_COURSE_STATUSES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select status..."
              label="Status"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.subscription"
              value={filters?.['onlineCourse.subscription']}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.courseType"
              value={filters?.['onlineCourse.courseType']}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.autoPlay"
              value={filters?.['onlineCourse.autoPlay']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.listabilityPolicy"
              value={filters?.['onlineCourse.listabilityPolicy']}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.type"
              value={filters?.['onlineCourse.type']}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.reqToUnpublish"
              value={filters?.['onlineCourse.reqToUnpublish']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.approvalRequest:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <FormControlLabel
              labelPlacement="start"
              sx={{ alignSelf: 'flex-end', margin: '0 !important' }}
              control={
                <Switch
                  checked={filters?.status === null ? true : false}
                  onChange={(e) => {
                    setFilters({
                      target: { id: 'status', value: e.target.checked ? null : 'pending' },
                    })
                  }}
                />
              }
              label={'Show all requests?'}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.languages"
              isMulti
              value={filters?.['courseDraft.onlineCourse.languages']}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="courseDraft.onlineCourse.tag"
              value={filters?.['courseDraft.onlineCourse.tag']}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.createdFor"
              value={filters?.['courseDraft.onlineCourse.createdFor']}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.status"
              value={filters?.['courseDraft.onlineCourse.status']}
              options={ONLINE_COURSE_STATUSES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select status..."
              label="Status"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.subscription"
              value={filters?.['courseDraft.onlineCourse.subscription']}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.courseType"
              value={filters?.['courseDraft.onlineCourse.courseType']}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.autoPlay"
              value={filters?.['courseDraft.onlineCourse.autoPlay']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="courseDraft.onlineCourse.listabilityPolicy"
              value={filters?.['courseDraft.onlineCourse.listabilityPolicy']}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.type"
              value={filters?.['courseDraft.onlineCourse.type']}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.reqToUnpublish"
              value={filters?.['courseDraft.onlineCourse.reqToUnpublish']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.cacheEvict:
      toReturn = (
        <CacheEvictHeaderFirstLayer setConfirmationModalProps={setConfirmationModalProps} />
      )
      break
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      toReturn = (
        <>
          <PaymentGatewaySKUHeaderFirstLayer
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    case ALL_ENTITY_TYPES.paymentGateways:
      toReturn = (
        <>
          <PaymentGatewayHeaderFirstLayer
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    case ALL_ENTITY_TYPES.users:
      toReturn = (
        <>
          <UsersHeaderComponentsFirstLayer
            rStore={rStore}
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    case ALL_ENTITY_TYPES.payoutDistributionReports:
      toReturn = (
        <>
          <PayoutDistributionReportsHeaderFirstLayer
            rStore={rStore}
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    case ALL_ENTITY_TYPES.patientInfo:
      toReturn = (
        <>
          <PatientInfoHeaderFirstLayer
            rStore={rStore}
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    default:
      toReturn = null
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES.oohMsg:
      headerActionButtons = (
        <div>
          <Tooltip title="Re-Order OOH Messages">
            <IconButton
              color="secondary"
              onClick={() =>
                setPopupActionsStates((prev) => ({
                  ...prev,
                  open: true,
                  type: POPUP_ACTION_STATES_TYPES.reorderOohMsgs,
                }))
              }
            >
              <SwapVert />
            </IconButton>
          </Tooltip>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.mentalStateType:
      headerActionButtons = (
        <Tooltip title={'Mental States'}>
          <IconButton
            onClick={() => {
              navigate(ALL_ENTITY_TYPES.mentalState)
            }}
          >
            <Settings />
          </IconButton>
        </Tooltip>
      )
      break
    case ALL_ENTITY_TYPES.activityPlan:
      headerActionButtons = (
        <>
          <Tooltip title="Activity">
            <IconButton
              onClick={() => {
                navigate('activity')
              }}
            >
              <FitnessCenter />
            </IconButton>
          </Tooltip>
          <Tooltip title="Plan Phases">
            <IconButton
              onClick={() => {
                navigate('plan-phase')
              }}
            >
              <DateRange />
            </IconButton>
          </Tooltip>
        </>
      )
      break
    case ALL_ENTITY_TYPES.displayMessageTemplates:
      headerActionButtons = (
        <ToggleButtonGroup
          color="standard"
          value={listingView}
          exclusive
          onChange={(e, val) => setListingView(val)}
        >
          <ToggleButton value={LISTING_VIEWS.list}>List</ToggleButton>
          <ToggleButton value={LISTING_VIEWS.grid}>Grid</ToggleButton>
        </ToggleButtonGroup>
      )
      break
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      headerActionButtons = (
        <Tooltip title="Payment Gateways">
          <IconButton
            onClick={() => {
              navigate(ALL_ENTITY_TYPES.paymentGateways)
            }}
          >
            <PointOfSale />
          </IconButton>
        </Tooltip>
      )
      break
    case ALL_ENTITY_TYPES.segment:
      headerActionButtons = (
        <div style={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            onClick={() =>
              setPopupActionsStates((prev) => ({
                ...prev,
                open: true,
                type: POPUP_ACTION_STATES_TYPES.segmentTester,
              }))
            }
            sx={{ flexShrink: 0 }}
          >
            Segment Tester
          </Button>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.drugAdviceDuration:
    case ALL_ENTITY_TYPES.drugAdviceFrequency:
    case ALL_ENTITY_TYPES.drugAdviceNote:
    case ALL_ENTITY_TYPES.drugAdviceDosage:
    case ALL_ENTITY_TYPES.drugAdviceTiming:
      headerActionButtons = (
        <div style={{ flexShrink: 0 }}>
          <Button
            color="secondary"
            onClick={() =>
              setPopupActionsStates((prev) => ({
                ...prev,
                open: true,
                type: POPUP_ACTION_STATES_TYPES.reorderDrugAdviceAvailableToAll,
              }))
            }
            sx={{ flexShrink: 0 }}
          >
            Reorder available to all
          </Button>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.clinicAssistantUser:
      headerActionButtons = (
        <div style={{ flexShrink: 0 }}>
          <IconButton
            onClick={() =>
              setPopupActionsStates((prev) => ({
                ...prev,
                open: true,
                type: POPUP_ACTION_STATES_TYPES.clinicAssistantAddition,
              }))
            }
            sx={{ flexShrink: 0 }}
          >
            <Add />
          </IconButton>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.investigation:
      headerActionButtons = (
        <>
          <div style={{ flexShrink: 0 }}>
            <IconButton
              onClick={() =>
                setPopupActionsStates((prev) => ({
                  ...prev,
                  open: true,
                  type: POPUP_ACTION_STATES_TYPES.multiUploadInvestigations,
                }))
              }
              sx={{ flexShrink: 0 }}
            >
              <PlusOne />
            </IconButton>
          </div>
          <div style={{ flexShrink: 0 }}>
            <Tooltip title="Sample Types">
              <IconButton
                onClick={() => navigate(ALL_ENTITY_TYPES_FROM_CONSTANTS.investigationSample)}
                sx={{ flexShrink: 0 }}
              >
                <Science />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )
      break
    default:
      headerActionButtons = <></>
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES.clinic:
      showAddButton = urlApiParam === 'clinic-prospects' ? true : false
      break
    case ALL_ENTITY_TYPES.approvalRequest:
    case ALL_ENTITY_TYPES.onlineCourse:
    case ALL_ENTITY_TYPES.unPublishRequest:
      showAddButton = false
      break

    default:
      showAddButton = apiParam === ALL_ENTITY_TYPES.article ? true : !disableForContentWriter()
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES.patientInfo:
      BulkActionsComponent = PatientInfoBulkActions
      break
    default:
      break
  }

  const allValidFiltersLen = Object.keys(filters).filter((key) => {
    if (Array.isArray(filters?.[key])) {
      return !!filters?.[key]?.length
    }
    if (key === 'search') {
      return false
    }
    return !!filters?.[key]
  })?.length

  const showBulkActionsButton = selectedItems && selectedItems?.length > 0 && BulkActionsComponent

  return (
    <>
      <div className="d-flex flex-wrap gap-2 w-100">
        <StyledListingSearchBar>
          <CFormInput
            placeholder={`Search ${apiParam}...`}
            defaultValue={filters?.search}
            onChange={debounceSearch}
            label="Search"
          />
        </StyledListingSearchBar>
        <FilterPopover
          anchorEl={filtersAnchorEl}
          open={Boolean(filtersAnchorEl)}
          onClose={closeFiltersPopover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {toReturn || <></>}
        </FilterPopover>
      </div>
      {showBulkActionsButton && (
        <BulkActionsComponent
          selectedItems={selectedItems}
          refreshListingData={refreshListingData}
        />
      )}
      {!!toReturn && !hideFilterButton && (
        <div>
          <Badge badgeContent={allValidFiltersLen} color="primary">
            <IconButton onClick={(e) => setFiltersAnchorEl(e.currentTarget)}>
              <FilterAlt />
            </IconButton>
          </Badge>
        </div>
      )}
      <Tooltip title="Reset Filters">
        <IconButton onClick={clearFilters}>
          <Close />
        </IconButton>
      </Tooltip>{' '}
      {!hideCsvSaveButton && (
        <Button color="primary" variant="contained" sx={{ flexShrink: 0 }} onClick={openCsvDialog}>
          Save Data through CSV
        </Button>
      )}
      {headerActionButtons}
      {showAddButton && !hideAddEntityButton && (
        <Tooltip title="Add">
          <IconButton
            onClick={() => {
              handleAdd()
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

const DRAFT_PUBLISH_OPTIONS = [
  { id: 'draft', title: 'Draft' },
  { id: 'published', title: 'Published' },
]

const customFilterOptions = {
  kycStatus: [
    { id: 'approved', label: 'Approved' },
    { id: 'pending', label: 'Pending' },
    { id: null, label: 'Show All' },
  ],
  appListable: [
    { id: true, label: 'Listable Only' },
    { id: false, label: 'Not Listable Only' },
    { id: null, label: 'Show All' },
  ],
  servicesOptions: [
    { id: true, label: 'Show such mentors Only' },
    { id: false, label: "Don't such mentors" },
    { id: null, label: 'Show All' },
  ],
}
