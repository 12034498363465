import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchPaginatedFunction } from 'src/services'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { saveCurrentClinic } from '../clinic'
import { getClinicBrandProspectMentorObjectForClinicSelect, getFinalRemovedStaff } from './utils'
import { isDoctorApprovedForClinic } from 'src/utilities/clinicHelpers'

export const fetchDoctorsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/fetchDoctorsForCurrentClinic',
  async (_, { getState, dispatch }) => {
    dispatch(fetchDoctorsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicDoctors = currentClinic?.doctors
    const clinicDoctorIds = clinicDoctors?.filter((doc) => doc?.uid)?.map((doc) => doc?.uid)

    const doctorResp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.mentor,
      ids: clinicDoctorIds?.join(','),
    })
    if (doctorResp) {
      return clinicDoctors?.map((clinicDoctor) => {
        const doctor = doctorResp.find((respDoctor) => respDoctor.id === clinicDoctor.uid) || {}
        return {
          ...clinicDoctor,
          ...doctor,
          status: clinicDoctor?.status,
        }
      })
    } else {
      return doctorResp.message
    }
  },
)

export const addEditDoctorsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/addEditDoctorsForCurrentClinic',
  async ({ data }, { getState, dispatch }) => {
    dispatch(addEditDoctorsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicDoctors = currentClinic?.doctors
    const updatedDoctors = [...(clinicDoctors || []), ...(data || [])]?.map((doc) => ({
      ...doc,
      role: 'ROLE_DOCTOR',
    }))

    await dispatch(
      saveCurrentClinic({
        dataToSave: {
          ...currentClinic,
          doctors: updatedDoctors,
        },
      }),
    )
    dispatch(fetchDoctorsForCurrentClinic())
  },
)

export const removeDoctorsForCurrentClinic = createAsyncThunk(
  'clinicFlowData/removeDoctorsForCurrentClinic',
  async ({ idx, type = 'prospect' }, { getState, dispatch }) => {
    dispatch(removeDoctorsForCurrentClinic.pending())
    const { clinicFlowData } = getState()
    const currentClinic = clinicFlowData.currentClinicData.data
    const clinicApprovedDoctors = currentClinic?.doctors?.filter((doc) =>
      isDoctorApprovedForClinic(doc),
    )
    const prospectDoctors = currentClinic?.doctors?.filter((doc) => !isDoctorApprovedForClinic(doc))
    const updatedDoctors = getFinalRemovedStaff({
      approvedStaff: clinicApprovedDoctors,
      prospectStaff: prospectDoctors,
      type,
      idx,
    })
    await dispatch(
      saveCurrentClinic({
        dataToSave: {
          ...currentClinic,
          doctors: updatedDoctors,
        },
      }),
    )
    dispatch(fetchDoctorsForCurrentClinic())
  },
)

export const fetchDoctorsFromCurrentClinicBrand = createAsyncThunk(
  'clinicFlowData/fetchDoctorsFromCurrentClinicBrand',
  async (_, { getState, dispatch }) => {
    dispatch(fetchDoctorsFromCurrentClinicBrand.pending())
    const { clinicFlowData } = getState()
    const currentClinicBrand = clinicFlowData.currentClinicBrandData.data
    const doctorsUserIds = currentClinicBrand?.workforce.doctors?.map(
      (doc) => doc.userId || doc?.uid,
    )
    const doctorsEmailIds = currentClinicBrand?.prospects.doctors
      ?.filter((doc) => !!doc?.email)
      ?.map((doc) => doc.email)

    const resp = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.mentor,
      userIds: doctorsUserIds?.join(','),
      size: 999,
    })
    if (resp) {
      return [...(doctorsUserIds || []), ...(doctorsEmailIds || [])]?.map((doc) => {
        var foundDoctor = resp.find((d) => d.userId === doc)
        if (!foundDoctor) {
          foundDoctor = getClinicBrandProspectMentorObjectForClinicSelect(doc)
        } else {
          foundDoctor = {
            ...foundDoctor,
            value: foundDoctor.id,
            label: foundDoctor.title,
            status: 'approved',
          }
        }

        return foundDoctor
      })
    } else {
      return resp.message
    }
  },
)

// export const setupClinicPeopleData = createAsyncThunk(
//   'clinicFlowData/setupClinicPeopleData',
//   async (_, { dispatch }) => {
//     console.log('setupClinicPeopleData')
//     dispatch(fetchDoctorsForCurrentClinic())
//   },
// )
