import { Checklist } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import PatientInfoBulkActionsForm from './PatientInfoBulkActionsForm'

export default function PatientInfoBulkActions({ selectedItems, refreshListingData }) {
  const [showDialog, setShowDialog] = useState()

  function openDialog(e) {
    setShowDialog(true)
  }
  function closeDialog(e) {
    setShowDialog(false)
  }
  return (
    <>
      <IconButton onClick={openDialog}>
        <Checklist />
      </IconButton>
      <Dialog open={showDialog} onClose={closeDialog}>
        <PatientInfoBulkActionsForm
          selectedItems={selectedItems}
          refreshListingData={refreshListingData}
          closeDialog={closeDialog}
        />
      </Dialog>
    </>
  )
}
