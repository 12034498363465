import { Chip } from '@mui/material'
import {
  BOOKED_EVENT_MODES,
  EVENT_TYPES_ARRAY,
  PAYMENT_METHOD_KV_MAP,
  PAYMENT_METHOD_TITLE_MAP,
} from 'src/utilities/constants'
import { getPriceStringWithCurrency, isValidNumber } from 'src/utilities/generalUtils'

export default function PayoutDistributionReportFeeDetails({
  report,
  getClinicShare,
  getClinicSharePercentage,
  hideFeeDataOnZeroPercentage = false,
  hideClinicShare = false,
  hideAumhumShare = false,
  getCurrency,
  forMentor = false,
  getMinPayableValue,
}) {
  const clinicShare = getClinicShare(report)
  const clinicSharePercentage = getClinicSharePercentage(report)
  const currency = getCurrency(report)
  const minPayableValue = getMinPayableValue(report)
  const feePaid = report?.feeCollected
  const foundEventType = EVENT_TYPES_ARRAY.find((type) => report?.eventType === type?.id)
  const payingToAUMHUM = minPayableValue > feePaid
  const eventMode = BOOKED_EVENT_MODES?.[report?.mode]
  const paymentMethod = report?.paymentMethod

  let aumhumShareString = isValidNumber(clinicSharePercentage)
    ? `${100 - clinicSharePercentage}%${
        isValidNumber(minPayableValue)
          ? ` of ${getPriceStringWithCurrency(minPayableValue, report?.currency)}`
          : ''
      }`
    : 'Cannot Calculate'
  let shareIsZero = isValidNumber(clinicSharePercentage) && clinicSharePercentage === 0

  if (shareIsZero && hideFeeDataOnZeroPercentage) {
    return (
      <div className="d-flex flex-column gap-2">
          NA
      </div>
    )
  }

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-items-center gap-1 flex-wrap">
        <div className="d-flex align-items-center gap-1 flex-shrink-0">
          <span>Fee Collected: </span>
          <span>
            {isValidNumber(feePaid)
              ? getPriceStringWithCurrency(feePaid, currency, true)
              : 'No Data'}
          </span>
        </div>
        {/* <div className="d-flex align-items-center gap-1 flex-shrink-0"> */}
        <SessionMode
          paymentMethod={paymentMethod}
          forMentor={forMentor}
          hasClinicBrand={!!report?.clinicBrandId}
        />
        {/* </div> */}
      </div>

      {!hideClinicShare && (
        <span>
          {forMentor ? 'Mentor' : 'Clinic'} Share{' '}
          {payingToAUMHUM
            ? '(After paying AUMHUM)'
            : isValidNumber(clinicSharePercentage)
            ? `(${clinicSharePercentage}%)`
            : 'Cannot Calculate'}
          :{' '}
          {isValidNumber(clinicShare)
            ? getPriceStringWithCurrency(clinicShare, currency, true)
            : 'No Data'}
        </span>
      )}
      {!hideAumhumShare && (
        <span>
          AUMHUM Share ({aumhumShareString}
          ):{' '}
          {isValidNumber((feePaid || 0) - clinicShare)
            ? getPriceStringWithCurrency(feePaid - clinicShare, currency, true)
            : 'No Data'}
        </span>
      )}
    </div>
  )
}

function SessionMode({ paymentMethod, forMentor = false, hasClinicBrand }) {
  if (forMentor) {
    return paymentMethod !==
      PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_COLLECTED_BY_AUMHUM ? (
      hasClinicBrand ? (
        <>
          <span> ({PAYMENT_METHOD_TITLE_MAP?.[paymentMethod]} at </span>
          <Chip size="small" sx={{ color: 'white', background: '#003eff' }} label="Clinic" />
          <span>)</span>
        </>
      ) : (
        <></>
      )
    ) : (
      <>
        <span> (Collected by</span>{' '}
        <Chip size="small" sx={{ color: 'white', background: '#7458b8' }} label="AUMHUM" />
        <span>)</span>
      </>
    )
  }

  return paymentMethod !== PAYMENT_METHOD_KV_MAP.BOOKING_FEE_PAYMENT_METHOD_COLLECTED_BY_AUMHUM ? (
    <>
      <span> ({PAYMENT_METHOD_TITLE_MAP?.[paymentMethod]} at </span>
      <Chip size="small" sx={{ color: 'white', background: '#003eff' }} label="Clinic" />
      <span>)</span>
    </>
  ) : (
    <>
      <span> (Collected by</span>{' '}
      <Chip size="small" sx={{ color: 'white', background: '#7458b8' }} label="AUMHUM" />
      <span>)</span>
    </>
  )
}
