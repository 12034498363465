import { Grid } from '@mui/material'
import React from 'react'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { COMMUNICATION_TYPES_ARRAY } from 'src/utilities/constants'

export default function DefualtCsvUploadForm({
  csvDelimiter,
  setCsvDelimiter,
  listDelimiter,
  setListDelimiter,
  communicationTypes,
  setCommunicationTypes,
}) {
  return (
    <>
      <Grid item md={6} xs={12}>
        <CCFormInput
          id="csv-delimiter"
          value={csvDelimiter}
          label="CSV Delimiter"
          placeholder=","
          subLabel="Character that separates the headers and cells in the CSV"
          onChange={(e) => {
            const value = e.target.value
            if (value.length <= 1) {
              setCsvDelimiter(value)
            }
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CCFormInput
          id="list-delimiter"
          value={listDelimiter}
          label="List Delimiter"
          placeholder=";"
          subLabel="Characters that separate the list values in the uploaded csv"
          onChange={(e) => {
            setListDelimiter(e.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomReactSelect
          isMulti
          id="comm-modes"
          value={communicationTypes}
          label="Communication Modes"
          options={COMMUNICATION_TYPES_ARRAY}
          selectType={selectTypes.string}
          getOptionLabel={(option) => option?.title}
          getOptionValue={(option) => option?.id}
          subLabel="Note that if None is in this list, no communication will be sent"
          onChangeEvent={(e) => {
            setCommunicationTypes(e.target.value)
          }}
        />
      </Grid>
    </>
  )
}
