import React from 'react'
import BaseSubscriptionPlanComponent from '../plans/components/SubscriptionPlanFormComponents/BaseSubscriptionPlanComponent'

export default function ClinicSubscriptionPlanForm({
  initialObject = {},
  imageObject: clinicSubscription,
  setImageObject: setClinicSubscription,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
  formMode,
}) {
  console.log({ clinicSubscription })

  return (
    <div className="row g-3">
      <BaseSubscriptionPlanComponent
        allPlanGroups={customOptions?.allPlanGroups}
        allPlanLevels={customOptions?.allPlanLevels}
        mode={formMode}
        setSubscription={setClinicSubscription}
        subscription={clinicSubscription}
        allSubscriptionPlans={customOptions?.allSubscriptionPlans}
        hidePlanGroup
        hideCustomerType
        showClinicPlanFields
      />
    </div>
  )
}
